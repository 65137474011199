<template>
  <footer class="print:hidden py-20 mt-20 text-lg bg-bluedark-100 text-graylight-100">
    <div v-if="footer"
         class="container md:flex">
      <div class="w-full md:w-1/3 md:px-5 mb-9 md:mb-0">
        <h2 v-if="footer.aboutUsHeading"
            v-text="footer.aboutUsHeading"
            class="mb-6 font-serif font-semibold text-hsm"></h2>
        <LinkList v-if="footer.aboutUsLinks"
                  :links="footer.aboutUsLinks" />
      </div>

      <div class="w-full md:w-1/3 md:px-5 mb-9 md:mb-0">
        <h2 v-if="footer.contactHeading"
            v-text="footer.contactHeading"
            class="mb-6 font-serif font-semibold text-hsm"></h2>
        <LinkList v-if="footer.contactLinks"
                  :links="footer.contactLinks" />
      </div>

      <div class="w-full md:w-1/3 md:px-5 mb-9 md:mb-0">
        <h2 v-if="footer.relatedHeading"
            v-text="footer.relatedHeading"
            class="mb-6 font-serif font-semibold text-hsm"></h2>
        <LinkList v-if="footer.relatedLinks"
                  :links="footer.relatedLinks" />
      </div>
    </div>
    <div class="container pt-12">
      <div class="md:px-5 lg:flex md:justify-start md:items-start md:mb-0">
        <!--Logos 1-->
        <div v-if="footer.logos" class="lg:flex lg:items-center">
          <div class="pt-7 lg:pt-1.5 lg:pl-12" v-for="(image, index) in footer.logos" :key="$uuid(index)">
            <div v-if="image.isVectorImage">
              <a :href="image.href" :target="image.target" :title="image.title" class="inline-block">
                <inline-svg :src="image.src"
                            height="44"
                            :title="image.title"
                            :aria-label="image.title"
                            class="text-white fill-current"></inline-svg>
              </a>
            </div>
            <div v-else>
              <a :href="image.href" v-if="image.href" :target="image.target" :title="image.title" class="inline-block">
                <img :src="image.src" :alt="image.alt" />
              </a>
              <img v-else :src="image.src" :alt="image.alt" />
            </div>
          </div>
        </div>
      </div>
      <div class="md:px-5 lg:flex md:justify-start md:items-start md:mt-6 md:mb-0">
        <!--Logos 2-->
        <div v-if="footer.logos2" class="lg:flex lg:items-center">
          <div class="pt-7 lg:pt-1.5 lg:pl-12" v-for="(image, index) in footer.logos2" :key="$uuid(index)">
            <div v-if="image.isVectorImage">
              <a :href="image.href" :target="image.target" :title="image.title" class="inline-block">
                <inline-svg :src="image.src"
                            height="44"
                            :title="image.title"
                            :aria-label="image.title"
                            class="text-white fill-current h-8"></inline-svg>
              </a>
            </div>
            <div v-else>
              <a :href="image.href" v-if="image.href" :target="image.target" :title="image.title" class="inline-block">
                <img :src="image.src" :alt="image.alt" />
              </a>
              <img v-else :src="image.src" :alt="image.alt" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import LinkList from '@/views/components/footer/FooterLinkList.vue';

  export default {
    name: 'Footer',
    props: {
      footer: {
        type: Object,
      },
    },
    components: {
      LinkList,
    },
  };
</script>
