<template>
  <div v-if="data?.searchResult?.length > 0"
       class="flex items-center justify-between mb-6 text-base border-b border-gray-100 pb-7">
    <div class="text-sm font-normal tabular-nums flex justify-between lg:justify-start items-center w-full">
      <div class="hidden lg:inline-block">
        <span>{{$tc("search.results.showing.text", { from: numResultFrom, to: numResultTo, total: data.totalMatching })}} </span>
      </div>
      <template v-if="totalPages > 1">
        <button @click="prevPage()"
                :class="pageNum === 1 ? ' text-gray-200 cursor-text' : 'text-text hover:text-red-200 cursor-pointer'"
                :disabled='pageNum === 1'
                class="mx-2"
                :title="$t('search.navigation.moveOnePageBack')">
          <font-awesome-icon icon="angle-left" />
        </button>

        <div class="block lg:hidden">
          <span>{{$tc("search.results.showing.text", { from: numResultFrom, to: numResultTo, total: data.totalMatching })}} </span>
        </div>

        <button @click="nextPage()"
                :class="pageNum === totalPages ? ' text-gray-200 cursor-text' : 'text-text hover:text-red-200 cursor-pointer'"
                :disabled='pageNum === totalPages'
                :title="$t('search.navigation.moveOnePageForward')">
          <font-awesome-icon icon="angle-right" />
        </button>
      </template>
    </div>

    <div v-show="showSort" class="hidden lg:block text-right w-1/2">
      <SearchSort :key="'searchpage_sort'" />
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import SearchSort from '@/views/components/search/SearchSort.vue';

  export default {
    components: {
      SearchSort,
    },
    props: {
      showSort: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      ...mapGetters({
        data: 'getData',
        pageNum: 'getPageNum',
        pageSize: 'getPageSize',
      }),
      totalPages() {
        const { pageSize, totalMatching } = this.data;
        return Math.ceil(totalMatching / pageSize);
      },
      numResultFrom() {
        const { page, pageSize } = this.data;
        if (page === 1) {
          return 1;
        }
        return ((page * pageSize) - pageSize) + 1;
      },
      numResultTo() {
        const { page, pageSize, totalMatching } = this.data;
        return page * pageSize > totalMatching ? totalMatching : page * pageSize;
      },
    },
    methods: {
      ...mapMutations(['setPageNum']),
      fetchProducts() {
        this.$store.dispatch('performSearch');
      },
      nextPage() {
        this.setPageNum(parseInt(this.pageNum, 10) + 1);
        this.fetchProducts();
        window.scrollTo(0, 0);
      },
      prevPage() {
        this.setPageNum(parseInt(this.pageNum, 10) - 1);
        this.fetchProducts();
        window.scrollTo(0, 0);
      },
    },
  };
</script>
