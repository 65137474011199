<template>
  <div class="flex flex-col">
    <h2 v-if="model.heading || this.$route.query.epieditmode"
        class="font-serif font-semibold text-hsm mb-7">
      {{model.heading}}
    </h2>
    <p
      v-if="model.ingress || this.$route.query.epieditmode"
      class="mb-6 text-lg"
    >
      {{model.ingress}}
    </p>
      <ul class="flex flex-col border-t border-b border-gray-200 divide-y divide-gray-200">
        <template v-for="(item, index) in model.questions">
          <li
            v-if="item.question"
            :key="$uuid(index)"
          >
            <FAQItem :model="item" />
          </li>
        </template>
      </ul>
    <div
      v-if="model.link || this.$route.query.epieditmode"
      class="flex justify-end mt-7"
    >
      <EPiLink
        :url="model.link"
        class="hover:underline"
      >
        <font-awesome-icon class="mr-2 text-red-200" icon="long-arrow-alt-right" />
        <span class="text-xl">{{model.linkText}}</span>
      </EPiLink>
    </div>
  </div>
</template>

<script>
  import FAQItem from '@/views/components/faq/FAQItem.vue';
  import EPiLink from '@/components/EpiLink.vue';

  export default {
    props: {
      model: {
        type: Object,
      },
    },
    components: {
      FAQItem,
      EPiLink,
    },
  };
</script>
