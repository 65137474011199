<template>
  <div v-if="filter.visible" class="flex flex-col mb-2.5">
    <button
      @click.prevent="this.toggleFilter = !this.toggleFilter"
      class="flex items-center justify-between pb-2 mb-3 text-base text-left border-b border-gray-400"
    >
      <span>
        {{ filter.filterTitle }}
      </span>
      <font-awesome-icon icon="angle-down" class="transition-all transform" :class="toggleFilter ? 'rotate-180' : ''" />
    </button>
    <ul
      v-show="toggleFilter"
    >
      <li v-for="(item, index) in sortFilterNames(filter.filterItems)" :key="$uuid(index)" class="mb-1.5">
          <SearchFilterItem :item="item"
                            :subItems="filter.subItems"
                            :isRadio="isRadio"
                            :checkboxGroup="filter.filterKey"
                            :isMobile="isMobile"
                            :showSubItems="showSubItems(item)"
                            :preChecked="preChecked(item)" />
      </li>
    </ul>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import SearchFilterItem from './SearchFilterItem.vue';

  export default {
    name: 'Search Filter Group',
    props: {
      filter: {
        type: Object,
        required: true,
        default() {
          return {};
        },
      },
      isMobile: {
        type: Boolean,
        default() {
          return false;
        },
      },
      visible: {
        type: Boolean,
        default() {
          return false;
        },
      },
      isRadio: {
        type: Boolean,
      },
    },
    data() {
      return {
        toggleFilter: this.visible,
      };
    },
    components: {
      SearchFilterItem,
    },
    mounted() {
      if (this.filter.filterKey === 'AssessmentType') {
        this.toggleFilter = true;
      }
    },
    methods: {
      ...mapGetters(['getFilter']),
      preChecked(item) {
        const filterKey = `filter${item.filterKey}`;
        const filterObj = this.getFilter();

        // Check if key exsist in object
        if (filterKey in filterObj) {
          // If key exsist, check if ID exsist in array
          if (filterObj[filterKey].includes(item.filterName)) {
            this.toggleFilter = true;
            return true;
          }
          if (filterObj[filterKey].toString().toLowerCase().trim().includes(item.filterId.toString().toLowerCase().trim())) {
            this.toggleFilter = true;
            return true;
          }
        }
        return false;
      },
      // Check if sub items should be expanded
      showSubItems(item) {
        const filterKey = 'filterSubFilter';
        const filterObj = this.getFilter();
        let showSubItems = false;

        // If item has sub items
        if (item.subItems && item.subItems.length > 0) {
          item.subItems.forEach((i) => {
            // If filtered on sub item
            if (filterObj && filterObj[filterKey] && filterObj[filterKey].includes(i.filterId)) {
              this.toggleFilter = true;
              showSubItems = true;

            // If filtered on parent item
            } else if (i.parentFilterId) {
                const parentFilterKey = `filter${i.parentFilterKey}`;
                const parentFilterId = parseInt(i.parentFilterId, 0);
                if (filterObj && filterObj[parentFilterKey] && filterObj[parentFilterKey].includes(parentFilterId)) {
                  showSubItems = true;
                }
              }
          });
        }
        return showSubItems;
      },
      sortFilterNames(filter) {
        let filtersSorted = filter;
        if (filter.length > 0 && (filter[0].filterKey === 'Year')) {
          filtersSorted = filtersSorted.sort((a, b) => {
            if (a.filterName < b.filterName) {
              return 1;
            }
            if (a.filterName > b.filterName) {
              return -1;
            }
            return 0;
          });
          return filtersSorted;
        }
        filtersSorted = filtersSorted.sort((a, b) => {
          if (a.filterName < b.filterName) {
            return -1;
          }
          if (a.filterName > b.filterName) {
            return 1;
          }
          return 0;
        });
        return filtersSorted;
      },
    },
  };
</script>
