<template>
  <div class="flex flex-col md:flex-row mb-11 md:mb-9">
    <div v-if="model.imageUrl" class="w-full md:w-1/2 md:pr-9">
      <img :src="model.imageUrl" class="rounded-lg mb-3 md:mb-0" />
    </div>
    <div :class="model.imageUrl ? 'md:w-1/2' : ''" class="w-full grid content-center">
      <div class="mb-3">
        <EpiLink :url="model.url">
          <h3 class="mb-3 font-serif font-semibold text-hsm break-all md:break-normal">{{model.name}}</h3>
          <p class="mb-2 text-gray-300">{{getDateString}}</p>
          <p class="text-base">{{model.preamble}}</p>
        </EpiLink>
      </div>
      <div class="ml-auto">
        <EpiLink :url="model.url">
          <span class="text-lg font-medium mr-2">Les mer</span>
          <font-awesome-icon class="mr-2 text-red-200" icon="long-arrow-alt-right" />
        </EpiLink>
      </div>
    </div>
  </div>
</template>
<script>
  import EpiLink from '@/components/EpiLink.vue';

  export default {
    props: {
      model: {
        type: Object,
      },
    },
    components: {
      EpiLink,
    },
    computed: {
      getDateString() {
        const date = new Date(this.model.publishdate);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return date?.toLocaleDateString('no-NO', options);
      },
    },
  };
</script>
