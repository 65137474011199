<template>
  <div>
    <FieldTitle :model="model" />
    <div class="flex">
      <div class="w-full pr-0 xl:w-3/4">
        <div class="relative">
          <font-awesome-icon v-show="isSaving" icon="spinner" class="animate-spin absolute right-2.5 top-3" />
        </div>
        <div>
          <select :disabled="isSaving" :class="isSaving ? ' bg-gray-100 border-gray-300' : ''" v-model="selected" @change="save">
            <option disabled value="">{{$t('schema.common.action.pleaseSelect')}}</option>
            <option v-for="(option, index) in selectable" :key="$uuid(index)" v-bind:value="option.value">{{option.text}}</option>
          </select>
        </div>
      </div>
      <div class="hidden w-1/4 pr-0 mb-4 xl:block lg:pl-4">
        <CommentPane
          v-if="!model.deactivateComments"
          :model="model.comments"
          :contentGuid="model.contentGuid"
          :pageId="pageId"
          :fieldTitle="model.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import CommentPane from '@/views/components/schema/CommentPane.vue';
  import FieldTitle from '@/views/components/schema/common/FieldTitle.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: true,
      },
    },
    components: {
      CommentPane,
      FieldTitle,
    },
    data() {
      return {
        selected: this.model.properties.selected !== undefined ? this.model.properties.selected[0] : '',
        selectable: this.model.properties.selectable,
        isSaving: false,
      };
    },
    methods: {
      save() {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.model.contentGuid, value: [this.selected], pageId: this.pageId,
          }),
        };
        const self = this;
        this.isSaving = true;
        fetch(`${window.location.origin}/api/schema/SaveSchemaItem`, requestOptions)
          .then(() => {
            this.$emit('onSave');
          }).then(() => {
            self.isSaving = false;
          });
      },
    },
  };
</script>
