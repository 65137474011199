<template>
  <div class="w-full pr-0" >

    <!-- Oversiktsmodell -->
    <div v-if="model.properties.overviewSection">
      <Section :model="model.properties.overviewSection" :pageId="pageId">
        <LiteratureSearchOverview :figureData="model.properties.overviewData"
                                  :preview="preview"
                                  :readOnly="readOnly" />
      </Section>
    </div>
    <div v-if="model.properties.overviewInputSection">
      <Section :model="model.properties.overviewInputSection"
               :pageId="pageId"
               :preview="preview"
               :readOnly="readOnly" />
    </div>

    <!-- A) Søk rettingslinjer -->
    <div v-if="model.properties.sectionA">
      <Section :model="model.properties.sectionA"
               :pageId="pageId"
               :readOnly="readOnly">
        <LiteratureSearchInput :contentId="model.contentId"
                               :items="model.properties.itemsA"
                               :dbSources="model.properties.dbSources"
                               :netSources="model.properties.netSources"
                               :section="a"
                               :preview="preview"
                               :readOnly="readOnly"
                               @get-schema="getSchema" />
      </Section>
    </div>

    <!-- Søk systematisk oppsummert forskning -->
    <div v-if="model.properties.sectionB">
      <Section :model="model.properties.sectionB"
               :pageId="pageId"
               :readOnly="readOnly">
        <LiteratureSearchInput :contentId="model.contentId"
                               :items="model.properties.itemsB"
                               :dbSources="model.properties.dbSources"
                               :netSources="model.properties.netSources"
                               :section="b"
                               :preview="preview"
                               :readOnly="readOnly"
                               @get-schema="getSchema" />
      </Section>
    </div>
    <!-- Søk primærstudier -->
    <div v-if="model.properties.sectionC">
      <Section :model="model.properties.sectionC"
               :pageId="pageId"
               :readOnly="readOnly">
        <LiteratureSearchInput :contentId="model.contentId"
                               :items="model.properties.itemsC"
                               :dbSources="model.properties.dbSources"
                               :netSources="model.properties.netSources"
                               :section="c"
                               :preview="preview"
                               :readOnly="readOnly"
                               @get-schema="getSchema" />
      </Section>
    </div>
    <!-- Søk pågående og upubliserte studier (valgfritt) -->
    <div v-if="model.properties.sectionD">
      <Section :model="model.properties.sectionD"
               :pageId="pageId"
               :readOnly="readOnly">
        <LiteratureSearchInput :contentId="model.contentId"
                               :items="model.properties.itemsD"
                               :dbSources="model.properties.dbSources"
                               :netSources="model.properties.netSources"
                               :section="d"
                               :preview="preview"
                               :readOnly="readOnly"
                               @get-schema="getSchema"/>
      </Section>
    </div>
    <!-- Søk helseøkonomiske evalueringer (valgfritt) -->
    <div v-if="model.properties.sectionE">
      <Section :model="model.properties.sectionE"
               :pageId="pageId"
               :readOnly="readOnly">
        <LiteratureSearchInput :contentId="model.contentId"
                               :items="model.properties.itemsE"
                               :dbSources="model.properties.dbSources"
                               :netSources="model.properties.netSources"
                               :section="e"
                               :preview="preview"
                               :readOnly="readOnly"
                               @get-schema="getSchema" />
      </Section>
    </div>

  </div>
</template>

<script>
  import Section from '@/views/components/schema/Section.vue';
  import LiteratureSearchInput from '@/views/components/schema/literatureSearch/LiteratureSearchInput.vue';
  import LiteratureSearchOverview from '@/views/components/schema/literatureSearch/LiteratureSearchOverview.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      LiteratureSearchInput,
      LiteratureSearchOverview,
      Section,
    },
    methods: {
      getSchema() {
        this.$emit('getSchema');
      },
    },
    data() {
      return {
        overview: null,
        a: 'A',
        b: 'B',
        c: 'C',
        d: 'D',
        e: 'E',
      };
    },
    emits: ['getSchema'],
  };
</script>
