// import appConfig from "./appConfig";

const config = {
  schema: 'html5',
  plugins: [
    'lists link paste',
  ],
  toolbar: 'undo redo | bold italic underline | bullist numlist outdent indent | link | code',
  height: '500',
  menu: {
    file: { title: 'File', items: 'preview | print' },
    edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
    view: { title: 'View', items: 'code' },
    insert: { title: 'Insert', items: 'link' },
    format: { title: 'Format', items: 'bold italic underline | removeformat' },
    // table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
    help: { title: 'Help', items: 'help' },
  },
};

const tinyConfig = {
  getAPIKey() {
    // return appConfig.get('tiny.key');
    return 'edkx0nsecn0gk8pomar4t65k9zmp0va2xd3yxt2axmq8la7g';
  },
  getConfig() {
    return config;
  },
};

Object.freeze(tinyConfig);

export default tinyConfig;
