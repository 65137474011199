<template>
  <nav aria-label="brødsmulesti">
    <ol class="flex flex-wrap justify-start -mx-2 text-sm md:flex-nowrap">
        <BreadcrumbItem
          v-for="(crumb, index) in model.breadCrumbs"
          :key="$uuid(index)"
          :item="crumb"
          :last="index === model.breadCrumbs.length -1"
          :first="index === 0"
        />
    </ol>
  </nav>
</template>
<script>
import BreadcrumbItem from './BreadcrumbItem.vue';

export default {
  name: 'Breadcrumb',
  components: {
    BreadcrumbItem,
  },
  props: {
    model: {
      type: Object,
    },
  },
};
</script>
