<template>
  <div class="flex">
    <div class="pr-0" :class="preview ? 'w-full' : 'w-3/4'">
      <!-- Kunnskapsoppsummeringer -->
      <div v-if="model.properties.knowledgeSummarySection">
        <Section :model="model.properties.knowledgeSummarySection"
                 :pageId="pageId"
                 :readOnly="readOnly"
                 @get-schema="getSchema">
          <EvidenceTableInput :contentId="model.contentId"
                              :items="model.properties.knowledgeSummaryItems"
                              :properties="model.properties"
                              :section="KnowledgeSummary"
                              :preview="preview"
                              :readOnly="readOnly"
                              @get-schema="getSchema"/>
        </Section>
      </div>

      <!-- Primærstudier -->
      <div v-if="model.properties.primaryStudySection">
        <Section :model="model.properties.primaryStudySection"
                 :pageId="pageId"
                 :readOnly="readOnly"
                 @get-schema="getSchema">
          <EvidenceTableInput :contentId="model.contentId"
                              :items="model.properties.primaryStudyItems"
                              :properties="model.properties"
                              :section="PrimaryStudy"
                              :preview="preview"
                              :readOnly="readOnly"
                              @get-schema="getSchema"/>
        </Section>
      </div>
    </div>
    <!--  Comments-->
    <div v-if="!readOnly" class="hidden w-1/4 pr-0 mb-4 xl:block lg:pl-4">
      <CommentPane v-if="!model.deactivateComments"
                   :model="model.comments"
                   :contentGuid="model.contentGuid"
                   :pageId="pageId"
                   :fieldTitle="model.title"/>
    </div>
  </div>
</template>

<script>
import EvidenceTableInput from '@/views/components/schema/evidenceTable/EvidenceTableInput.vue';
import Section from '@/views/components/schema/Section.vue';
import CommentPane from '@/views/components/schema/CommentPane.vue';

export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
    pageId: {
      type: String,
      required: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
    },
    preview: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      KnowledgeSummary: 'KnowledgeSummary',
      PrimaryStudy: 'PrimaryStudy',
    };
  },
  methods: {
    getSchema() {
      this.$emit('getSchema');
    },
  },
  components: {
    EvidenceTableInput,
    Section,
    CommentPane,
  },
  emits: ['getSchema'],
};
</script>
