<template>
  <div v-if="readOnly">
    <h1 class="mb-2 font-serif font-semibold text-hlg text-left">
      {{ currentText }}
    </h1>
  </div>
  <div v-else>
    <div v-if="!isEditing">
      <div class="inline-flex">
        <button tabindex="-1" class="cursor-text" @click.prevent="edit()">
          <h1 class="mb-2 font-serif font-semibold text-hlg text-left">
            {{ currentText }}
          </h1>
        </button>
        <IconButton @click="edit"
                    :icon="'edit'"
                    :title="$t('schema.common.action.editText')"
                    class="ml-2 self-start"
                    size="base" />
      </div>
      <SchemaError v-if="!savingStatus.success"
                   :message="savingStatus.message"
                   :error="savingStatus.details" />
    </div>
    <div v-else v-click-outside="onClickOutside" class="relative">
      <textarea class="
        nomargin
        font-serif
        text-hlg
        font-semibold
        mb-2
        overflow-hidden
        resize-none
      "
                v-model="editedText"
                ref="textArea"
                :tabindex="isSaving ? '-1' : '0'"
                :disabled="isSaving"
                :placeholder="$t('schema.introPage.methodEvaluationTitle.label')" />
      <font-awesome-icon v-show="isSaving"
                         icon="spinner"
                         class="animate-spin absolute right-2.5 top-3" />
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';

export default {
  emits: ['changed'],
  props: {
    text: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
    },
    requireCallbackAfterSave: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
      currentText: '',
      editedText: '',
      isSaving: false,
      savingStatus: {
        success: true,
        message: null,
        details: null,
      },
    };
  },
  methods: {
    // Click outside automatically saves the text
    onClickOutside() {
      this.saveChanges();
    },
    edit() {
      this.editedText = this.currentText;
      this.isEditing = true;
      this.$nextTick(() => {
        this.$refs.textArea.focus();
        this.$refs.textArea.addEventListener('focusout', this.saveChanges);
      });
    },
    cancelEdit() {
      this.$refs.textArea.removeEventListener('focusout', this.saveChanges);
      this.editedText = '';
      this.isEditing = false;
    },
    saveChanges() {
      if (this.isSaving) {
          return;
      }

      if (!this.hasChanged()) {
        this.cancelEdit();
        return;
      }
      this.isSaving = true;
      this.$emit('changed', {
        text: this.editedText,
        callback: this.saveCallback,
      });

      if (!this.requireCallbackAfterSave) {
        this.currentText = this.editedText;
        this.cancelEdit();
      }
    },
    saveCallback(status) {
      this.isSaving = false;

      if (status.success) {
        this.currentText = this.editedText;
      }

      this.cancelEdit();
      this.setSavingStatus(status.success, status.message, status.details);
    },
    setSavingStatus(success, message, details) {
      this.savingStatus.success = success;
      this.savingStatus.message = message;
      this.savingStatus.details = details;
    },
    hasChanged() {
      return this.editedText !== this.currentText;
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {
    text() {
      this.currentText = this.text;
    },
  },
  mounted() {
    this.currentText = this.text;
  },
};
</script>
