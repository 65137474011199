<template>
  <textarea :name="name"
            :id="name"
            @input="$emit('update:modelValue', $event.target.value); resize($event)"
            :value="modelValue"
            ref="guid"
            :readonly="readOnly"
            :placeholder="placeholder">
  </textarea>
</template>

<script>

  export default {
    props: {
      name: {
        type: String,
        required: true,
      },
      modelValue: {
        required: false,
      },
      placeholder: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
    },
    data() {
      return {
        guid: this.$uuid(),
      };
    },
    mounted() {
      const element = this.$refs.guid;
      element.style.height = 'auto';
      if (element.scrollHeight === 0) {
        element.style.height = '66px';
      } else {
        element.style.height = `${element.scrollHeight + 4}px`;
      }
    },
    methods: {
      resize(e) {
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight + 4}px`;
      },
    },
  };
</script>
