<template>
    <div class="container-narrow">
        <h1 class="text-3xl font-black mb-10">{{$t('error.404page.header')}}</h1>
        <p class="mb-10">
            {{$t('error.404page.description')}}
        </p>
        <div class="w-full md:w-1/2 mb-10">
          <div style="width:100%;height:0;padding-bottom:56%;position:relative;">
            <iframe src="https://giphy.com/embed/xT1XGWbE0XiBDX2T8Q" width="100%" height="100%" style="position:absolute" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
          </div>
        </div>
    </div>
</template>
