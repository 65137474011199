<template>
  <div v-if="figureData">
    <div class="border-2 rounded mb-4">
      <div class="bg-bluegray-300 p-4">
        <span class="text-hxs font-serif">{{$t('schema.literatureSearch.label.databaseSources')}}</span>
      </div>
      <div class="p-4">
        <div v-for="item in figureData.databaseSearches" :key="item.id">
          <div v-if="item.items.length > 0" class="mb-2">
            <div class="text-base font-semibold mb-2">
              <p>{{item.name}} ( n = {{item.categoryTotal}} )</p>
            </div>
            <div v-for="subItem in item.items" :key="subItem.id">
              <p>{{subItem.source}} ( n = {{subItem.hits}} )</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-2 rounded mb-4">
      <div class="bg-bluegray-300 p-4">
        <span class="text-hxs font-serif">{{$t('schema.literatureSearch.label.webSources')}}</span>
      </div>
      <div class="p-4">
        <div v-for="item in figureData.webSearches" :key="item.id">
          <div v-if="item.items.length > 0" class="mb-2">
            <div class="text-base font-semibold mb-2">
              <p>{{item.name}}</p>
            </div>
            <div v-for="subItem in item.items" :key="subItem.id">
              <p>{{subItem.source}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="italic">
    Ingen søk registrert
  </div>
</template>

<script>

  export default {
    props: {
      figureData: {
        type: Object,
        required: true,
      },
    },
  };
</script>
