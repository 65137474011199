import { createRouter, createWebHistory } from 'vue-router';
import { ResolvedContentStatus } from '@episerver/content-delivery';
import { UPDATE_MODEL_BY_URL } from '@/store/modules/epiDataModel';
import store from '@/store';
import authService from '@/authService';
import PageComponentSelector from '@/components/EpiPageComponentSelector.vue';
import LoginCallback from '@/components/LoginCallback.vue';
import LoginRenewal from '@/components/LoginRenewal.vue';
import AccessDenied from '@/views/403.vue';
import NotFound from '@/views/404.vue';

const router = createRouter({
  // Use the HTML HistoryAPI so the # isn't needed in the URL, and
  // Vue routing will work even when going directly to an URL.
  history: createWebHistory(),

  // https://router.vuejs.org/guide/advanced/scroll-behavior.html#scroll-behavior
  scrollBehavior(to, from, savedPosition) {
    if (to.path === '/sok/') {
      if (from.path === '/') {
        return { top: 0 };
      }
      return null;
    }
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      // console.log(from);
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return { top: 0 };
  },

  routes: [
    {
      path: '/login-callback',
      component: LoginCallback,
    },
    {
      path: '/login-renewal',
      component: LoginRenewal,
    },
    {
      path: '/access-denied',
      component: AccessDenied,
    },
    {
      path: '/not-found',
      component: NotFound,
    },
    {
      name: 'page-component-selector',
      path: '/:pathMatch(.*)',
      component: PageComponentSelector,
    },
  ],
});
router.beforeEach((to, from, next) => {
  // URL is updated by vue-route-sync, and when time travelling with the
  // debugger we don't want to trigger a model commit as the model is already
  // part of the store holding the url update.

  const searchHandler = (params, action) => {
    // Set query to store
    store.commit(action, params);
  };
  if (to.query) {
    if (to.query.q) {
      searchHandler(to.query.q, 'setQuery');
    }
    if (to.query.sort) {
      searchHandler(to.query.sort, 'setSort');
    }
    if (to.query.page) {
      searchHandler(to.query.page, 'setPageNum');
    }
    if (to.query.published) {
      searchHandler(to.query.published, 'setPublished');
    }
    let filterParams = Object.keys(to.query);
    filterParams = filterParams.map((i) => {
      if (i.startsWith('filter')) {
        if (i.endsWith('Source', 12)) {
          return { filterId: to.query[i].split(',').map((x) => x), filterKey: i.replace('filter', '') };
        }
        return { filterId: to.query[i].split(',').map(Number), filterKey: i.replace('filter', '') };
      }
      return false;
    });
      filterParams.map((fp) => {
        if (fp) {
          fp.filterId.forEach((id) => {
            if (id !== 0 && id !== '') {
              searchHandler({ filterId: id, filterKey: fp.filterKey }, 'setFilter');
            }
          });
      }
      return null;
    });
  }
  if (to.name === 'page-component-selector' && store.state.epiDataModel.model.url !== to.fullPath) {
    store.dispatch(UPDATE_MODEL_BY_URL, to.fullPath).then(() => {
      switch (store.state.epiDataModel.status) {
        case ResolvedContentStatus.NotFound:
          router.replace('/not-found');
          break;
        case ResolvedContentStatus.Unauthorized:
          // Prevent redirect loop.
          authService.getUser().then((user) => {
            if (!user || user.expired) {
              authService.login();
            } else {
              router.replace('/access-denied');
            }
          });
          break;
        case ResolvedContentStatus.AccessDenied:
          router.replace('/access-denied');
          break;
        default:
      }
    });
  }

  next();
});

export default router;
