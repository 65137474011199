<template>
  <div class="container lg:mt-20 flex flex-col lg:flex-row">
    <!-- Mobile filterpane -->
    <transition name="slideFromRight">
      <section v-if="showFilterPane" class="overflow-x-scroll flex flex-col absolute inset-0 p-8 z-50 bg-bluegray-200 lg:hidden">
        <div class="flex justify-end mb-8 relative">
            <button
              @click="showFilterPane = !showFilterPane"
              class="flex items-center text-xl group"
              :title="$t('window.close.description.filter')"
            >
              <span class="group-hover:underline">{{$t("window.close.text")}}</span>
              <span class="flex items-center justify-center w-10 h-10 ml-2 border border-gray-400 rounded-full group-hover:bg-white group-hover:bg-opacity-80">
                <font-awesome-icon class="text-lg align-middle" icon="times" />
              </span>
            </button>
          </div>
          <SearchFilterGroup
            v-for="(group, index) in data.searchFilter"
            :key="$uuid(index)"
            :filter="group"
          />
          <div class="mt-auto bg-bluegray-300 w-full">
            <button
              @click.prevent="showFilterPane = !showFilterPane"
              class="mt-auto btn btn--bluedark w-full"
              :title="$t('search.results.showSearchResults.description')"
            >
              {{searchResultText(data.totalMatching)}}
            </button>
        </div>
      </section>
    </transition>

    <transition name="slideFromLeft">
      <section v-if="showSortPane" class="flex flex-col absolute inset-0 p-8 z-50 bg-bluegray-200 lg:hidden">
        <div class="flex justify-end mb-8 relative">
              <button
                @click="showSortPane = !showSortPane"
                class="flex items-center text-xl group"
                :title="$t('window.close.description.sorting')"
              >
                <span class="group-hover:underline">{{$t("window.close.text")}}</span>
                <span class="flex items-center justify-center w-10 h-10 ml-2 border border-gray-400 rounded-full group-hover:bg-white group-hover:bg-opacity-80">
                  <font-awesome-icon class="text-lg align-middle" icon="times" />
                </span>
              </button>
            </div>
            <SearchSort :key="$uuid()"/>
            <button
              @click.prevent="showSortPane = !showSortPane"
              class="mt-auto btn btn--bluedark w-full"
              :title="$t('search.results.showSearchResults.description')"
            >
              {{searchResultText(data.totalMatching)}}
            </button>
        </section>
      </transition>

      <div class="lg:pr-12 lg:mt-16 w-full lg:w-1/4">
        <!-- Desktop filterpane -->
        <section v-if="data" class="hidden lg:block">
          <SearchFilterGroup
            v-for="(group, index) in data.searchFilter"
            :key="`${group.filterKey}_${index}`"
            :filter="group"
          />
        </section>
        <!-- vente til varslingstjenesten er klar før dette brukes: -->
        <!-- <NotificationSignup @openPopup="openPopup"/>
        <div v-if="showPopup">
          <PopupTopic @close="closePopup"/>
        </div> -->
    </div>

    <!--Heading-->
    <div class="w-full lg:w-3/4">
      <div class="flex flex-col lg:flex-row">
        <h1 class="font-serif font-semibold text-hlg mb-7 w-full lg:w-3/4">
          {{ model.heading ?? model.name }}
        </h1>

        <!--Search Help text-->
        <div v-if="this.model.searchInfoText"  class="w-full text-left mb-6 lg:mb-0 lg:w-1/4 lg:text-right">
          <button
            @click="toggleSearchTips(true)"
            class="group"
          >
            <span class="mr-1 underline group-hover:no-underline">
              {{$t('search.searchtips')}}
            </span>
            <font-awesome-icon icon="question-circle" />
          </button>
          <HelpPopUp @close="toggleSearchTips(false)" :text="model.searchInfoText" v-if="showTips"/>
        </div>

      </div>

      <!--Search form-->
        <SearchForm v-if="data" :key="$uuid()"  :label="$t('search.searchBar.label.notification')" :placeholder="$t('search.searchBar.placeholder.notification')"/>
        <section class="lg:hidden flex flex-row justify-between mb-5">
          <button
            @click="showSortPane = !showSortPane"
            class="text-base flex items-center hover:underline"
            type="button"
            :title="$t('search.sort.sorting.description')"
          >
            <font-awesome-icon icon="sort-amount-up" class="mr-2"/>
            {{$t("search.sort.sorting.text")}}
          </button>

          <button
            @click="showFilterPane = !showFilterPane"
            class="text-base flex items-center hover:underline"
            type="button"
            :title="$t('search.filter.showFilters.description')"
          >
            <font-awesome-icon icon="sliders-h" class="mr-2" />
           {{$t('search.filter.showFilters.text') + ' (' + getFilterCount + ')'}}
        </button>
      </section>

      <SearchSelectedFilterButtons v-if="data" :filters="data.searchFilter" />

      <!-- Paging and sort desktop -->
      <SearchPaging :showSort="true" />

      <!-- Results -->
        <Loading v-if="!data" class="mb-10"/>
        <SearchResultList v-if="data"
                          :results="data.searchResult"
                          type='methodnotification'
                          :searchSettings="model.searchSettings"
                          :key="$uuid()"
                          :isEditor="model.isEditor"/>
        <SearchPaging :showSort="false" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import SearchFilterGroup from '@/views/components/search/SearchFilterGroup.vue';
  import SearchSort from '@/views/components/search/SearchSort.vue';
  import SearchSelectedFilterButtons from '@/views/components/search/SearchSelectedFilterButtons.vue';
  import SearchResultList from '@/views/components/search/SearchResultList.vue';
  // import PopupTopic from '@/views/components/popup/popupTopic.vue';
  import Loading from '@/views/components/Loading.vue';
  // import NotificationSignup from '@/views/components/notificationsignup/NotificationSignup.vue';
  import HelpPopUp from '@/views/components/help/HelpPopUp.vue';
  import SearchPaging from '@/views/components/search/SearchPaging.vue';

  export default {
    name: 'Search',
    props: {
      model: {
        type: Object,
      },
    },
    components: {
      // PopupTopic,
      HelpPopUp,
      SearchSelectedFilterButtons,
      SearchResultList,
      SearchFilterGroup,
      SearchSort,
      Loading,
      // NotificationSignup,
      SearchPaging,
    },
    data() {
      return {
        showPopup: false,
        showFilterPane: false,
        showSortPane: false,
        showTips: false,
      };
    },
    computed: {
      ...mapGetters({
        data: 'getData',
      }),
      getFilterCount() {
        if (this.data) {
          return this.data.searchFilter.length;
        }
        return 0;
      },
    },
    methods: {
      ...mapActions(['performSearch']),

      fetchProducts() {
        this.$store.dispatch('performSearch');
      },
      searchResultText(resultCount) {
        if (resultCount <= 1) {
          return this.$tc('search.results.showSearchResults.text', 0, { count: resultCount });
        }
        return this.$tc('search.results.showSearchResults.text', 1, { count: resultCount });
      },
      openPopup() {
        this.showPopup = true;
      },
      closePopup() {
        this.showPopup = false;
      },
      toggleSearchTips(value) {
        this.showTips = value;
      },
    },
    watch: {
      filterIds(newVal) {
        this.filterIds = newVal;
      },
    },
    mounted() {
      this.fetchProducts();
    },
  };
</script>

<style scoped>
  .slideFromRight-enter-active,
  .slideFromRight-leave-active {
    transition: transform 0.5s ease;
    transform: translateX(0);
  }

  .slideFromRight-enter-from,
  .slideFromRight-leave-to {
    transform: translateX(100%);
  }

  .slideFromLeft-enter-active,
  .slideFromLeft-leave-active {
    transition: transform 0.5s ease;
    transform: translateX(0);
  }

  .slideFromLeft-enter-from,
  .slideFromLeft-leave-to {
    transform: translateX(-100%);
  }
</style>
