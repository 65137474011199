<template>
  <nav
    class="sticky top-4"
    aria-label="Innholdsfortegnelse"
  >
    <ul>
      <li
        v-for="(item, index) in sections"
        :key="$uuid(index)"
        class="mb-3"
      >
        <button
          @click="setSection(item.contentGuid)"
          class="hover:underline hover:text-red-300 text-left"
          :class="item.contentGuid === openSection ? 'font-semibold' : 'font-normal'"
        >
          <font-awesome-icon
            v-if="item.contentGuid === openSection"
            icon="chevron-right"
            class="mr-2 text-sm"
          />
          <span>{{item.title}}</span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
  export default {
    props: {
      sections: {
        type: Object,
        required: true,
      },
      openSection: {
        type: String,
        required: false,
      },
    },
    methods: {
      setSection(id) {
        this.$emit('toggleSections', id);
      },
    },
  };
</script>
