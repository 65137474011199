<template>
  <div :class="model.showTableOfContent ? 'container' : 'container-article'"
       class="grid grid-cols-12 relative">
    <article id="Article-area"
             :class="model.showTableOfContent ? 'col-span-12 lg:col-span-7' : 'col-span-12'">

      <h1 v-if="model.heading || this.$route.query.epieditmode"
          v-epi-edit="'Heading'"
          class="mb-6 font-serif font-semibold text-hlg">
        {{ model.heading }}
      </h1>

      <p v-if="model.introduction || this.$route.query.epieditmode"
         v-epi-edit="'Introduction'"
         class="mb-6 text-xl font-normal">
        {{ model.introduction }}
      </p>

      <div v-if="!model.hideChangeLog"
           class="flex flex-col pb-6 mb-6 space-x-4 border-b border-gray-200 md:flex-row">
        <ChangeLogSimple :model="model"
                         class="mb-4 md:mb-0" />

        <Toolbar :model="model"
                 :showNotify="true"
                 :showPrint="true"
                 :hideContact="true" />
      </div>

      <TableOfContent v-if="model.showTableOfContent" class="block lg:hidden" :target="'#Article-area'" />

      <div v-epi-edit="'TopContent'"
           v-if="model.topContent || this.$route.query.epieditmode"
           class="mb-14 mt-14">
        <ContentArea :model="model.topContent" />
      </div>

      <div v-epi-edit="'MainBody'"
           v-if="model.mainBody || this.$route.query.epieditmode"
           v-html="model.mainBody"
           class="article"></div>

      <div v-epi-edit="'MainContent'"
           v-if="model.mainContent || this.$route.query.epieditmode">
        <ContentArea :model="model.mainContent" />
      </div>

      <div v-if="model.author || this.$route.query.epieditmode"
           v-epi-edit="'Author'"
           class="pt-6 mt-6 border-t border-graylight-300 article">
        <p class="text-gray-300">{{model.author}}</p>
      </div>
    </article>
    <aside v-if="model.showTableOfContent" class="lg: col-start-9 xl:col-start-10 col-end-13">
      <TableOfContent class="hidden lg:block sticky top-20 bottom-20" :target="'#Article-area'" />
    </aside>
  </div>
</template>

<script>
import ContentArea from '@/components/EpiContentArea.vue';
import ChangeLogSimple from '@/components/article/ChangeLogSimple.vue';
import Toolbar from '@/views/components/toolbar/Toolbar.vue';
import TableOfContent from '@/views/components/tableOfContent/TableOfContent.vue';

export default {
  components: {
    ContentArea,
    ChangeLogSimple,
    Toolbar,
    TableOfContent,
  },
  props: ['model'],
};
</script>
