<template>
    <div class="mb-6 mt-1 flex justify-left space-y-1 sm:space-x-2 lg:space-x-0 2xl:space-y-0 2xl:space-x-2">
            <button
            class="btn rounded-3xl px-6 w-auto sm:w-1/3 lg:w-full 2xl:w-auto"
            :class="isFilteredByAll ? 'btn--charcoalgray' : 'btn--charcoalgray__outline'"
            :disabled="isFilteredByAll"
            @click="setFilterShowAll">{{$t('methodEvaluationSearch.filterByAll')}}</button>

            <button
            class="btn rounded-3xl px-3 w-auto sm:w-1/3 lg:w-full 2xl:w-auto"
            :class="isFilteredByCompleted ? 'btn--charcoalgray' : 'btn--charcoalgray__outline'"
            :disabled="isFilteredByCompleted"
            @click="setFilterByCompleted">{{$t('methodEvaluationSearch.filterByCompleted')}}</button>

            <button
            class="btn rounded-3xl px-3.5 w-auto sm:w-1/3 lg:w-full 2xl:w-auto"
            :class="isFilteredByStarted ? 'btn--charcoalgray' : 'btn--charcoalgray__outline'"
            :disabled="isFilteredByStarted"
            @click="setFilterByStarted">{{$t('methodEvaluationSearch.filterByStarted')}}</button>
          </div>
</template>

<script>
  import { mapMutations } from 'vuex';

export default {
    name: 'Search Selected State Filter',
    emits: ['filterChanged'],
    props: {
        filterByCompleted: Boolean,
        filterByStarted: Boolean,
    },
    computed: {
        isFilteredByCompleted() {
            return this.filterByCompleted && !this.filterByStarted;
        },
        isFilteredByStarted() {
            return !this.filterByCompleted && this.filterByStarted;
        },
        isFilteredByAll() {
            return (this.filterByCompleted && this.filterByStarted)
            || (!this.filterByCompleted && !this.filterByStarted);
        },
    },
    methods: {
      ...mapMutations(['setPageNum']),
      setFilterByCompleted() {
            this.toggleFilters(true, false);
        },
        setFilterByStarted() {
            this.toggleFilters(false, true);
        },
        setFilterShowAll() {
            this.toggleFilters(true, true);
        },
      toggleFilters(completed, started) {
            this.setPageNum(1);
            this.$emit('update:filterByCompleted', completed);
            this.$emit('update:filterByStarted', started);
            this.$emit('filterChanged');
        },
    },
};
</script>
