<template>
  <div>
    <div :class="preview ? 'w-full' : 'w-3/4'">
      <FieldTitle :model="model"/>
    </div>

    <!--Read only-->
    <div v-if="readOnly" :class="preview ? 'w-full' : 'w-3/4'">
      <!--Dropdown with singleselect-->
      <div v-if="model.properties.singleSelect && !model.properties.multiSelect" class="mb-4">
        <div v-for="(item, index) in model.properties.selectable" :key="$uuid(index)" :value="item.value" disabled>
          <div v-if="item.value === singleSelected">
            <div v-html="item.text" class="w-full border bg-graylight-100 p-4 rounded"/>
          </div>
        </div>
      </div>
      <!--Checkboxes-->
      <div v-else class="mb-2">
        <div v-for="(item, index) in model.properties.selectable" :key="index">
          <div v-if="this.isMultiSelected(item.value)">
            <CustomCheckbox :cbxId="item.value"
                            :cbxValue="item.value"
                            :cbxDisabled="true"
                            :cbxLabel="item.text"
                            v-model="multiSelected"/>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit mode -->
    <div v-else class="flex">
      <div class="w-full pr-0 xl:w-3/4">
        <div>
          <font-awesome-icon v-show="isSaving" icon="spinner" class="animate-spin absolute right-2.5 top-3"/>
        </div>

        <!-- Dropdown with single select-->
        <div v-if="model.properties.singleSelect && !model.properties.multiSelect">
            <select class="p-4 bg-transparent border-2" @change="save" v-model="singleSelected">
              <option v-for="(item, index) in model.properties.selectable" :key="$uuid(index)" :value="item.value">
                {{ item.text }}
              </option>
            </select>
        </div>

        <!--Dropdown with multiselect-->
        <div v-if="model.properties.singleSelect && model.properties.multiSelect" class="mb-6">
            <!--Selected items-->
            <div class="selected-items w-full border border-black rounded p-4 flex flex-wrap">
              <div
                v-for="(item, index) in multiSelected"
                :key="index"
                class="selected-item flex items-center bg-bluegray-200 rounded-full px-4 py-2 mb-2 mr-2"
              >
                {{ getSelectedById(item) }}
                <button @click="removeItem(item)" class="remove-item items-center ml-2">
                  <font-awesome-icon icon="times"/>
                </button>
              </div>
            </div>
            <!--Toggle dropdownlist-->
            <div ref="dropdownMenu" class="w-1/2">
              <div class="w-full border rounded p-2 mt-4 flex justify-between" @click="toggleDropdown()">
                <span>Velg</span>
                <span class="ml-auto"><font-awesome-icon icon="caret-down"/></span>
              </div>
              <!--Dropdownlist-->
              <div v-if="isDropdownOpen" class="dropdown-options w-full border mt-1">
                <div v-for="(item, index) in model.properties.selectable" :key="index" class="option p-2">
                  <label class="flex">
                    <input
                      type="checkbox"
                      :value="item.value"
                      class="custom--inputbox__checkbox flex-grow-0 flex-shrink-0 mt-0.5 mb-0.5"
                      @change="toggleItem(item)"
                      :checked="multiSelected.includes(item.value)"
                    />
                    <span class="flex justify-between text-sm text-left mb-0 self-center">{{ item.text }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

        <!--Checboxes-->
        <div v-if="!model.properties.singleSelect">
          <div v-for="(item, index) in model.properties.selectable" :key="index">
            <CustomCheckbox
              :cbxId="item.value"
              :cbxValue="item.value"
              :cbxLabel="item.text"
              v-model="multiSelected"
              @change="save"
            />
          </div>
        </div>
      </div>

      <!--Comments-->
      <div class="hidden w-1/4 pr-0 mb-4 xl:block lg:pl-4">
        <CommentPane
          v-if="!model.deactivateComments"
          :model="model.comments"
          :contentGuid="model.contentGuid"
          :pageId="pageId"
          :fieldTitle="model.title"
        />
      </div>
    </div>
    </div>
</template>

<script>
import CommentPane from '@/views/components/schema/CommentPane.vue';
import FieldTitle from '@/views/components/schema/common/FieldTitle.vue';

export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
    pageId: {
      type: String,
      required: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
    },
    preview: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    CommentPane,
    FieldTitle,
  },
  data() {
    return {
      type: this.model.properties.selectType,
      multiSelected: this.model.properties.multiSelected === null ? [] : this.model.properties.multiSelected,
      singleSelected: this.model.properties.singleSelected,
      selectable: this.model.properties.selectable,
      isSaving: false,
      isDropdownOpen: false,
    };
  },
  methods: {
    getSelectedById(id) {
      const itemFound = this.selectable.find((selectableItem) => selectableItem.value === id);
      if (itemFound) {
        return itemFound.text;
      }
      return id;
    },
    toggleItem(item) {
      const index = this.multiSelected.indexOf(item.value);
      if (index === -1) {
        // Legg til elementet hvis det ikke allerede er valgt
        this.multiSelected.push(item.value);
      } else {
        // Fjern elementet hvis det allerede er valgt
        this.multiSelected.splice(index, 1);
      }
      this.save();
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen) {
        document.addEventListener('click', this.handleClickOutside);
      } else {
        document.removeEventListener('click', this.handleClickOutside);
      }
    },
    removeItem(item) {
      // Fjern valgt element
      const index = this.multiSelected.indexOf(item);
      if (index !== -1) {
        this.multiSelected.splice(index, 1);
      }
      this.save();
    },
    handleClickOutside(event) {
      if (!this.$refs.dropdownMenu.contains(event.target)) {
        this.isDropdownOpen = false;
        document.removeEventListener('click', this.handleClickOutside);
      }
    },
    isMultiSelected(value) {
      const isSelected = this.multiSelected.filter((item) => {
        if (item === value) {
          return true;
        }
        return false;
      });
      return isSelected.length > 0;
    },
    save() {
      let selected;
      // Dropdown with single select option
      if (this.model.properties.singleSelect && !this.model.properties.multiSelect) {
        selected = this.singleSelected;
      } else {
        // Multi select, either with dropdown or checkboxes
        selected = this.multiSelected;
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: this.model.contentGuid,
          value: selected,
          type: this.type,
          pageId: this.pageId,
          relationRule: this.model.properties.relationRule,
        }),
      };
      const self = this;
      this.isSaving = true;
      fetch(`${window.location.origin}/api/schema/SaveSchemaItem`, requestOptions)
        .then(() => {
          this.$emit('onSave');
          this.$emit('getSchema');
        }).then(() => {
        self.isSaving = false;
        this.$toast.open({
          message: 'Lagret',
          type: 'success',
          dismissible: true,
        });
      });
    },
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>
