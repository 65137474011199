<template>
    <ul class="md:-mb-4">
      <li
        v-for="(link, index) in links"
        :key="$uuid(index)"
        class="mb-4"
      >
        <a
          :href="link.url"
          :title="link.title"
          :target="link.target"
          class="text-lg underline hover:no-underline hover:text-white"
        >
          {{link.text}}
        </a>
      </li>
    </ul>
</template>
<script>
export default {
  name: 'Footer Linklist',
  props: {
    links: {
      type: Array,
    },
  },
};
</script>
