<template>
  <li class="flex items-center px-2">
    <font-awesome-icon v-if="!first" icon="angle-right" class="mr-3 text-red-200"/>
    <span
      v-if="last"
      class="block w-full h-5 overflow-hidden text-charcoalgray-200 break-all overflow-ellipsis"
      v-html="truncatedText"
    >

    </span>
    <a
      v-else
      :href="item.url"
      :title="item.text"
      class="text-sm no-underline hover:underline"
    >
      {{ item.text }}
    </a>
  </li>
</template>
<script>
export default {
  name: 'Breadcrumb Item',
  props: {
    item: {
      type: Object,
    },
    last: {
      type: Boolean,
      default: false,
    },
    first: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    truncatedText() {
      if (this.item.text.length > 30) {
        const truncat = this.item.text.substring(0, 50);
        return `${truncat} &#8230;`;
      }
      return this.item.text;
    },
  },
};
</script>
