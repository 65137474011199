<template>
<div v-if="userName != null" class="flex mb-5 gap-x-2 text-gray-300 items-center">
  <div>Innlogget som: {{userName}}</div>
  <div class="justify-center text-lightblue-300 flex items-center">
    <button @click="logout"><span class="mr-2 underline">Logg ut</span><font-awesome-icon icon="sign-out-alt"/></button>
  </div>
</div>
</template>
<script>
export default {
  name: 'LoginPanel',
  props: {
    logoutUrl: {
      type: String,
    },
    userName: {
      type: String,
    },
  },
  methods: {
    logout() {
      const res = this.deleteCookies();
      if (res) {
        window.location.href = this.logoutUrl;
      }
    },
    async deleteCookies() {
      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(`${window.location.origin}/api/logout`, requestOptions);
        if (!response.ok) {
          console.log('Error when logging out', response);
          return false;
        }
        return true;
      } catch (error) {
        console.error('Error:', error);
      }
      return false;
    },
  },
};
</script>
