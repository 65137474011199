<template>
  <div>
    <SearchFilterGroup :filter="sourceFilters" :title="$t('filter.category.source', 0)" group="source"/>
    <SearchFilterGroup :filter="methodTypeFilters" :title="$t('filter.category.methodType', 0)" group="methods"/>
    <SearchFilterGroup :filter="topicFilters" :title="$t('filter.category.topic', 1)" group="topics"/>
    <SearchFilterGroup :filter="publisherFilters" :title="$t('filter.category.publisher', 0)" group="publisher"/>
    <SearchFilterGroup :filter="publishedFilters" :title="'Publiseringsår'" group="published"/>
    <SearchFilterGroup :filter="organizationFilters" :title="'Helseforetak'" group="organization"/>
  </div>
</template>

<script>
  import SearchFilterGroup from '@/views/components/search/SearchFilterGroup.vue';

  export default {
    name: 'Search Filter',
    components: {
      SearchFilterGroup,
    },
    props: {
      filters: {
        type: Object,
      },
    },
    data() {
      return {
        showTopics: false,
        showMethodTypes: false,
        showPublishers: false,
        topicIds: [],
      };
    },
    methods: {
      getFilterCount(filterId) {
        const filterCount = this.filters.relationFacet.find((i) => i.term === String(filterId))?.count;
        if (filterCount) return filterCount;
        return 0;
      },
      toggleTopics() {
        this.showTopics = !this.showTopics;
      },
      toggleMethodTypes() {
        this.showMethodTypes = !this.showMethodTypes;
      },
      togglePublishers() {
        this.showPublishers = !this.showPublishers;
      },
      filterResultList(filterList) {
        this.$emit('filter-click', filterList);
      },
    },
    computed: {
      topicFilters() {
        const topicFilters = this.filters.filters?.filter((f) => f.filterKey === 'Topic');
        return topicFilters;
      },
      methodTypeFilters() {
        const methodTypeFilters = this.filters.filters?.filter((f) => f.filterKey === 'MethodType');
        return methodTypeFilters;
      },
      publisherFilters() {
        const publisherFilters = this.filters.filters?.filter((f) => f.filterKey === 'Publisher');
        return publisherFilters;
      },
      publishedFilters() {
        const publishedFilter = this.filter.filter?.filteR((f) => f.filterKey === 'Published');
        return publishedFilter;
      },
      sourceFilters() {
        const sourceFilters = this.filters.filter?.filter((f) => f.filterKey === 'Source');
        return sourceFilters;
      },
    },
  };
</script>
