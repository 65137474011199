<template>
  <FocusTrap
:active="false"
:initial-focus="() => $refs.closeMenu"
:escapeDeactivates="true"
@deactivate="focusTrapOnDeactivate()"
ref="focusTrapHeaderMenu">
  <nav class="absolute top-0 left-0 z-50 w-full pb-16 bg-red-100 shadow-2xl mainmenu"
v-click-outside="close" aria-label="hovednavigasjon">
    <div class="container">
      <div class="flex justify-end mb-4 mt-14">
        <button class="flex items-center text-xl group" @click.prevent="close" ref="closemenu">
          <span class="group-hover:underline">{{$t('header.menu.button.text.close')}}</span>
          <span class="flex items-center justify-center w-10 h-10 ml-2 border border-gray-400 rounded-full group-hover:bg-white group-hover:bg-opacity-80">
            <font-awesome-icon class="text-lg align-middle" icon="times" />
          </span>
        </button>
      </div>
      <div class="block lg:hidden mt-9">
        <HeaderSearchForm :searchPage="model.header.searchPageUrl" :label="searchLabel" />
      </div>

      <div v-if="menu1 !== []">
        <h2 class="mb-4 font-serif font-semibold text-hsm text-text">
          {{$t('header.menu.headers.content')}}
        </h2>
        <ul class="flex flex-col md:flex-wrap -m-3 md:flex-row">
          <li
            v-for="(item, index) in menu1" :key="$uuid(index)"
            class="w-full p-3 md:w-auto"
          >
            <a
              :href="item.url"
              :title="item.text"
              class="block px-6 py-3 font-sans text-lg font-normal leading-none border border-gray-400 rounded-md bg-graylight-100 hover:bg-opacity-80 group"
            >
              <font-awesome-icon v-if="item.faIconClass" :icon="item.faIconClass" class="mr-2.5 h-4 w-auto" />
              <span class="no-underline group-hover:underline">
                {{ item.text }}
              </span>
            </a>
          </li>
        </ul>
      </div>

      <div v-if="menu2 !== []" class="mt-9">
        <h2 class="mb-4 font-serif font-semibold text-hsm text-text ">
          {{$t('header.menu.headers.otherPages')}}
        </h2>
        <ul class="flex flex-col md:flex-wrap -m-3 md:flex-row">
          <li
            v-for="(item, index) in menu2"
            :key="$uuid(index)"
            class="w-full p-3 lg:w-auto"
          >
            <a :href="item.url"
               :title="item.text"
               target="_blank"
               class="block px-6 py-3 font-sans text-lg font-normal leading-none border border-gray-400 rounded-md bg-graylight-100 hover:bg-opacity-80 hover:underline"
            >
              <font-awesome-icon v-if="item.faIconClass" :icon="item.faIconClass" class="mr-2.5 h-4 w-auto" />
              <span class="no-underline group-hover:underline">
                {{ item.text }}
               </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  </FocusTrap>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import { FocusTrap } from 'focus-trap-vue';
import HeaderSearchForm from './HeaderSearchForm.vue';

export default {
  name: 'Header Menu',
  components: {
    HeaderSearchForm,
    FocusTrap,
  },
  props: {
    menu1: {
      type: Array,
      default() {
        return [];
      },
    },
    menu2: {
      type: Array,
      default() {
        return [];
      },
    },
    model: {
      type: Object,
    },
    searchLabel: {
        type: String,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
        menuIsOpen: false,
    };
  },
  methods: {
    close() {
      this.menuIsOpen = false;
      this.$emit('selfClose');
    },
    focusTrapOnDeactivate() {
      if (this.menuIsOpen) {
        this.close();
      }
    },
  },
  mounted() {
    this.menuIsOpen = true;
    this.$refs.focusTrapHeaderMenu.activate();
  },
  beforeUnmount() {
    this.$refs.focusTrapHeaderMenu.deactivate();
  },
};
</script>

<style scoped>
  nav {
    transition: transform 3s ease;
    transform: translateY(0);
  }
</style>
