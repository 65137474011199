<template>
  <div class="StartPage">
    <div class="container-narrow">
      <Hero :model="model" />
      <div class="flex flex-col mb-10 md:mb-20 md:flex-row md:space-x-9">
        <div class="md:w-1/2 w-full md:pr-5">
          <LinkBlock :model="model.leftbox" />
        </div>
        <div class="md:w-1/2 w-full md:pl-5">
          <LinkBlock :model="model.rightbox" />
        </div>
      </div>
    </div>
    <div class="container-narrow">
      <ContentArea v-epi-edit="'MainContent'" :model="model.mainContent" />
      <FAQ v-if="!model.hideFaq" v-epi-edit="'FAQ'" :model="model.faq" />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ContentArea from '@/components/EpiContentArea.vue';
  import LinkBlock from '@/views/blocks/LinkBlock.vue';
  import FAQ from '@/views/components/faq/FAQ.vue';
  import Hero from '@/views/components/hero/Hero.vue';

  export default {
    components: {
      ContentArea,
      LinkBlock,
      FAQ,
      Hero,
    },
    data() {
      return {
        topics: [],
      };
    },
    props: {
      model: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapState({
        showModal: (state) => state.appContext.modalShowing,
      }),
    },
  };
</script>
