<template>
  <div class="mb-6">
    <FieldTitle :model="model" />
    <div v-if="readOnly">
      <div class="pr-0" :class="preview ? 'w-full' : 'w-3/4'">
        <div v-for="(item, index) in selectable"
             :key="$uuid(index)"
             class="relative mb-2">
          <CustomCheckbox :cbxValue="item.value"
                          :cbxId="ids[index]"
                          :cbxName="ids[index]"
                          :cbxLabel="item.text"
                          :cbxDisabled="true"
                          v-model="selected" />
        </div>
      </div>
    </div>
    <div v-else class="flex">
      <div class="w-full pr-0 xl:w-3/4">
        <div class="relative">
          <font-awesome-icon v-show="isSaving" icon="spinner" class="animate-spin absolute right-2.5 top-3" />
        </div>
        <div
          v-for="(item, index) in selectable"
          :key="$uuid(index)"
          class="relative mb-2"
        >
          <CustomCheckbox
            :cbxId="ids[index]"
            :cbxName="ids[index]"
            :cbxValue="item.value"
            :cbxLabel="item.text"
            v-model="selected"
            @change="save"
          />
        </div>
      </div>
      <div class="hidden w-1/4 pr-0 mb-4 xl:block">
        <CommentPane
          v-if="!model.deactivateComments"
          :model="model.comments"
          :contentGuid="model.contentGuid"
          :pageId="pageId"
          :fieldTitle="model.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import CommentPane from '@/views/components/schema/CommentPane.vue';
  import FieldTitle from '@/views/components/schema/common/FieldTitle.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      CommentPane,
      FieldTitle,
    },
    data() {
      return {
        type: this.model.properties.selectType,
        selected: this.model.properties.selected === null ? [] : this.model.properties.selected,
        selectable: this.model.properties.selectable,
        isSaving: false,
        ids: [],
      };
    },
    methods: {
      generateIds() {
        if (this.selectable !== null) {
          this.selectable.forEach(() => {
            this.ids.push(this.$uuid());
          });
        }
      },
      save() {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.model.contentGuid,
            value: this.selected,
            type: this.type,
            pageId: this.pageId,
          }),
        };
        const self = this;
        this.isSaving = true;
        fetch(`${window.location.origin}/api/schema/SaveSchemaItem`, requestOptions)
          .then(() => {
            this.$emit('onSave');
            this.$emit('getSchema');
          }).then(() => {
            self.isSaving = false;
            this.$toast.open({
              message: 'Lagret',
              type: 'success',
              dismissible: true,
            });
          });
      },
    },
    mounted() {
      this.generateIds();
    },
  };
</script>
