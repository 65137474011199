<template>
  <button v-if="model" @click.prevent="openPopUp" class="ml-2 cursor-pointer">
    <font-awesome-icon class="text-xl text-bluedark-100 hover:text-red-300" icon="question-circle" :title="model" />
  </button>
  <HelpPopUp @close="closePopUp" :text="model" v-if="showPopUp"/>
</template>

<script>
  import HelpPopUp from './HelpPopUp.vue';

  export default {
    name: 'Schema Help',
    props: {
      model: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        showPopUp: false,
      };
    },
    components: {
      HelpPopUp,
    },
    methods: {
      openPopUp() {
        this.showPopUp = true;
      },
      closePopUp() {
        this.showPopUp = false;
      },
    },
  };
</script>
