<template>
  <StandardPage :model="model" />
</template>

<script>
import StandardPage from '@/views/pages/Common/StandardPage.vue';

export default {
  components: {
    StandardPage,
    },
    methods: {
      setMetaTags() {
        document.title = this.model.name;
        document.querySelector('meta[property="og:title"]').setAttribute('content', this.model.name);
      },
    },
    mounted() {
      this.setMetaTags();
    },
  props: ['model'],
};
</script>
