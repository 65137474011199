<template>
    <h1 class=" text-hmd font-serif">{{headingText}}</h1>
</template>

<script>

export default {
    props: {
        headingText: {
            type: String,
        },
    },
};
</script>
