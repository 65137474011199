<template>
  <div class="container-narrow">
    <Loading v-show="!assessmentSchema" />
    <div class="flex flex-row flex-wrap">
      <div class="w-full pb-6 md:w-full">
        <h1 class="text-hlg font-serif font-semibold mb-2">{{assessment.heading}}</h1>
        <div v-if="assessment.lastSaved">
          <p class="pb-1 font-sans text-sm text-gray-300">{{$t('schema.common.save.lastSaved')}} {{lastSavedString}}</p>
        </div>
      </div>
      <div class="w-full pb-6 md:w-1/2" v-if="assessment.statusText">
        <p class="text-gray-300">{{$t('schema.schemaPage.status')}}</p>
        <p>{{assessment.statusText}}</p>
      </div>
      <!--Toolbar-->
      <Toolbar v-if="assessment"
               :model="assessment"
               :showPrint="true"
               :hideContact="assessment.hideContact"
               class="pb-4 mb-4" />
      <!--Detail List-->
      <div class="w-full">
        <DetailList :topics="assessment.relatedTopics"
                    :organizations="assessment.relatedOrganizations"
                    :methodTypes="assessment.relatedMethodTypes"
                    :published="assessment.year" />
      </div>
    </div>
    <div v-if="assessmentSchema">
      <div v-for="schemaItem in assessmentSchema.schemaItems" :key="schemaItem.contentGuid">
        <template v-if="schemaItem.blockType.toString().toLowerCase() === 'section' || schemaItem.blockType.toString().toLowerCase() === '0'">
          <Section :model="schemaItem"
                   :readOnly="readOnly"
                   :preview="preview" />
        </template>
        <template v-if="schemaItem.blockType.toString().toLowerCase() === 'peerreviewsection' || schemaItem.blockType.toString().toLowerCase() === '1'">
          <PeerReviewSection :model="schemaItem"
                             :readOnly="readOnly"
                             :title="assessmentSchema.name"
                             :organization="assessmentSchema.organization"
                             :preview="preview"
                             :isPeerReviewed="isPeerReviewed"
                             :isPeerReview="false"
                             :isPublished="true" />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
  import Section from '@/views/components/schema/Section.vue';
  import Loading from '@/views/components/Loading.vue';
  import PeerReviewSection from '@/views/components/schema/peerReview/PeerReviewSection.vue';
  import Toolbar from '@/views/components/toolbar/Toolbar.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        assessment: '',
        assessmentSchema: null,
        readOnly: true,
        preview: true,
        isPeerReviewed: false,
      };
    },
    components: {
      Section,
      Loading,
      PeerReviewSection,
      Toolbar,
    },
    mounted() {
      this.getAssessment();
    },
    methods: {
      getAssessment() {
        const assessmentId = this.$route.query.id;
        fetch(`${window.location.origin}/api/methodassessment/GetAssessment?id=${assessmentId}`).then((response) => {
          response.json().then((data) => {
            console.log(data);
            this.assessment = data;
            this.assessmentSchema = data.schema;
            this.isPeerReviewed = data.isPeerReviewed;
          }).catch((error) => {
            console.log(error);
          });
        });
      },
    },
    computed: {
      lastSavedString() {
        const msSince = new Date() - new Date(this.assessment.lastSaved);
        const seconds = ((msSince % 60000) / 1000).toFixed(0);
        const minutes = Math.floor(msSince / 60000);

        if (minutes < 60) {
          if (minutes < 1) {
            return `${seconds} sekunder siden`;
          }
          return `${minutes} minutter siden`;
        }
        const last = new Date(this.assessment.lastSaved).toLocaleString();
        console.log(last);
        return last;
      },
    },
  };
</script>
