<template>
  <div class="text-sm font-normal text-gray-300">
    <span v-if="model.published || model.created">
      {{model.published ? 'Publisert' : 'Opprettet'}} {{model.published ? dateFormatter(new Date(model.published)) : dateFormatter(new Date(model.created))}}
    </span>
    <span v-if="displayDivider"> | </span>
    <span v-if="model.modified || model.changed">Sist oppdatert {{model.modified ? dateFormatter(new Date(model.modified)) : dateFormatter(new Date(model.changed))}} </span>
  </div>
</template>

<script>
  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
    },
    computed: {
      displayDivider() {
        return ((this.model.published && this.model.modified) || (this.model.created && this.model.changed));
      },
    },
    methods: {
      dateFormatter(date) {
        const dateString = `${(`0${date.getDate()}`).slice(-2)}.${(`0${1 + date.getMonth()}`).slice(-2)}.${(`${date.getFullYear()}`)}`;
        return dateString;
      },
    },
  };
</script>
