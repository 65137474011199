<template>
  <div v-if="userRole" class="text-sm text bg-green-100 rounded py-1 px-2 opacity-80">
    <font-awesome-icon icon="user" class="mr-1" />
    <span v-if="userRole === 'initiator'">
      <span v-if="assessmentType === '2' || assessmentType === '3' ">
        {{ $t('schema.schemaPage.roles.initiator')}}
      </span>
      <span v-else>
        {{ $t('schema.schemaPage.roles.initiator')}}
      </span>
    </span>
    <span v-if="userRole === 'peer'">
      {{ $t('schema.schemaPage.roles.peer')}}
    </span>
    <span v-if="userRole === 'librarian'">
      {{ $t('schema.schemaPage.roles.librarian')}}
    </span>
    <span v-if="userRole === 'economist'">
      {{ $t('schema.schemaPage.roles.economist')}}
    </span>
    <span v-if="userRole === 'other'">
      {{ $t('schema.schemaPage.roles.other')}}
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      userRole: {
        type: String,
        required: false,
      },
      assessmentType: {
        type: String,
        required: false,
      },
    },
  };
</script>
