<template>
  <div class="flex flex-col w-full pt-4 pb-4 mb-4 w-full">
    <h2
      v-if="model.heading || this.$route.query.epieditmode"
      v-epi-edit="prefixPropertyName('Heading')"
      class="mb-3 font-serif font-semibold text-hsm break-all md:break-normal">
      {{model.heading}}
    </h2>
    <p
      v-if="model.text || this.$route.query.epieditmode"
      v-epi-edit="prefixPropertyName('Text')"
      class="mb-6 text-lg"
    >
      {{model.text}}
    </p>
    <EpiLink
      v-if="model.link || this.$route.query.epieditmode"
      :class="buttonColor"
      :url="model.link"
      :title="model.linkTitle"
      class="btn btn--small lg:btn--large mt-auto text-left"
    >
      <font-awesome-icon class="mr-2 text-red-100" icon="arrow-right" />
      <span>{{model.linkTitle}}</span>
    </EpiLink>
  </div>
</template>

<script>
  import EpiLink from '@/components/EpiLink.vue';

  export default {
    name: 'Link Block',
    components: {
      EpiLink,
    },
    props: {
      model: {
        type: Object,
      },
      pagePropertyName: {
        type: String,
      },
    },
    methods: {
      prefixPropertyName(propertyName) {
        // Prefix propertyName with the block name (pagePropertyName), if it's being edited on a page.
        if (this.pagePropertyName) {
          return `${this.pagePropertyName}.${propertyName}`;
        }
        return propertyName;
      },
    },
    computed: {
      buttonColor() {
        return 'btn--secondary__outline border-charcoalgray';
      },
    },
  };
</script>
