<template>
  <div>
    <FieldTitle :model="model" />
    <div v-if="readOnly" class="font-sm py-4">
      <div class="bg-orange-100 rounded py-2 px-4 opacity-80 w-3/4">
        {{$t('search.results.notAvailable')}}
      </div>
    </div>
    <div v-else class="flex">
      <div class="w-full pr-0 xl:w-3/4">
        <form @submit.prevent="search">
          <fieldset class="relative">
            <input id="searchid"
                  type="search"
                  :placeholder="$t('search.searchBar.placeholder.inputSearchValue')"
                  v-model="query"
                  class="w-full"
                  v-on:keyup="search"/>
            <button type="submit"
                    class="mt-1 mr-1 btn--text btn--inlinebutton">
              <span class="sr-only">{{$t("search.searchBar.text")}}</span>
              <font-awesome-icon icon="search" />
            </button>
          </fieldset>
        </form>
        <div v-if="hits.length > 0">
          <!--Paging and sort-->
          <div class="flex mb-6 text-base border-b border-gray-100 pb-7">
            <div class="w-full">
              {{firstItemNumber}}-{{lastItemNumber}} av {{data.totalMatching}}
              <button @click="prevPage()" class="p-2 text-gray-200" :title="$t('search.navigation.moveOnePageBack')" :disabled="prevIsDisabled">
                <font-awesome-icon icon="angle-left" />
              </button>
              <button @click="nextPage()" class="p-2 hover:text-red-200" :title="$t('search.navigation.moveOnePageForward')" :disabled="nextIsDisabled">
                <font-awesome-icon icon="angle-right" />
              </button>
            </div>
            <div class="w-full text-right">
              <button @click="sortLatest" class="m-2 font-medium border-b border-red-200 text-text hover:text-red-200">
                {{$t("search.sort.newestFirst")}}
              </button>
              <span class="inline-block">|</span>
              <button @click="sortModified" class="m-2 font-normal text-text hover:text-red-200">
                {{$t("search.sort.lastUpdated")}}
              </button>
            </div>
          </div>
          <!-- Hits -->
          <label class="my-3 text-base">{{$t("search.results.searchResult")}}:</label>
          <ul v-if="hits">
            <li v-for="(hit, index) in hits" :key="$uuid(index)">
              <SearchResultItem :item="hit" :type="this.model.properties.searchType" target="_blank" />
            </li>
          </ul>
          <div v-else>
            {{$t("search.results.noHits")}}
          </div>
        </div>
        <div v-if="searchFinished && hits.length === 0">
          <label class="my-2 text-base font-semibold text-red-200">{{$t("search.results.noHits")}}!</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FieldTitle from '@/views/components/schema/common/FieldTitle.vue';
  import SearchResultItem from '@/views/components/search/SearchResultItem.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      FieldTitle,
      SearchResultItem,
    },
    data() {
      return {
        hits: [],
        query: '',
        data: {},
        searchFinished: false,
        pageToGet: 0,
        sort: 'published',
        filterIds: [''],
      };
    },
    computed: {
      firstItemNumber() {
        return ((this.data.pageSize * this.data.page) - this.data.pageSize) + 1;
      },
      lastItemNumber() {
        let lastNumber = (this.firstItemNumber - 1) + this.data.pageSize;
        if (lastNumber > this.data.totalMatching) {
          lastNumber = this.data.totalMatching;
        }
        return lastNumber;
      },
      prevIsDisabled() {
        return this.data.page < 2;
      },
      nextIsDisabled() {
        return this.data.page >= (this.data.totalMatching / this.data.pageSize);
      },
    },
    methods: {
      async search() {
        console.log('search');
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            q: this.query,
            page: this.pageToGet,
            pageSize: 3,
            sort: this.sort,
          }),
        };

        if (this.query === '') {
          this.hits = [];
          this.data = {};
          this.searchFinished = false;
        } else {
          const searchApiUrl = this.model.properties.searchType === 'methodassessment' ? '/api/search/methodassessment/' : '/api/search/methodnotifications/';
          const response = await fetch(`${window.location.origin + searchApiUrl}`, requestOptions);
          const data = await response.json();
          this.data = data;
          this.hits = data.searchResult !== null ? data.searchResult : [];
          this.searchFinished = true;
        }
      },
      nextPage() {
        this.pageToGet = this.data.page + 1;
        this.search();
      },
      prevPage() {
        this.pageToGet = this.data.page - 1;
        this.search();
      },
      sortLatest() {
        this.sort = 'published';
        this.search();
      },
      sortModified() {
        this.sort = 'modified';
        this.search();
      },
    },
    emits: ['onSave'],
  };
</script>
