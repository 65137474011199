<template>
  <div>
    <div class="w-full mt-12 mb-20">

      <!-- Feeedback -->
      <div v-if="isSent" v-html="feedback" />

      <!-- Tips Form -->
      <div v-if="!isSent" class="w-full ">
        <!--Intro text-->
        <p class="w-full mb-6 text-xl font-normal">
          {{this.model.introductionText}}
        </p>
        <div class="md:w-3/5">
          <form v-on:submit.prevent="submit">
            <div class="mb-6">
              <!-- Method description -->
              <p class="text-base mb-6">{{$t('blocks.tips.aboutMethod.text')}}</p>
              <label for="description" class="text-sm">{{$t('blocks.tips.aboutMethod.label')}}:</label>
              <ResizableTextArea :name="description"
                                 :readOnly="false"
                                 v-model="description"
                                 required />
              <!-- Link -->
              <label for="link" class="text-sm">{{$t('blocks.tips.linkToMethod.label')}}:</label>
              <input id="link" type="text" v-model="link" />
            </div>
            <div class="mb-6">
              <p class="text-base mb-6">{{$t('blocks.tips.aboutYou.text')}}</p>
              <!-- Name -->
              <label for="name" class="text-sm">{{$t('blocks.tips.aboutYou.label.name')}}:</label>
              <input id="name" type="text" v-model="name" required />
              <!-- Email -->
              <label for="email" class="text-sm">{{$t('blocks.tips.aboutYou.label.email')}}:</label>
              <input id="email" type="email" v-model="email" required />
              <!-- Employer -->
              <label for="employer" class="text-sm">{{$t('blocks.tips.aboutYou.label.employer')}}:</label>
              <input id="employer" type="text" v-model="employer" />
            </div>
            <div>
              <p class="mb-6 text-xs text-gray-300">
                {{$t('blocks.tips.googleterms')}}
                <a href="https://policies.google.com/privacy" class="underline hover:no-underline">{{$t('blocks.tips.privacypolicy')}}</a>
                and <a href="https://policies.google.com/terms" class="underline hover:no-underline">{{$t('blocks.tips.termsofservice')}}</a> {{$t('blocks.tips.apply')}}.
              </p>
            </div>
            <div class="flex justify-end">
              <button type="submit"
                      class="w-full btn md:w-44"
                      :class="!recaptchaToken ? 'btn--disabled' : 'btn--charcoalgray'"
                      :disabled="!recaptchaToken">
                <font-awesome-icon icon="arrow-right" />
                {{$t('blocks.tips.submit.text')}}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { useReCaptcha } from 'vue-recaptcha-v3';
  import ResizableTextArea from '@/views/components/textarea/ResizableTextArea.vue';

  export default {
    props: {
      model: {
        type: Object,
      },
    },
    data() {
      return {
        name: '',
        description: '',
        email: '',
        link: '',
        employer: '',
        isSuccess: false,
        feedback: '',
        isSent: false,
        recaptchaToken: null,
      };
    },
    components: {
      ResizableTextArea,
    },
    methods: {
      resize(e) {
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight + 4}px`;
      },
      async getToken() {
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

        // (optional) Wait until recaptcha has been loaded.
        await recaptchaLoaded();

        // Execute reCAPTCHA with action 'login'.
        const token = await executeRecaptcha('login');
        this.recaptchaToken = token;

        // Renew token after 115 seconds
        setInterval(() => {
          executeRecaptcha('login').then((value) => {
            this.recaptchaToken = value;
          });
        }, 150000);
      },
      async submit() {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: this.name,
            methodDescription: this.description,
            senderEmail: this.email,
            link: this.link,
            employer: this.employer,
            recipient: this.model.recipient,
            copyToSender: this.model.copyToSender,
            recaptchaToken: this.recaptchaToken,
          }),
        };

        const response = await fetch(`${window.location.origin}/api/tips/sendTips`, requestOptions);
        const res = await response.json();
        this.res = res;

        if (res) {
          this.isSuccess = true;
          this.isSent = true;
          this.feedback = this.model.confirmationText ? this.model.confirmationText : this.$t('blocks.tips.confirmation');
        } else {
          this.isSuccess = false;
          this.feedback = this.model.errorText ? this.model.errorText : this.$t('blocks.tips.error');
          this.isSent = true;
        }
      },
    },
    mounted() {
      this.getToken();
    },
  };
</script>
