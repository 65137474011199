<template>
  <StandardPage :model="model" />
</template>

<script>
import StandardPage from '@/views/pages/Common/StandardPage.vue';

export default {
  components: {
    StandardPage,
  },
  props: ['model'],
};
</script>
