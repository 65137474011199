<template>
  <div class="py-6 border-b border-gray-100">
    <div class="flex justify-between">
      <p v-if="type === 'methodnotification'" class="mb-3 text-sm leading-none text-gray-300">
        {{el.sourceItemName}}
      </p>
      <p v-else class="mb-3 text-sm leading-none text-gray-300">
        {{el.unitTitle}}
      </p>
      <a v-if="isEditor" :href="editUrl(el.pageId)" :title="$t('search.results.editResult')" target="_blank" class="text-sm text-red-200">
        <font-awesome-icon icon="edit" />
        {{$t("search.results.editResult")}}
      </a>
    </div>
    <h2 class="mb-3 text-lg leading-7 text-dark">
      <EpiLink :url="el.url"
               :title="`${$tc('navigationUtils.navigateTo', { address: el.sortName })}`"
               :target="target"
               class="no-underline hover:text-red-200 hover:underline">
        {{ el.title }}
      </EpiLink>
    </h2>
    <!--Organizations-->
    <p v-if="this.type === 'methodassessment' && el.organizations && el.organizations != ''"
       class="text-sm leading-none text-gray-300 mb-3">
      <template v-if="el.organizations">
        {{$t("article.organizations")}}:
        {{el.organizations.join(', ')}}
      </template>
    </p>
    <!--Municipalities-->
    <p v-if="this.type === 'methodassessment' && el.municipalities && el.municipalities != ''"
       class="text-sm leading-none text-gray-300 mb-3">
      <template v-if="el.municipalities">
        {{$t("article.municipalities")}}:
        {{el.municipalities.join(', ')}}
      </template>
    </p>
    <!--Published-->
    <p v-if="el.published || el.modified"
       class="text-sm leading-none text-gray-300">
      <template v-if="el.published">
        {{$t("article.published")}}: {{ dateFormatter(new Date(el.published)) }}
      </template>

      <template v-if="el.published && el.modified">
        &nbsp;&ndash;&nbsp;
      </template>

      <template v-if="el.modified">
        {{$t("article.modified")}}: {{ dateFormatter(new Date(el.modified)) }}
      </template>
    </p>
    <!--Tags-->
    <div v-if="ifTags" class="flex flex-wrap mt-6">
      <!--AssessmentType-->
      <template v-if="el.assessmentType">
        <h3 class="sr-only">
          {{$t("filter.assessmentType")}}
        </h3>
        <ul class="flex flex-wrap">
          <li class="my-1 mr-1">
            <button @click="resetFilter(el.assessmentType.link)"
                    class="px-3 py-1 text-xs text-text bg-green-100 rounded-3xl hover:bg-graylight-300">
              {{ el.assessmentType.text }}
            </button>
          </li>
        </ul>
      </template>
      <!--Topics-->
      <template v-if="el.topics">
        <h3 class="sr-only">
          {{$t("filter.category.topic", 1)}}
        </h3>
        <ul class="flex flex-wrap">
          <li v-for="(topic, index) in el.topics"
              :key="$uuid(index)"
              class="my-1 mr-1">
            <button @click="resetFilter(topic.link)"
                    class="px-3 py-1 text-xs text-text bg-bluegray-200 rounded-3xl hover:bg-graylight-300">
              {{ topic.text }}
            </button>
          </li>
        </ul>
      </template>
      <!--Method types-->
      <template v-if="el.methodTypes">
        <h3 class="sr-only">
          {{$t("filter.category.methodType", 1)}}
        </h3>
        <ul class="flex flex-wrap">
          <li v-for="(methodType, index) in el.methodTypes"
              :key="$uuid(index)"
              class="my-1 mr-1">
            <button @click="resetFilter(methodType.link)"
                    class="px-3 py-1 text-xs text-text bg-bluegray-200 rounded-3xl hover:bg-graylight-300">
              {{ methodType.text }}
            </button>
          </li>
        </ul>
      </template>
      <!--Subjects-->
      <template v-if="el.subjects">
        <h3 class="sr-only">
          {{$t("filter.category.subject", 1)}}
        </h3>
        <ul class="flex flex-wrap">
          <li v-for="(subject, index) in el.subjects"
              :key="$uuid(index)"
              class="my-1 mr-1">
            <button @click="resetFilter(subject.link)"
                    class="px-3 py-1 text-xs text-text bg-bluegray-200 rounded-3xl hover:bg-graylight-300">
              {{ subject.text }}
            </button>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
  import EpiLink from '@/components/EpiLink.vue';

  export default {
    name: 'Search Result - Item',
    components: {
      EpiLink,
    },
    props: {
      item: {
        type: Object,
      },
      type: {
        type: String,
      },
      target: {
        type: String,
      },
      isEditor: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        el: this.item,
      };
    },
    methods: {
      resetFilter(url) {
        window.location.href = url;
      },
      editUrl(id) {
        // eslint-disable-next-line no-use-before-define
        return `${window.location.origin}/EPiServer/CMS/#context=epi.cms.contentdata:///${id}`;
      },
      ifTags() {
        return this.item.topics.length > 0 || this.item.methodTypes.length > 0 || this.item.organizations || this.el.assessmentType;
      },
      dateFormatter(date) {
        const dateString = `${(`0${date.getDate()}`).slice(-2)}.${(`0${1 + date.getMonth()}`).slice(-2)}.${(`${date.getFullYear()}`)}`;
        return dateString;
      },
    },
  };
</script>
