<template>
    <div class="mb-6 mt-1 flex justify-left space-y-1 sm:space-x-2 lg:space-x-0 2xl:space-y-0 2xl:space-x-2">
            <input type="checkbox" name="collaboration_checkbox" id="collaboration" :value=this.filterByCollaboration
            class="custom--inputbox__checkbox flex-grow-0 flex-shrink-0 mt-0.5 mb-0.5"
            @change="setFilterByCollaboration" />
            <label for="collaboration">{{$t('methodEvaluationSearch.filterByCollaboration')}}</label>
          </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    name: 'Search Collaboration Filter',
    emits: ['typeFilterChanged'],
    props: {
        filterByCollaboration: Boolean,
    },
    computed: {
        isFilteredByCollaboration() {
            return this.filterByCollaboration;
        },
    },
    methods: {
      ...mapMutations(['setPageNum']),
      setFilterByCollaboration() {
        this.toggleFilters(!this.filterByCollaboration);
        },
      toggleFilters(collaboration) {
            this.setPageNum(1);
            this.$emit('update:filterByCollaboration', collaboration);
            this.$emit('typeFilterChanged');
        },
    },
};
</script>
