<template>
  <table class="border border-collapse w-full">
    <tbody>
      <tr v-for="(row, index) in table.rows" :key="$uuid(index)" :class="row.isHeader ? 'tableheader' : ''">
        <td v-for="(cell, index) in row.cells"
            :key="$uuid(index)"
            :colspan="cell.colspan"
            class="border px-3 py-2 align-top"
            :class="[index === 0  ? table.numberOfColumns === 2 ? 'w-2/6' : 'w-2/10'  : '']">
          <div>
            <span class="text-base" :class="cell.isHeader ? 'font-semibold' : ''">{{cell.headerText}}</span>
            <div v-if="cell.headerDescription" class="pt-2" v-html="cell.headerDescription" />
          </div>
          <div v-if="cell.cellItem" class="pr-1">
            <TableInput :model="cell.cellItem.properties" :contentGuid="cell.cellItem.contentGuid" :readOnly="readOnly" :pageId="pageId" />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import TableInput from '@/views/components/schema/table/TableInput.vue';

  export default {
    props: {
      table: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      TableInput,
    },
  };
</script>
