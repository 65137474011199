<template>
  <div class="mb-6">
    <div class="relative">
      <font-awesome-icon v-show="isSaving" icon="spinner" class="animate-spin absolute right-2.5 top-3" />
    </div>
    <div v-if="readOnly" class="flex">
      <div class="pr-0" :class="preview ? 'w-full' : 'w-3/4'">
        <FieldTitle :model="model" />
        <div v-for="(option, index) in selectable"
             :key="$uuid(index)"
             class="relative mb-2">
          <div class="flex flex-nowrap">
            <input :id="ids[index]"
                   :name="model.contentGuid"
                   :value="option.value"
                   :checked="isChecked(option.value)"
                   v-model="selected"
                   type="radio"
                   :class="`custom--inputbox__radio flex-grow-0 flex-shrink-0 mt-0.5 mb-0.5`"
                   :disabled="true" />
            <label class="flex justify-between text-sm text-left mb-0 self-center " :for="ids[index]">
              <span class="flex-grow">{{option.text}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex">
      <div class="w-full pr-0 xl:w-3/4">
        <FieldTitle :model="model" />
        <div v-for="(option, index) in selectable"
             :key="$uuid(index)"
             class="relative mb-2">
          <div class="flex flex-nowrap">
            <input :id="ids[index]"
                   :name="model.contentGuid"
                   :value="option.value"
                   :checked="isChecked(option.value)"
                   v-model="selected"
                   type="radio"
                   :class="`custom--inputbox__radio flex-grow-0 flex-shrink-0 mt-0.5 mb-0.5`"
                   @change="save" />
            <label class="flex justify-between text-sm text-left mb-0 self-center " :for="ids[index]">
              <span class="flex-grow">{{option.text}}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="hidden w-1/4 pr-0 mb-4 xl:block lg:pl-4">
        <CommentPane
          v-if="!model.deactivateComments"
          :model="model.comments"
          :contentGuid="model.contentGuid"
          :pageId="pageId"
          :fieldTitle="model.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import CommentPane from '@/views/components/schema/CommentPane.vue';
  import FieldTitle from '@/views/components/schema/common/FieldTitle.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      CommentPane,
      FieldTitle,
    },
    data() {
      let selectedButton;
      if (typeof this.model.properties.selected === 'undefined' || this.model.properties.selected === null) {
        selectedButton = '';
      } else {
        selectedButton = this.model.properties.selected;
      }
      return {
        type: this.model.properties.selectType !== null ? this.model.properties.selectType : 'radiobuttons',
        selected: selectedButton[0],
        selectable: this.model.properties.selectable !== null ? this.model.properties.selectable : [],
        isSaving: false,
        ids: [],
      };
    },
    methods: {
      generateIds() {
        if (this.selectable !== undefined) {
          this.selectable.forEach(() => {
            this.ids.push(this.$uuid());
          });
        }
      },
      save() {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.model.contentGuid, value: [this.selected], type: this.type, pageId: this.pageId,
          }),
        };
        const self = this;
        this.isSaving = true;
        fetch(`${window.location.origin}/api/schema/SaveSchemaItem`, requestOptions)
          .then(() => {
            this.$emit('onSave');
          }).then(() => {
            self.isSaving = false;
            this.$toast.open({
              message: 'Lagret',
              type: 'success',
              dismissible: true,
            });
          });
      },
      isChecked(value) {
        return value === this.selected;
      },
    },
    emits: ['onSave'],
    mounted() {
      this.generateIds();
    },
  };
</script>
