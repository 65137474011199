<template>
  <form :action="searchPage">
    <fieldset class="relative">
      <label for="searchid" class="sr-only">
        {{label}}
      </label>
      <input
        id="searchid"
        type="search"
        name="q"
        :placeholder="label"
        class="w-full pr-6 mb-0 border-gray-200"
      />
      <button type="submit" class="mt-1 mr-1 btn--text btn--inlinebutton" :title="$t('search.searchBar.description')">
        <span class="sr-only">{{$t('search.searchBar.text')}}</span>
        <font-awesome-icon icon="search" />
      </button>
    </fieldset>
  </form>
</template>

<script>
export default {
  name: 'HeaderSearch',
  props: {
    searchPage: {
      type: String,
      default: '/sok',
    },
    label: {
      type: String,
    },
  },
};
</script>

<style scoped>
/* Override margin from stylesheets */
.breadcrumb input[type='search'] {
  @apply mb-0 border-gray-100 rounded-none;

  margin-bottom: -1px;
  margin-top: -1px;
}

.breadcrumb input[type='search']:focus {
  @apply border-gray-200 rounded-none;
}

.breadcrumb form {
  @apply mr-6 w-72;
}
</style>
