<template>
  <div
    v-if="model"
    class="flex items-center h-full"
  >
    <button @click.prevent="openPopUp" class="ml-2 cursor-pointer">
      <font-awesome-icon class="text-xl text-bluedark-100 hover:text-red-300" icon="question-circle" :title="model" />
    </button>
    <ImagePopUp @close="closePopUp" :text="model" v-if="showPopUp"/>
  </div>
</template>

<script>
  import ImagePopUp from './ImagePopUp.vue';

  export default {
    name: 'Schema Help',
    props: {
      model: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        showPopUp: false,
      };
    },
    components: {
      ImagePopUp,
    },
    methods: {
      openPopUp() {
        this.showPopUp = true;
      },
      closePopUp() {
        this.showPopUp = false;
      },
    },
  };
</script>
