<template>
<FocusTrap
:active="false"
:initial-focus="() => $refs.closePopup"
:escapeDeactivates="true"
@deactivate="onDeactivateFocusTrap()"
ref="focusTrapPopupTopic">
<div v-if="!showNext" class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30 bg-gray-400 bg-opacity-50">
<div class=" mx-auto ">
  <div v-click-outside="hidePopup" class=" inset-0 border-2 rounded-md my-4 p-9 max-h-screen overflow-y-scroll md:overflow-hidden bg-white">
    <div class="relative">
        <button class="inline-block absolute top-0 right-0" :title="$t('window.close.description.popup')" ref="closePopup" @click="hidePopup">
          <font-awesome-icon icon="times" />
        </button>
    </div>
    <h1 class=" font-bold md:text-xl text-sm flex-row pb-9">{{$t('methodNotificationPopup.selectTopics.header')}}</h1>
    <div class="container grid md:grid-cols-2 2xl:grid-cols-3 mx-auto gap-y-4 gap-x-2 grid-cols-1  w-full text-sm">
        <div v-for="(item, index) in topics" :key="$uuid(index)">
            <CustomCheckbox
            :cbxName="'topicArea'"
            :cbxId="`topicArea${index}`"
            :cbxClass="'custom--inputbox__checkbox'"
            :cbxLabel="`${item.subjectName}(${item.hits})`"
            />
        </div>
    </div>
    <div class="flex justify-end">
    <button class="btn btn--secondary" @click="showNextPopup" >{{$t('methodNotificationPopup.selectTopics.continue')}}</button>
    </div>
  </div>
</div>
</div>
<div v-else>
  <popup @close="hidePopup"/>
</div>
</FocusTrap>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import { FocusTrap } from 'focus-trap-vue';
import { HIDE_MODAL } from '@/store/modules/appContext';
import popup from './popup.vue';

export default {
  components: {
    popup,
    FocusTrap,
  },
  props: {
    topics: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showNext: false,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    hidePopup() {
      this.$refs.focusTrapPopupTopic.deactivate();
      this.$emit('close');
      this.$store.commit(HIDE_MODAL);
    },
    showScroll: () => document.body.classList.remove('modal-open'),
    hideScroll: () => document.body.classList.add('modal-open'),
    showNextPopup() {
      this.showNext = true;
    },
  onDeactivateFocusTrap() {
    if (!this.showNext) {
      this.hidePopup();
    }
  },
  },
  mounted() {
    this.$refs.focusTrapPopupTopic.activate();
    return this.hideScroll();
  },
  unmounted() {
    return this.showScroll();
  },
};
</script>
