<template>
  <FAQ :model="model.faq" />
</template>

<script>
  import FAQ from '@/views/components/faq/FAQ.vue';

  export default {
    props: {
      model: {
        type: Object,
      },
    },
    components: {
      FAQ,
    },
  };
</script>
