<template>
  <div class="flex flex-row flex-wrap">
    <div v-if="topics" class="w-full pb-6 md:w-1/2">
      <dl>
        <dt class="pb-1 font-sans text-sm text-gray-300">{{$t('filter.category.topic')}}</dt>
        <dd class="font-sans text-base" v-for="(item, index) in topics" :key="$uuid(index)">
          {{ item.name }}
        </dd>
      </dl>
    </div>
    <div v-if="methodTypes" class="w-full pb-6 md:w-1/2">
      <dl>
        <dt class="pb-1 font-sans text-sm text-gray-300">{{$t('filter.category.methodType')}}</dt>
        <dd class="text-base" v-for="(item, index) in methodTypes" :key="$uuid(index)">
          {{ item.name }}
        </dd>
      </dl>
    </div>
    <div v-if="organizations" class="w-full pb-6 md:w-1/2">
      <dl>
        <dt class="pb-1 font-sans text-sm text-gray-300">{{$t('article.organizations')}}</dt>
        <dd class="text-base" v-for="(item, index) in organizations" :key="$uuid(index)">
          {{ item.name }}
        </dd>
      </dl>
    </div>
    <div v-if="publishers" class="w-full pb-6 md:w-1/2">
      <dl>
        <dt class="pb-1 font-sans text-sm text-gray-300">{{$t('filter.category.publisher')}}</dt>
        <dd class="text-base" v-for="(item, index) in publishers" :key="$uuid(index)">
          {{ item.name }}
        </dd>
      </dl>
    </div>
    <div v-if="subjects" class="w-full pb-6 md:w-1/2">
      <dl>
        <dt class="pb-1 font-sans text-sm text-gray-300">{{$t('filter.category.subject')}}</dt>
        <dd class="text-base" v-for="(item, index) in subjects" :key="$uuid(index)">
          {{ item.name }}
        </dd>
      </dl>
    </div>
    <div v-if="municipalities" class="w-full pb-6 md:w-1/2">
      <dl>
        <dt class="pb-1 font-sans text-sm text-gray-300">{{$t('filter.category.municipality')}}</dt>
        <dd class="text-base" v-for="(item, index) in municipalities" :key="$uuid(index)">
          {{ item.name }}
        </dd>
      </dl>
    </div>
    <div v-if="published" class="w-full pb-6 md:w-1/2">
      <dl>
        <dt class="pb-1 font-sans text-sm text-gray-300">{{$t('article.published')}}</dt>
        <dd class="font-sans text-base">{{ published }}</dd>
      </dl>
    </div>
    <div v-if="finance" class="w-full pb-6 md:w-1/2">
      <dl>
        <dt class="pb-1 font-sans text-sm text-gray-300">{{$t('filter.category.financeResponsibility')}}</dt>
        <dd class="font-sans text-base">{{ finance }}</dd>
      </dl>
    </div>
    <div v-if="orderRecommendation" class="w-full pb-6 md:w-1/2">
      <dl>
        <dt class="pb-1 font-sans text-sm text-gray-300">{{$t('filter.category.orderRecommendation')}}</dt>
        <dd class="font-sans text-base">{{ orderRecommendation }}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    municipalities: {
      type: Array,
      required: false,
    },
    subjects: {
      type: Array,
      required: false,
    },
    topics: {
      type: Array,
      required: false,
    },
    organizations: {
      type: Array,
      required: false,
    },
    publishers: {
      type: Array,
      required: false,
    },
    methodTypes: {
      type: Array,
      required: false,
    },
    published: {
      type: String,
      required: false,
    },
    finance: {
      type: String,
      required: false,
    },
    orderRecommendation: {
      type: String,
      required: false,
    },
  },
};
</script>
