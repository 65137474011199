<template>
  <button class="flex items-center text-sm cursor-pointer text-lightblue-300 group" @click.prevent="openPopUp">
    <font-awesome-icon class="mr-2" icon="user-plus" />
    <span class="text-left underline group-hover:no-underline">
      {{$t('schema.schemaPage.inviteContributors.invite')}}
    </span>
  </button>
  <InvitePopUp v-if="showPopUp"
               @close="closePopUp"
               :roles="roles"
               :contributors="contributors"
               :organizations="organizations"
               :municipalityOptions="municipalityOptions"
               :organizationOptions="organizationOptions"
               :assessmentType="assessmentType"
               :pageId="pageId"
               @update="update"/>
</template>

<script>
  import InvitePopUp from './InvitePopUp.vue';

  export default {
    props: {
      pageId: {
        type: String,
        required: false,
      },
      roles: {
        type: Object,
        required: false,
      },
      contributors: {
        type: Object,
        required: false,
      },
      organizations: {
        type: Object,
        required: false,
      },
      organizationOptions: {
        type: Object,
        required: false,
      },
      municipalityOptions: {
        type: Object,
        required: false,
      },
      assessmentType: {
        type: String,
        required: false,
      },
    },
    components: {
      InvitePopUp,
    },
    data() {
      return {
        showPopUp: false,
      };
    },
    methods: {
      openPopUp() {
        this.showPopUp = true;
      },
      closePopUp() {
        this.showPopUp = false;
      },
      update() {
        this.$emit('update');
      },
    },
    emits: ['update'],
  };

</script>
