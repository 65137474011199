<template>
  <div class="flex flex-col w-full p-4 mb-4 text-center border border-solid border-charcoalgray rounded-md md:mb-0 md:px-11 md:py-9 h-full">
    <div
      v-if="model.image || this.$route.query.epieditmode"
      v-epi-edit="prefixPropertyName('Image')"
      class="mb-6 text-center"
    >
      <img
        :src="model.image"
        class="inline-block"
        :alt="model.alt"
      />
    </div>
    <h2
      v-if="model.heading || this.$route.query.epieditmode"
      v-epi-edit="prefixPropertyName('Heading')"
      class="mb-3 font-serif font-semibold text-hxs md:text-hmd break-all md:break-normal">
      {{model.heading}}
    </h2>
    <p
      v-if="model.text || this.$route.query.epieditmode"
      v-epi-edit="prefixPropertyName('Text')"
      class="mb-6 text-base md:text-lg"
    >
      {{model.text}}
    </p>
    <EpiLink
      v-if="model.url || this.$route.query.epieditmode"
      :class="buttonColor"
      :url="model.url"
      :title="model.linkTitle"
      class="btn btn--small lg:btn--large mt-auto"
    >
      <font-awesome-icon class="mr-2" icon="arrow-right" />
      <span>{{model.linkTitle}}</span>
    </EpiLink>
  </div>
</template>

<script>
  import EpiLink from '@/components/EpiLink.vue';

  export default {
    name: 'Link Block',
    components: {
      EpiLink,
    },
    props: {
      model: {
        type: Object,
      },
      pagePropertyName: {
        type: String,
      },
    },
    methods: {
      prefixPropertyName(propertyName) {
        // Prefix propertyName with the block name (pagePropertyName), if it's being edited on a page.
        if (this.pagePropertyName) {
          return `${this.pagePropertyName}.${propertyName}`;
        }
        return propertyName;
      },
    },
    computed: {
      buttonColor() {
        if (this.model.brightButton) {
          return 'btn--secondary__outline border-charcoalgray';
        }
        return 'btn--charcoalgray';
      },
    },
  };
</script>
