<template>
  <div class="flex flex-col items-center justify-start mb-8 md:mb-16 md:flex-row md:space-x-9">
    <div class="w-full mb-3 md:w-1/2 md:mb-0">
      <h1
        v-if="model.heading || this.$route.query.epieditmode"
        v-epi-edit="'Heading'"
        class="mb-3 font-serif font-semibold md:mb-6 md:text-hxl text-hlg"
      >
        {{model.heading}}
      </h1>
      <p
        v-if="model.introduction || this.$route.query.epieditmode"
        v-epi-edit="'Introduction'"
        class="text-base md:text-lg font-normal"
      >
        {{model.introduction}}
      </p>
    </div>
    <div
      v-epi-edit="'Image'"
      v-if="model.image || this.$route.query.epieditmode"
      class="w-full md:w-1/2"
    >
      <img v-if="model.image" :src="model.image.url" :alt="model.mainImageAltText" :title="model.heading" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
};
</script>
