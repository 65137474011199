<template>
  <div  class="mb-6 text-base xhtml-string" :class="preview ? 'w-full' : 'w-3/4'" v-html="description" />
</template>

<script>
  export default {
    name: 'Schema Description',
    props: {
      description: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
  };
</script>
