<template>
  <div class="StandardPage">
    <main>
      <span data-epi-edit="Heading">
        {{model.heading}}
      </span>
      <div data-epi-edit="FormArea">
        <ContentArea :model="model.formArea" />
      </div>
    </main>
  </div>
</template>

<script>
  import ContentArea from '@/components/EpiContentArea.vue';

  export default {
    components: {
      ContentArea,
    },
    props: ['model'],
  };
</script>
