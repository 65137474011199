<template>
  <div :id="model.contentGuid" class="flex flex-col w-full mb-12">
    <SectionTitle :model="model"
                  :readOnly="readOnly"
                  :preview="preview" />
    <SectionDescription v-if="model.description"
                        :description="model.description"
                        :readOnly="readOnly"
                        :preview="preview" />
    <div v-if="!isPeerReviewed && !isPeerReview" class="w-3/4 bg-orange-100 rounded py-2 px-4 opacity-80">
      {{ $t('schema.schemaPage.peerReview.notPeerReviewed')}}
    </div>
    <div v-else>
      <FieldTitle :model="assessmentTitleModel" />
      <div class="mb-6" :class="preview ? 'w-full' : 'w-3/4'">
        <div v-html="readOnlyTitle" class="nomargin w-full border bg-graylight-100 p-4 rounded" />
      </div>

      <FieldTitle :model="organizationTitleModel" />
      <div class="mb-6" :class="preview ? 'w-full' : 'w-3/4'">
        <div v-html="readOnlyOrganization" class="nomargin w-full border bg-graylight-100 p-4 rounded" />
      </div>
      <ul>
        <li v-for="schemaItem in model.schemaItems" :key="schemaItem.contentGuid">
          <Text v-if="schemaItem.blockType?.toString().toLowerCase() === 'text' || schemaItem.blockType?.toString().toLowerCase()  === '5'"
                :pageId="pageId"
                :model="schemaItem"
                :readOnly="readOnly"
                :preview="preview"
                :isPublished="isPublished"
                @on-save="saveInput" />
          <Section v-if="schemaItem.blockType?.toString().toLowerCase()  === 'section' || schemaItem.blockType?.toString().toLowerCase()  === '0'"
                   :pageId="pageId"
                   :model="schemaItem.properties.sectionItem"
                   @on-save="saveInput"
                   :preview="preview"
                   :readOnly="readOnly"
                   :isPublished="isPublished" />
        </li>
      </ul>
      <!-- Messages -->
      <div v-if="!readOnly">
        <div class="w-3/4 mb-4">
          <div v-if="isPeerReviewed" class="bg-orange-100 rounded py-2 px-4 opacity-80">
            {{ $t('schema.schemaPage.peerReview.sent')}}
          </div>
          <div v-else class="bg-orange-100 rounded py-2 px-4 opacity-80">
            {{ $t('schema.schemaPage.peerReview.notPeerReviewed')}}
          </div>
        </div>
        <!-- Buttons -->
        <div v-if="!isPeerReviewed" class="w-3/4">
          <button @click.prevent="openPopUp"
                  class="btn btn--small btn--charcoalgray float-right"
                  :class="isSaving ? 'btn--charcoalgray__outline' : ''"
                  :disabled="isSaving">
            <font-awesome-icon v-show="!isSaving" icon="long-arrow-alt-right" class="mr-2" />
            <font-awesome-icon v-show="isSaving" icon="spinner" class="animate-spin mr-2" />
            {{$t('schema.schemaPage.peerReview.send')}}
          </button>
        </div>
        <div v-if="isPeerReviewed" class="w-3/4">
          <button @click.prevent="openPopUp"
                  class="btn btn--small btn--charcoalgray float-right"
                  :class="isSaving ? 'btn--charcoalgray__outline' : ''"
                  :disabled="isSaving">
            <font-awesome-icon v-show="!isSaving" icon="long-arrow-alt-right" class="mr-2" />
            <font-awesome-icon v-show="isSaving" icon="spinner" class="animate-spin mr-2" />
            {{$t('schema.schemaPage.peerReview.sendUpdated')}}
          </button>
        </div>
      </div>
    </div>
    <div v-if="showPopUp">
      <ConfirmPopUp @close="closePopUp" @confirm="send" :text="$t('schema.schemaPage.peerReview.sendConfirm')" />
    </div>
  </div>
</template>

<script>
  import ConfirmPopUp from '@/views/components/confirm/ConfirmPopUp.vue';
  import SectionDescription from '@/views/components/schema/common/SectionDescription.vue';
  import SectionTitle from '@/views/components/schema/common/SectionTitle.vue';
  import Text from '@/views/components/schema/Text.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
      title: {
        type: String,
        required: true,
      },
      organization: {
        type: String,
        required: true,
      },
      isPublished: {
        type: Boolean,
        required: false,
      },
      isPeerReviewed: {
        type: Boolean,
        required: true,
      },
      isPeerReview: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        isSaving: false,
        showPopUp: false,
        readOnlyTitle: this.title,
        readOnlyOrganization: this.organization,
        assessmentTitleModel: {
          title: 'Tittel på mini-metodevurderingen',
        },
        organizationTitleModel: {
          title: 'Helseforetak/sykehus der mini-metodevurderingen er utført',
        },
      };
    },
    methods: {
      openPopUp() {
        this.showPopUp = true;
      },
      closePopUp() {
        this.showPopUp = false;
      },
      saveInput() {
        this.$emit('onSave');
      },
      send() {
        this.isSaving = true;
        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            assessmentId: this.pageId,
            isPeerReviewed: true,
          }),
        };
        fetch(`${window.location.origin}/api/schema/SetPeerReviewStatus`, requestOptions)
          .then(() => {
            this.$emit('update');
            this.$toast.open({
              message: 'Melding om ferdigstilt fagfellevurdering er sendt inn',
              type: 'success',
              dismissible: true,
            });
            this.isSaving = false;
          });
      },
    },
    components: {
      ConfirmPopUp,
      SectionDescription,
      SectionTitle,
      Text,
    },
  };
</script>
