<template>
  <div>
      <div
            class="p-2 mb-4 text-sm bg-red-100 border border-red-300 rounded text-text"
          >
            {{ message }} {{ error }}
      </div>
  </div>
</template>

<script>

export default {
  name: 'Schema Error',
  props: {
    message: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      required: false,
    },
  },
};
</script>
