<template>
  <div>
    <h2 class="mb-3 font-serif font-semibold text-hmd break-all md:break-normal">{{model.heading || model.name}}</h2>
    <p class="mb-6 text-base md:text-lg">{{model.preamble}}</p>
    <div v-for="(article, index) in articleList" :key="$uuid(index)" class="flex flex-col">
      <ArticleListItem :model="article"></ArticleListItem>
    </div>
  </div>
</template>
<script>
  import ArticleListItem from '@/views/blocks/ArticleListItem.vue';

  export default {
    props: {
      model: {
        type: Object,
      },
      articleList: {
        type: Object,
      },
    },
    components: {
      ArticleListItem,
    },
  };
</script>
