<template>
    <div>
        <h3 v-epi-edit="prefixPropertyName('Heading')">{{heading || model.heading}}</h3>
    </div>
</template>

<script>
export default {
  props: ['pagePropertyName', 'model', 'heading'],
  methods: {
    prefixPropertyName(propertyName) {
      // Prefix propertyName with the block name (pagePropertyName), if it's being edited on a page.
      if (this.pagePropertyName) {
        return `${this.pagePropertyName}.${propertyName}`;
      }
      return propertyName;
    },
  },
};
</script>
