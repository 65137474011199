<template>
  <div :class="model.narrow ? 'p:10 md:p-20' : 'pt-10 pb-10 md:pt-20 md:pb-20'">
    <h2 class="mb-3 font-serif font-semibold text-hmd break-all md:break-normal">{{model.heading || model.name}}</h2>
    <p class="mb-6 text-base md:text-lg">{{model.preamble}}</p>
    <div v-for="(link, index) in model.pageLinks" :key="$uuid(index)" class="flex flex-col">
      <div class="border bg-white p-3 mb-3">
        <a :href="link.href">
          <div class="flex items-center">
            <font-awesome-icon class="mr-2 text-red-200" icon="long-arrow-alt-right" />
            <span class="w-full">{{link.text}}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      model: {
        type: Object,
      },
    },
  };
</script>
