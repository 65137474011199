<!--
    Renders a button to edit a property in OPE. Useful when the property to
    edit isn't directly visible (such as ArtistGenre on the ArtistDetailsPage
    component), or when the OPE overlays would be overlapping
    (e.g. the background image in the Hero component).
-->

<template>
  <button class="hover:border-2 hover:bg-transparent hover:border-charcoalgray hover:text-charcoalgray
          text-white bg-charcoalgray border-2 border-charcoalgray cursor-pointer w-max rounded py-1 px-2 my-2 mx-2"
          v-if="isEditable"
          v-epi-edit="propertyName"
          type="button">
    <span>
      <font-awesome-icon icon="edit" /> {{text}}
    </span>
  </button>

</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['propertyName', 'buttonText'],
  computed: mapState({
    isEditable: (state) => state.epiContext.isEditable,
  }),
  data() {
    return {
      text: this.buttonText ? this.buttonText : this.propertyName,
    };
  },
};
</script>
