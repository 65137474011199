<template>
  <div :id="model.contentGuid" class="flex w-full flex-col mb-10" >
    <SectionTitle :model="model" :readOnly="readOnly" :preview="preview"/>
    <SectionDescription v-if="model.description" :description="model.description" :readOnly="readOnly" :preview="preview" />

    <!-- placeholder for markup -->
    <slot></slot>

    <ul>
      <li v-for="schemaItem in model.schemaItems" :key="schemaItem.contentGuid">
        <SchemaHeading v-if="schemaItem.blockType?.toString().toLowerCase()  === 'heading' || schemaItem.blockType?.toString().toLowerCase()  === '17'"
                       :model="schemaItem" />
        <Section v-if="schemaItem.blockType?.toString().toLowerCase()  === 'section' || schemaItem.blockType?.toString().toLowerCase()  === '0'"
                 :pageId="pageId"
                 :model="schemaItem.properties.sectionItem"
                 :preview="preview"
                 :readOnly="readOnly"
                 :isPublished="isPublished"
                 @on-save="saveInput"
                 @get-schema="getSchema" />
        <Search v-if="schemaItem.blockType?.toString().toLowerCase()  === 'search' || schemaItem.blockType?.toString().toLowerCase()  === '7'"
                :model="schemaItem"
                @on-save="saveInput"
                :preview="preview"
                :readOnly="readOnly" />
        <Text v-if="schemaItem.blockType?.toString().toLowerCase()  === 'text' || schemaItem.blockType?.toString().toLowerCase()  === '5'"
              :pageId="pageId"
              :model="schemaItem"
              @on-save="saveInput"
              :preview="preview"
              :readOnly="readOnly"
              :isPublished="isPublished"
              @get-schema="getSchema" />
        <TextEditor v-if="schemaItem.blockType?.toString().toLowerCase()  === 'texteditor' || schemaItem.blockType?.toString().toLowerCase()  === '6'"
                    :pageId="pageId"
                    :model="schemaItem"
                    @on-save="saveInput"
                    :preview="preview"
                    :readOnly="readOnly"
                    @get-schema="getSchema" />
        <div v-if="schemaItem.blockType?.toString().toLowerCase()  === 'select' || schemaItem.blockType?.toString().toLowerCase()  === '4'">
          <div v-if="schemaItem.properties.selectType?.toString().toLowerCase()  === 'dropdownlist'">
            <Dropdown :model="schemaItem"
                      :pageId="pageId"
                      @on-save="saveInput"
                      :preview="preview"
                      :readOnly="readOnly" />
          </div>
          <div v-if="schemaItem.properties.selectType?.toString().toLowerCase()  === 'radiobuttons'">
            <Radio :model="schemaItem"
                   :pageId="pageId"
                   @on-save="saveInput"
                   :preview="preview"
                   :readOnly="readOnly" />
          </div>
          <div v-if="schemaItem.properties.selectType?.toString().toLowerCase()  === 'checkboxes'">
            <Checkboxes :model="schemaItem"
                        :pageId="pageId"
                        @on-save="saveInput"
                        :preview="preview"
                        :readOnly="readOnly"
                        @get-schema="getSchema" />
          </div>
        </div>
        <Download v-if="schemaItem.blockType?.toString().toLowerCase()  === 'file' || schemaItem.blockType?.toString().toLowerCase()  === '8'"
                  :model="schemaItem"
                  :preview="preview"
                  :readOnly="readOnly" />
        <EconomyEvaluationUpload v-if="schemaItem.blockType?.toString().toLowerCase()  === 'economyevaluation' || schemaItem.blockType?.toString().toLowerCase()  === '9'"
                                 :model="schemaItem"
                                 :pageId="pageId"
                                 :preview="preview"
                                 :readOnly="readOnly" />
        <EconomyMunicipality v-if="schemaItem.blockType?.toString().toLowerCase()  === 'economymunicipality' || schemaItem.blockType?.toString().toLowerCase()  === '18'"
                                 :model="schemaItem"
                                 :pageId="pageId"
                                 :preview="preview"
                                 :readOnly="readOnly" />
        <Table v-if="schemaItem.blockType?.toString().toLowerCase()  === 'table' || schemaItem.blockType?.toString().toLowerCase()  === '12'"
               :model="schemaItem"
               :pageId="pageId"
               :preview="preview"
               :readOnly="readOnly" />
        <LiteratureSearch v-if="schemaItem.blockType?.toString().toLowerCase()  === 'literaturesearch' || schemaItem.blockType?.toString().toLowerCase()  === '13'"
                          :model="schemaItem"
                          :pageId="pageId"
                          :preview="preview"
                          :readOnly="readOnly"
                          @get-schema="getSchema" />
        <EvidenceTable v-if="schemaItem.blockType?.toString().toLowerCase()  === 'evidencetable' || schemaItem.blockType?.toString().toLowerCase()  === '14'"
                       :model="schemaItem"
                       :pageId="pageId"
                       :preview="preview"
                       :readOnly="readOnly"
                       @get-schema="getSchema" />
        <RelationSelector v-if="schemaItem.blockType?.toString().toLowerCase()  === 'relationselector' || schemaItem.blockType?.toString().toLowerCase()  === '15'"
                          :model="schemaItem"
                          :pageId="pageId"
                          @on-save="saveInput"
                          :preview="preview"
                          :readOnly="readOnly"
                          @get-schema="getSchema" />
        <ImageUpload v-if="schemaItem.blockType?.toString().toLowerCase() === 'imageupload' || schemaItem.blockType?.toString().toLowerCase()  === '16'"
                     :model="schemaItem"
                     :pageId="pageId"
                     :preview="preview"
                     :readOnly="readOnly" />
        <PdfUpload v-if="schemaItem.blockType?.toString().toLowerCase() === 'pdfupload' || schemaItem.blockType?.toString().toLowerCase()  === '19'"
                     :model="schemaItem"
                     :pageId="pageId"
                     :preview="preview"
                     :readOnly="readOnly" />
      </li>
    </ul>
  </div>
</template>

<script>
  import Checkboxes from '@/views/components/schema/Checkboxes.vue';
  import Download from '@/views/components/schema/Download.vue';
  import EconomyEvaluationUpload from '@/views/components/schema/EconomyEvaluationUpload.vue';
  import EconomyMunicipality from '@/views/components/schema/EconomyMunicipality.vue';
  import EvidenceTable from '@/views/components/schema/evidenceTable/EvidenceTable.vue';
  import Dropdown from '@/views/components/schema/Dropdown.vue';
  import Radio from '@/views/components/schema/Radio.vue';
  import SectionDescription from '@/views/components/schema/common/SectionDescription.vue';
  import SectionTitle from '@/views/components/schema/common/SectionTitle.vue';
  import Search from '@/views/components/schema/Search.vue';
  import Table from '@/views/components/schema/table/Table.vue';
  import Text from '@/views/components/schema/Text.vue';
  import TextEditor from '@/views/components/schema/TextEditor.vue';
  import LiteratureSearch from '@/views/components/schema/literatureSearch/LiteratureSearch.vue';
  import RelationSelector from '@/views/components/schema/RelationSelector.vue';
  import ImageUpload from '@/views/components/schema/ImageUpload.vue';
  import PdfUpload from '@/views/components/schema/PdfUpload.vue';
  import SchemaHeading from '@/views/components/schema/SchemaHeading.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
      isPublished: {
        type: Boolean,
        required: false,
      },
    },
    computed: {
      console: () => console,
    },
    methods: {
      saveInput() {
        this.$emit('onSave');
      },
      getSchema() {
        this.$emit('getSchema');
      },
    },
    components: {
      Checkboxes,
      Dropdown,
      Radio,
      Search,
      Text,
      TextEditor,
      Download,
      SectionDescription,
      SectionTitle,
      EconomyEvaluationUpload,
      EconomyMunicipality,
      Table,
      LiteratureSearch,
      RelationSelector,
      EvidenceTable,
      ImageUpload,
      PdfUpload,
      SchemaHeading,
    },
    emits: ['onSave', 'getSchema'],
  };
</script>
