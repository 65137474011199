<template>
  <div class="flex justify-center shortcuts">
    <a href="#hovedmeny" :title="$t('navigationUtils.jumpToMenuButton.description')">{{$t('navigationUtils.jumpToMenuButton.text')}}</a>
    <a href="#innhold" :title="$t('navigationUtils.jumpToMenuButton.description')">{{$t('navigationUtils.jumpToMainContent.text')}}</a>
  </div>
</template>
<script>
export default {
  name: 'Shortcuts',
};
</script>
