<template>
  <div :id="model.contentGuid" class="flex w-full flex-col mb-12">
    <div v-if="userRole !== 'initiator'">
      <div class="bg-orange-100 rounded py-2 px-4 opacity-80 w-3/4">
        {{$t('schema.schemaPage.publishStatusMessage.noAccess')}}
      </div>
    </div>
    <div v-else>
      <SectionTitle :model="model" :readOnly="readOnly" :preview="preview" />
      <SectionDescription v-if="model.description" :description="model.description" :readOnly="readOnly" :preview="preview" />
      <div v-if="status === 1" class="w-3/4">
        <p class="mb-2 text-lg font-semibold">{{ $t('schema.schemaPage.publishStatusMessage.status')}}</p>
        <div class="bg-orange-100 rounded py-2 px-4 opacity-80 mb-4">
          {{ $t('schema.schemaPage.publishStatusMessage.isDraft')}}
        </div>
        <div class="float-right">
          <button @click.prevent="send"
                  class="btn btn--small btn--charcoalgray"
                  :class="isSaving ? 'btn--charcoalgray__outline' : ''"
                  :disabled="isSaving">
            <font-awesome-icon v-show="!isSaving" icon="long-arrow-alt-right" class="mr-2" />
            <font-awesome-icon v-show="isSaving" icon="spinner" class="animate-spin mr-2" />
            {{$t('schema.common.action.sendApprovalRequest')}}
          </button>
        </div>
      </div>
      <div v-if="status === 2" class="w-3/4">
        <p class="mb-2 text-lg font-semibold">{{ $t('schema.schemaPage.publishStatusMessage.status')}}</p>
        <div class="bg-orange-100 rounded py-2 px-4 opacity-80">
          {{ $t('schema.schemaPage.publishStatusMessage.pending')}}
        </div>
      </div>
      <div v-if="status === 3" class="w-3/4">
        <p class="mb-2 text-lg font-semibold">{{ $t('schema.schemaPage.publishStatusMessage.status')}}</p>
        <div class="bg-green-100 rounded py-2 px-4 opacity-80">
          <font-awesome-icon icon="check" class="mr-1" />
          {{ $t('schema.schemaPage.publishStatusMessage.published')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SectionDescription from '@/views/components/schema/common/SectionDescription.vue';
  import SectionTitle from '@/views/components/schema/common/SectionTitle.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: true,
      },
      readOnly: {
        type: Boolean,
        required: true,
      },
      status: {
        type: Number,
        required: true,
      },
      preview: {
        type: Boolean,
        required: false,
      },
      userRole: {
        type: String,
        required: true,
      },
    },
    components: {
      SectionDescription,
      SectionTitle,
    },
    data() {
      return {
        isSaving: false,
      };
    },
    methods: {
      send() {
        this.isSaving = true;
        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            assessmentId: this.pageId,
          }),
        };
        fetch(`${window.location.origin}/api/schema/SendApprovalRequest`, requestOptions)
          .then(() => {
            this.$emit('update');
            this.$toast.open({
              message: 'Status på mini-metodevurderingen oppdatert',
              type: 'success',
              dismissible: true,
            });
            this.isSaving = false;
          });
      },
    },
    emits: ['update'],
  };
</script>
