<template>
  <FocusTrap
  :initial-focus="() => $refs.closePopup"
  :escapeDeactivates="true"
  @deactivate="onDeactivateFocusTrap()"
  ref="focusTrapHelpPopup">
  <div class="z-30 fixed w-full h-full top-0 left-0 flex items-center justify-center bg-gray-400 bg-opacity-50 mx-auto">
    <div v-click-outside="hidePopup" class="lg:w-1/2 sm:w-3/4 w-full inset-0 my-4 p-8 max-h-screen overflow-y-scroll md:overflow-hidden bg-bluegray-200">
      <div class="flex justify-between h-6 mb-8">
        <button @click="hidePopup" class="cursor-pointer">
          <font-awesome-icon class="font-semibold text-xl" icon="times" />
        </button>
      </div>
      <div class="flex justify-center">
        <img :src="imageSrc" />
      </div>
      <div class="flex justify-start">
        <button class="btn btn--secondary mt-4" @click="hidePopup" :title="$t('window.close.description.popup')" ref="closePopup">{{$t('window.close.text')}}</button>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>
  import vClickOutside from 'click-outside-vue3';
  import { FocusTrap } from 'focus-trap-vue';
  import { HIDE_MODAL } from '@/store/modules/appContext';

  export default {
    components: {
      FocusTrap,
    },
    props: {
      imageSrc: {
        type: String,
        required: true,
      },
    },
    directives: {
      clickOutside: vClickOutside.directive,
    },
    methods: {
      hidePopup() {
        this.$emit('close');
        this.$store.commit(HIDE_MODAL);
      },
      onDeactivateFocusTrap() {
        this.hidePopup();
      },
    },
  };
</script>
