<template>
  <section class="w-full py-20 mb-10 md:mb-20 bg-graylight-200">
    <div class="container">
      <h2
        v-if="heading"
        class="mb-12 font-serif font-semibold text-center text-hmd"
      >
        {{heading}}
      </h2>
      <ul class="flex flex-row flex-wrap md:-my-4 md:-mx-9">
        <li
          v-for="item in notificationlist"
          :key="$uuid(item.hits)"
          class="w-full py-4 md:w-1/2 lg:w-1/3 md:px-9"
        >
          <a
            :href="item.url"
            :title="item.subjectName"
            class="flex w-full group hover:text-black"
          >
            <span class="mt-1 mr-6">
              <inline-svg
                :src="item.icon ? item.icon : require('@/assets/images/bandAid.svg')"
                height="32"
                :title="item.subjectName"
              >
              </inline-svg>
            </span>
            <span class="block w-full pb-2 text-base border-b group-hover:border-black">
              {{item.subjectName + ` (${item.hits})`}}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Link list block',
  props: {
    notificationlist: {
      type: Array,
    },
    heading: {
      type: String,
    },
  },
};
</script>
