<template>
  <div>
    <FieldTitle :model="model" />
    <div v-if="readOnly" class="flex">
      <div :class="preview ? 'w-full' : 'w-3/4'">
        <div v-if="text" v-html="readOnlyText" class="border bg-graylight-100 p-4 rounded" />
        <div v-else>
          <input type="text"
                 class="nomargin"
                 :id="guid"
                 v-model="readOnlyText"
                 disabled />
        </div>
      </div>
    </div>
    <div v-else class="flex">
      <div class="w-full pr-0 xl:w-3/4">
        <tiny-editor @update="save" :value="text" :readonly="isSaving"></tiny-editor>
        <div class="relative">
          <font-awesome-icon v-show="isSaving" icon="spinner" class="animate-spin absolute right-2.5 top-0" />
        </div>
      </div>
      <div class="hidden w-1/4 pr-0 mb-4 xl:block lg:pl-4">
        <CommentPane
          v-if="!model.deactivateComments"
          :model="model.comments"
          :contentGuid="model.contentGuid"
          :pageId="pageId"
          :fieldTitle="model.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import CommentPane from '@/views/components/schema/CommentPane.vue';
  import FieldTitle from '@/views/components/schema/common/FieldTitle.vue';
  import TinyEditor from '@/components/tiny/tinyEditor.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      CommentPane,
      FieldTitle,
      TinyEditor,
    },
    data() {
      return {
        type: this.model.type,
        text: this.model.properties.text,
        readOnlyText: this.model.properties.text ? this.model.properties.text : 'Ikke utfylt',
        isSaving: false,
      };
    },
    methods: {
      save(input) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.model.contentGuid, value: input, type: this.type, pageId: this.pageId,
          }),
        };
        this.isSaving = true;
        const self = this;
        fetch(`${window.location.origin}/api/schema/SaveSchemaItem`, requestOptions)
          .then(() => {
            this.$emit('onSave');
            this.$emit('getSchema');
          }).then(() => {
            self.isSaving = false;
            this.$toast.open({
              message: 'Lagret',
              type: 'success',
              dismissible: true,
            });
          });
      },
    },
    emits: ['onSave', 'getSchema'],
  };
</script>
