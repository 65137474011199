<template>
  <div class="w-full xl:w-3/4 pr-0">
    <div v-if="model.title">
      <FieldTitle :model="model" />
    </div>
  </div>
  <!--Read only-->
  <div v-if="readOnly" :class="preview ? 'w-full' : 'w-3/4'">
    <!--List of uploaded images-->
    <div v-if="this.images && this.images.length > 0" class="grid grid-cols-2 gap-1 sm:grid-cols-4 sm:gap-2 md:grid-cols-8 md:gap-3">
      <div v-for="(image, index) in this.images" :key="$uuid(index)" class="flex justify-center border">
        <img :src="image.imageSrc" @click="openModal(image.imageSrc)" />
      </div>
    </div>
    <!--Modal-->
    <ImagePopUp @close="closeModal()" v-if="showModal" :imageSrc="this.modalImageSrc" />
  </div>

  <!--Edit mode-->
  <div v-else class="w-3/4 mb-10" >

    <!--Image upload-->
    <div v-if="((!uploadFinished || !uploadResult) && !fileName) || removeFileResult " class="mb-2">
      <button class="btn btn--charcoalgray" @click="onFileUploadClick">
        <font-awesome-icon icon="file" class="mr-2" />
        <span>{{$t('schema.common.action.selectImage')}}</span>
      </button>
      <input type="file"
             multiple
             style="display: none"
             ref="fileInput"
             accept=".png, .jpg, .jpeg"
             @change="imageUploadChange" />

      <!--Small help text-->
      <div class="mt-4 mb-4">
        <span class="text-gray-200">{{$t('schema.common.action.upload.feedback.supportedFormat', { formats : '.png, .jpg, .jpeg' })}}</span>
      </div>

      <!--List of uploaded images-->
      <div v-if="this.images && this.images.length > 0">
        <span class="text-base">Dine bilder:</span>
        <div v-for="(image, index) in this.images" :key="$uuid(index)" class="flex flex-col">
          <div class="w-full mt-4 border p-4">
            <div>
              <span class="italic">{{image.imageName}}</span>
              <img class="w-24" :src="image.imageSrc" @click="openModal(image.imageSrc)" />
            </div>
            <div>
              <div class="mt-4">
                <button @click="deleteImage(image.imageName)">
                  <font-awesome-icon icon="trash" class="mr-2" />
                  <span>Slett</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Modal-->
      <ImagePopUp @close="closeModal()" v-if="showModal" :imageSrc="this.modalImageSrc" />

      <!--Feedback-->
      <div class="mt-4">
        <span>{{this.feedback}}</span>
      </div>

    </div>
  </div>

</template>

<script>
  import axios from 'axios';
  import FieldTitle from '@/views/components/schema/common/FieldTitle.vue';
  import ImagePopUp from '@/views/components/image/ImagePopUp.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      readOnly: {
        type: Boolean,
        default: false,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      FieldTitle,
      ImagePopUp,
    },
    data() {
      return {
        file: null,
        images: [],
        modalImageSrc: '',
        showModal: false,
      };
    },
    mounted() {
      this.getImages();
    },
    methods: {
      deleteImage(imageFileName) {
        const imageData = { imageName: imageFileName };
        axios.post(`${window.location.origin}/api/schema/DeleteImage/${this.model.contentId}`, imageData)
          .then((response) => {
            if (response) {
              this.getImages();
            } else {
              this.feedback = 'Beklager, bildet kunne ikke slettes.';
            }
          }, (error) => {
            this.statusMessage = error;
          });
      },
      onFileUploadClick() {
        this.$refs.fileInput.click();
      },
      imageUploadChange(e) {
        const fileUploaded = e.target.files || e.dataTransfer.files;
        const [file] = fileUploaded;
        this.file = file;
        this.submitFile();
      },
      submitFile() {
        if (this.file) {
          const formData = new FormData();
          formData.append('file', this.file);
          axios.post(`${window.location.origin}/api/schema/UploadImages/${this.model.contentId}`, formData)
            .then((response) => {
              if (response) {
                this.getImages();
              } else {
                this.feedback = 'Beklager, noe gikk galt.';
              }
            }, (error) => {
              this.statusMessage = error;
            });
        }
      },
      getImages() {
        fetch(`${window.location.origin}/api/schema/getImages/${this.model.contentId}`)
          .then((response) => {
            response.json().then((data) => {
              this.images = data;
            });
          });
      },
      openModal(imageSrc) {
        this.modalImageSrc = imageSrc;
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      },
    },
  };
</script>
