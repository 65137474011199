<template>
    <ResizableTextArea :name="id"
                        class="h-full nomargin border-none"
                        :placeholder="model.placeholder"
                        :readOnly="readOnly"
                        v-on:change="save"
                        v-model="value" />
</template>

<script>
  import ResizableTextArea from '@/views/components/textarea/ResizableTextArea.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: false,
      },
      contentGuid: {
        Type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      ResizableTextArea,
    },
    data() {
      return {
        value: this.model.text,
        id: this.$uuid(),
      };
    },
    methods: {
      save() {
         const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              id: this.contentGuid,
              value: this.value,
              pageId: this.pageId,
            }),
         };

         this.isSaving = true;
         const self = this;
         fetch(`${window.location.origin}/api/schema/SaveSchemaItem`, requestOptions)
          .then(() => {
            this.$emit('onSave');
          }).then(() => {
            self.isSaving = false;
            this.$toast.open({
              message: 'Lagret',
              type: 'success',
              dismissible: true,
            });
          });
      },
    },
  };
</script>
