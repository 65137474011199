<template>
  <!-- https://github.com/martyf/tinymce-5-vue -->
  <Editor v-model='content'
          :api-key='getTinyKey'
          :init='getTinyConfig'
          :disabled="readonly"
          v-on:focusout="save"></Editor>
</template>

<script>
  import Editor from '@tinymce/tinymce-vue';
  import TinyConfig from '@/assets/scripts/tinyConfig';

  export default {
    name: 'tiny-editor',
    components: {
      Editor,
    },
    computed: {
      getTinyConfig() {
        return TinyConfig.getConfig(this.type);
      },
      getTinyKey() {
        return TinyConfig.getAPIKey();
      },
    },
    data() {
      return {
        content: this.value,
        editor: false,
      };
    },
    props: {
      value: {
        type: String,
        required: false,
        default: '',
      },
      readonly: {
        type: Boolean,
        required: false,
        default: false,
      },
      type: {
        type: [Boolean, String],
        required: false,
        default: false,
      },
    },
    methods: {
      save() {
        this.$emit('update', this.content);
      },
    },
  };
</script>
