<template>
    <article class="container-article">
      <h1
        v-epi-edit="'Heading'"
        class="mb-6 font-serif font-semibold text-hmd md:text-hlg"
      >
        {{ model.heading }}
      </h1>

      <ChangeLogSimple class="mb-6" :model="model" />
      <Toolbar
          v-if="model"
          :model="model"
          :showNotify="false"
          :showSharing="true"
          :showPrint="true"
          :hideContact="true"
        class="border-b border-gray-100 pb-9 mb-9"
      />

      <div v-if="showPopup">
        <Popup @close="closePopup" :topic="model.heading" />
      </div>

      <section
        v-if="!model.isExternalMethod && model.document"
        class="border-b border-gray-100 print:hidden pb-9 mb-9"
      >
        <a
          :href="model.document"
          class="text-lg md:text-hxs text-lightblue-300 group"
          title="Last ned metodevarsel"
          download
        >
          <font-awesome-icon class="mr-4" icon="file-pdf" />
          <span class="underline group-hover:no-underline">{{$t('methodNotificationPage.downloadPDF')}}</span>
        </a>
      </section>
      <section
        v-else-if="model.externalUrl"
        class="border-b border-gray-100 pb-9 mb-9"
      >
        <EpiLink
          :url="model.externalUrl"
          class="text-hxs text-lightblue-300 group"
          title="Gå til metodevarsel"
        >
          <font-awesome-icon class="mr-4" icon="link" />
          <span class="underline group-hover:no-underline">Gå til metodevarsel (eksternt nettsted)</span>
        </EpiLink>
      </section>

      <section
        v-if="model.methodSummary || model.mainBody"
        class="mb-9"
      >
        <div
          v-html="model.methodSummary"
          v-epi-edit="'MethodSummary'"
          class="article-small"
        ></div>
        <div
          v-html="model.mainBody"
          v-epi-edit="'MainBody'"
          class="article-small"
        ></div>
      </section>

      <section v-if="model.nyeMetoderLink" class="border-b border-gray-100 mb-9 pb-9">
        <h2 class="mb-4 font-serif text-hmd">Status</h2>
        <div class="article-small">
          <p>
            Metoden er foreslått til vurdering i Nye metoder:
            <EpiLink
              :url="model.nyeMetoderLink"
              class="underline text-lightblue-300 hover:no-underline"
              title="Se status på Nye Metoder"
            >
              se status
            </EpiLink>.
          </p>
        </div>
      </section>

      <section>
        <DetailList
          :topics="model.relatedTopics"
          :publishers="model.relatedPublishers"
          :methodTypes="model.relatedMethodTypes"
          :published="model.year"
          :finance="model.financialResponsibility"
          :orderRecommendation="model.orderRecommendation"
        />
      </section>
    </article>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import ChangeLogSimple from '@/components/article/ChangeLogSimple.vue';
import Toolbar from '@/views/components/toolbar/Toolbar.vue';
import EpiLink from '@/components/EpiLink.vue';

export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  components: {
    ChangeLogSimple,
    Toolbar,
    EpiLink,
  },
};
</script>
