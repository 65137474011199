<template>
  <div class="p-4 mb-10 rounded-md md:px-12 md:py-8 bg-bluegray-200 md:mb-20">
    <h2
      v-epi-edit="prefixPropertyName('Heading')"
      class="mb-3 font-serif md:text-hmd text-hsm"
    >
      {{model.heading}}
    </h2>

    <div class="flex flex-col md:flex-row">
      <div class="w-full md:w-2/3">
        <p
          v-epi-edit="prefixPropertyName('Text')"
          class="mb-6 text-lg"
        >
          {{model.text}}
        </p>

        <a
          v-if="model.link"
          :href="model.link"
          :title="model.linkTitle"
          class="border border-black btn btn--negative group"
        >
          <font-awesome-icon class="mr-1 text-red-200 group-hover:text-white" icon="long-arrow-alt-right" />
          {{model.linkTitle}}
        </a>
        <button
          v-else
          @click="showPopup"
          v-epi-edit="prefixPropertyName('LinkTitle')"
          class="border border-black btn btn--negative group"
        >
          <font-awesome-icon class="mr-1 text-red-200 group-hover:text-white" icon="long-arrow-alt-right" />
          {{model.linkTitle}}
        </button>
      </div>
      <div v-if="model.image" class="hidden text-left md:w-1/3 md:text-right md:block">
        <img class="inline-block h-16 " v-epi-edit="prefixPropertyName('Image')" alt="img" :src="model.image && model.image.url"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { SHOW_MODAL } from '@/store/modules/appContext';

export default {
  props: {
    pagePropertyName: {
      type: String,
    },
    model: {
      type: Object,
      required: true,
    },
    extraData: {
      type: Array,
    },
  },
  computed: () => ({
    }),
  methods: {
      showPopup() {
            this.$store.commit(SHOW_MODAL);
          },
    prefixPropertyName(propertyName) {
      // Prefix propertyName with the block name (pagePropertyName), if it's being edited on a page.
      if (this.pagePropertyName) {
        return `${this.pagePropertyName}.${propertyName}`;
      }
      return propertyName;
    },
  },
};
</script>
