<template>
  <div class="w-full xl:w-3/4 pr-0">
    <div v-if="model.title">
      <FieldTitle :model="model" />
    </div>
  </div>
  <!--Public mode-->
  <div v-if="readOnly" :class="preview ? 'w-full' : 'w-3/4'" class="mb-10">
    <!--Info text-->
    <div v-if="model.properties.infoTextReader" class="mb-6 mt-6" v-html="model.properties.infoTextForReader">
    </div>

    <!--List of uploaded pdfs-->
    <div v-if="this.pdf && this.pdf.pdfName" class="w-full mt-4">
      <div class="mb-6">
        <span class="mr-2">{{$t('schema.common.action.displayInBrowser')}}:</span>
        <a class="py-3 underline text-lightblue-300" :href=this.pdf.fileUrl target="_blank">
          {{this.pdf.pdfName}}
          <font-awesome-icon icon="file-pdf" class="mr-2" />
        </a>
      </div>
      <div class="relative" style="padding-top: 56.25%">
        <iframe :src=this.pdfUrl class="absolute inset-0 w-full h-full" frameborder="0" title="PDF-file of review"></iframe>
      </div>
    </div>
  </div>

  <!--Draft mode-->
  <div v-else class="w-3/4 mb-10">

    <!--Info text-->
    <div class="mb-6 mt-6" v-if="model.properties.infoTextDraft" v-html="model.properties.infoTextDraft">
    </div>

    <!--Pdf upload-->
    <div v-if="!this.pdf || !this.pdf.pdfName" class="mb-2">
      <button class="btn btn--charcoalgray" @click="onFileUploadClick">
        <font-awesome-icon icon="file" class="mr-2" />
        <span>{{$t('schema.common.action.selectFile')}}</span>
      </button>
      <input type="file"
             multiple
             style="display: none"
             ref="fileInput"
             accept=".pdf"
             @change="pdfUploadChange" />

      <!--Small help text-->
      <div class="mt-4 mb-4">
        <span class="text-gray-200">{{$t('schema.common.action.upload.feedback.supportedFormat', { formats : '.pdf' })}}</span>
      </div>
    </div>

    <!--List of uploaded pdfs-->
    <div v-if="this.pdf && this.pdf.pdfName">
      <div class="mt-6">
        <a class="py-3 underline text-lightblue-300" title="pdf of review" :href=this.pdf.fileUrl>
          {{this.pdf.pdfName}}
          <font-awesome-icon icon="file-pdf" class="mr-2" />
        </a>
      </div>
      <div class="mt-4">
        <button @click="deletePdf(this.pdf.pdfName)">
          <font-awesome-icon icon="trash" class="mr-2" />
          <span>Slett</span>
        </button>
      </div>
    </div>

    <!--Feedback-->
    <div class="mt-4">
      <span>{{this.feedback}}</span>
    </div>

  </div>

</template>

<script>
  import axios from 'axios';
  import FieldTitle from '@/views/components/schema/common/FieldTitle.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      readOnly: {
        type: Boolean,
        default: false,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      FieldTitle,
    },
    data() {
      return {
        file: null,
        pdf: null,
        showModal: false,
        pdfUrl: '',
      };
    },
    mounted() {
      this.getPdf();
    },
    methods: {
      deletePdf(pdfFileName) {
        const pdfData = { pdfName: pdfFileName };
        axios.post(`${window.location.origin}/api/schema/DeletePdf/${this.model.contentId}`, pdfData)
          .then((response) => {
            if (response) {
              this.getPdf();
            } else {
              this.feedback = 'Beklager, pdfen kunne ikke slettes.';
            }
          }, (error) => {
            this.statusMessage = error;
          });
      },
      onFileUploadClick() {
        this.$refs.fileInput.click();
      },
      pdfUploadChange(e) {
        const fileUploaded = e.target.files || e.dataTransfer.files;
        const [file] = fileUploaded;
        this.file = file;
        this.submitFile();
      },
      submitFile() {
        if (this.file) {
          const formData = new FormData();
          formData.append('file', this.file);
          axios.post(`${window.location.origin}/api/schema/UploadPdf/${this.model.contentId}`, formData)
            .then((response) => {
              if (response) {
                this.getPdf();
              } else {
                this.feedback = 'Beklager, noe gikk galt.';
              }
            }, (error) => {
              this.statusMessage = error;
            });
        }
      },
      getPdf() {
        fetch(`${window.location.origin}/api/schema/getPdf/${this.model.contentId}`)
          .then((response) => {
            response.json().then((data) => {
              this.pdf = data;
              this.pdfUrl = `${this.pdf.fileUrl}#toolbar=0&navpanes=0`;
            });
          });
      },
    },
    emits: ['onSave'],
  };
</script>
