<template>
  <div v-if="model.url" class="flex flex-col w-full mb-4 border-2 rounded-md md:w-1/2">
    <a :href="model.url"
       :class="buttonColor"
       :url="model.url"
       :title="model.linkTitle"
       class="btn btn--small lg:btn--large mt-auto"
       download>
      <font-awesome-icon class="mr-2" icon="arrow-down" />
      <span>{{model.linkText ?? model.name}}</span>
    </a>
  </div>
</template>

<script>
  export default {
    name: 'MediaFileBlock',
    props: {
      model: {
        type: Object,
      },
    },
  };
</script>
