<template>
  <div class="LoginPage">
    <main class="Page-container">
      <div class="container flex flex-col justify-center p-10">
        {{ model.name }}
        <form @submit.prevent="submitForm">
          <div class="flex-row">
            <label for="username">{{$t("login.username.text")}}</label>
            <input type="text" name="username" ref="username" />
          </div>
          <div class='form-control flex-row'>
            <label for="password">{{$t("login.password.text")}}</label>
            <input type="password" name="password" ref="password" />
          </div>
          <div class="form-control flex-row">
            <button type="submit" class="btn--charcoalgray btn--medium">{{$t("login.login.text")}}</button>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  props: ['model'],
  methods: {
    submitForm() {
        // let username = this.$refs.username.value;
        // let password = this.$refs.password.value;
    },
    setMetaTags() {
      document.title = this.model.name;
      document.querySelector('meta[property="og:title"]').setAttribute('content', this.model.name);
    },
  },
  mounted() {
    this.setMetaTags();
  },
};
</script>
