const state = {
  schema: {
    commentpane: {
      guid: null,
    },
  },
};

const mutations = {
  // Set published
  setCommentGuid(state, payload) {
    state.schema.commentpane.guid = payload;
  },
};

const getters = {
  // Hent informasjon som kan brukes i APP-en
  getSchemaCommentGuid: (state) => state.schema.commentpane.guid,
};

export default {
  state,
  mutations,
  getters,
};
