<template>
  <div v-if="status === 1" class="text-sm text bg-orange-100 rounded py-1 px-2 opacity-80">
    {{ $t('schema.schemaPage.badge.draft')}}
  </div>
  <div v-if="status === 2" class="text-sm text bg-orange-100 rounded py-1 px-2 opacity-80">
    {{ $t('schema.schemaPage.badge.inPreview')}}
  </div>
  <div v-if="status === 3" class="text-sm text bg-green-100 rounded py-1 px-2 opacity-80">
    <font-awesome-icon icon="check" class="mr-1" />
    {{ $t('schema.schemaPage.badge.published')}}
  </div>
</template>

<script>
  export default {
    props: {
      status: {
        type: Number,
        required: true,
      },
    },
  };
</script>
