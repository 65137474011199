<template>
  <div class="StartPage">
    <div class="container-narrow">
      <Hero :model="model" />
      <div class="flex flex-col mb-10 md:mb-20 md:flex-row">
        <div class="md:w-1/2 w-full md:pr-5">
          <LinkBlock :model="model.leftbox" />
        </div>
        <div class="md:w-1/2 w-full md:pl-5">
          <LinkBlock :model="model.rightbox" />
        </div>
      </div>
    </div>
    <!--Article list-->
    <div v-if="!model.hideArticleList" class="flex flex-col mx-auto my-8 container-narrow">
      <ArticleList v-epi-edit="'ArticleListBlock'" :model="model.articleListBlock"  :articleList="model.articleList" />
    </div>
    <!--Page link list-->
    <div v-if="!model.hidePageLinks" class="bg-graylight-200">
      <div class="container-narrow">
        <PageLinkList v-epi-edit="'PageLinksBlock'" :model="model.pageLinksBlock" />
      </div>
    </div>
    <!--Main content-->
    <div class="flex flex-col mx-auto my-8 container-narrow">
      <ContentArea v-epi-edit="'MainContent'" :model="model.mainContent" />
    </div>
    <!--FAQ-->
    <div v-if="!model.hideFaq" class="flex flex-col mx-auto my-8 container-narrow">
      <FAQ v-epi-edit="'FAQ'" :model="model.faq" />
    </div>
  </div>
</template>

<script>
  import ContentArea from '@/components/EpiContentArea.vue';
  import LinkBlock from '@/views/blocks/LinkBlock.vue';
  import FAQ from '@/views/components/faq/FAQ.vue';
  import ArticleList from '@/views//blocks/ArticleListBlock.vue';
  import PageLinkList from '@/views/blocks/PageLinkListBlock.vue';

  export default {
    components: {
      ContentArea,
      LinkBlock,
      FAQ,
      ArticleList,
      PageLinkList,
    },
    methods: {
      setMetaTags() {
        document.title = this.model.name;
        document.querySelector('meta[property="og:title"]').setAttribute('content', this.model.name);
      },
    },
    mounted() {
      this.setMetaTags();
    },
    props: ['model'],
  };
</script>
