<template>
  <div class="flex flex-wrap -mx-1 mb-6">
    <h2 class="sr-only">{{$t('search.filter.selectedFilters')}}</h2>
    <!-- Selected filters -->
    <div v-if="selectedFilters.length > 0 || query" class="flex flex-row flex-wrap">
      <button
        v-if="query"
        @click.prevent="resetQuery"
        type="button"
        :title="$t('search.filter.removeSearchWordFromFilter')"
        class="flex items-center justify-between px-3 py-1 text-sm no-underline border border-dashed border-gray-200 rounded-3xl hover:bg-pinklight-300 hover:underline m-1"
      >
        <span>"<span v-text="query"></span>"</span>
        <font-awesome-icon icon="times" class="ml-2"/>
      </button>
      <button
        v-for="(filter, index) in selectedFilters"
        :key="$uuid(index)"
        @click="removeFilterItem(filter)"
        type="button"
        :title="$t('search.filter.removeFromFilter')"
        class="flex items-center justify-between px-3 py-1 text-sm no-underline border border-dashed border-gray-200 rounded-3xl hover:bg-pinklight-300 hover:underline m-1"
      >
        <span>{{getFilterName(filter.filterId, filter.filterKey)}}</span>
        <font-awesome-icon icon="times" class="ml-2"/>
      </button>
      <div v-if="selectedFilters.length > 0">
           <button
              @click="clearAll"
              type="button"
              :title="$t('search.filter.removeSearchWordFromFilter')"
              class="flex items-center justify-between px-3 py-1 text-sm no-underline hover:underline m-1">
            <span v-text="$t('search.filter.removeAllFilters')"></span> <!-- lokaliser denne -->
          </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Search Selected Filter Buttons',
  props: {
    filters: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['getSearchQuery', 'getFilterInfo', 'getData']),
    query() {
      return this.getSearchQuery;
    },
    selectedFilters() {
      return this.getFilterInfo;
    },
  },
  methods: {
    ...mapMutations(['setQuery', 'unsetFilter', 'setPageNum', 'clearAll']),
    ...mapActions(['performSearch']),
    resetQuery() {
      this.setPageNum(1);
      this.setQuery();
      this.performSearch();
    },
    removeFilterItem(filter) {
      this.setPageNum(1);
      this.unsetFilter(filter);
      this.performSearch();
    },
    clearFilter() {
        this.clearAll();
      },
    getFilterName(id, key) {
      if (key === 'Year') {
        return id;
      }
      const allFilters = this.getData.searchFilter;
      let filterName = '';
      allFilters.forEach((filter) => {
        if (filter.filterKey.toLowerCase().trim() === key.toLowerCase().trim() || key === 'SubFilter') {
          filter.filterItems.forEach((filterItem) => {
            if (filterItem.filterId.toString().toLowerCase().trim() === id.toString().toLowerCase().trim()) {
              filterName = filterItem.filterName;
            }
            if (filterItem.subItems) {
              filterItem.subItems.forEach((subitem) => {
                if (subitem.filterId === id) {
              filterName = subitem.filterName;
            }
              });
            }
          });
        }
      });
      return filterName;
    },
  },
};
</script>
