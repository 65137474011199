<template>
    <form action="/en/sok" @submit.prevent="submitQuery">
        <label for="search" class="sr-only">{{label}}</label>
        <div class="relative">
            <input v-model="query"
                   type="search"
                   :placeholder="placeholder"
                   class="input--inlinebutton__right"
                   id="search" />
            <button type="submit" class="absolute text-base p-2 top-0.5 right-1 text-text hover:text-red-200" :title="$t('search.searchBar.description')">
                <span class="sr-only">{{$t("search.searchBar.text")}}</span>
                <font-awesome-icon icon="search" />
            </button>
        </div>
    </form>
</template>

<script>
    import { mapMutations, mapGetters, mapActions } from 'vuex';

    export default {
        name: 'Search Form',
        props: {
            label: {
                type: String,
            },
            placeholder: {
                type: String,
            },
        },
        data() {
            return {
                query: '',
            };
        },
        methods: {
            ...mapMutations(['setQuery']),
            ...mapActions(['performSearch']),
            ...mapGetters(['getSearchQuery']),

            submitQuery() {
                this.setQuery(this.query);
                this.performSearch();
            },

            getQuery() {
                this.query = this.getSearchQuery();
            },
        },
        mounted() {
            this.getQuery();
        },
    };
</script>
