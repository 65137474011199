<template>
  <div class="flex flex-col lg:flex-row lg:justify-end items-center -mr-2">
    <button
      @click.prevent="toggleSort('published')"
      :class="this.sort === 'published' ? 'font-medium lg:border-b border-red-200' : 'font-normal'"
      class="w-full lg:w-auto mb-4 block border border-gray-400 bg-white p-2 lg:p-0 lg:bg-transparent lg:border-0 lg:m-2 lg:text-sm text-text lg:hover:text-red-200"
    >
      {{$t("search.sort.newestFirst")}}
    </button>

    <span class="hidden lg:inline-block -mt-1">|</span>

    <button
      @click.prevent="toggleSort('modified')"
      :class="this.sort === 'modified' ? 'font-medium lg:border-b border-red-200' : 'font-normal'"
      class="w-full lg:w-auto block border border-gray-400 bg-white p-2 lg:p-0 lg:bg-transparent lg:border-0 lg:m-2 lg:text-sm text-text lg:hover:text-red-200"
    >
      {{$t("search.sort.lastUpdated")}}
    </button>

    <span class="hidden lg:inline-block -mt-1">|</span>

    <button
      @click.prevent="toggleSort('alphabetically')"
      :class="this.sort === 'alphabetically' ? 'font-medium lg:border-b border-red-200' : 'font-normal'"
      class="w-full lg:w-auto block border border-gray-400 bg-white p-2 lg:p-0 lg:bg-transparent lg:border-0 lg:m-2 lg:text-sm text-text lg:hover:text-red-200"
    >
      {{$t("search.sort.alphabetically")}}
    </button>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';

export default {
  name: 'Search Sort Buttons',
  data() {
    return {
      sort: '',
    };
  },
  methods: {
    ...mapGetters(['getSort']),
    ...mapActions(['performSearch']),
    getSortFromStore() {
      this.sort = this.getSort();
    },

    ...mapMutations(['setSort', 'setPageNum']),
    toggleSort(value) {
      this.sort = value;
      this.setSort(value);
      this.setPageNum(1);
      this.$store.dispatch('performSearch');
    },
  },
  mounted() {
    this.getSortFromStore();
  },
};
</script>
