<template>
  <div
    class="
      border border-gray-200
      lg:max-h-screen-3/4
      overflow-y-auto
      customScrollBar
    "
  >
    <div class="pt-6 px-6 pb-3 bg-white sticky top-0">
      <font-awesome-icon
        icon="sort-alpha-down"
        class="inline-block mr-2 text-lg text-pink-200"
      />
      <p class="inline-block text-sm font-semibold uppercase">
        {{$t('article.tableOfContent')}}
      </p>
    </div>
    <ul class="px-6 pb-6">
      <li
        v-for="(heading, index) in headings"
        :key="heading.id"
        :class="index > 0 ? 'py-3' : 'pb-3'"
        class="border-b border-gray-100 text-base"
      >
        <button
          class="btn--text text-left cursor-pointer hover:underline"
          @click="scrollIntoView(heading.id)"
        >
          {{ heading.text }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    target: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      headings: [],
    };
  },
  methods: {
    findAllHeadings() {
      const htmlElement = document.querySelector(this.target);
      const elementHeadings = htmlElement.querySelectorAll('h2');

      const targetHeadings = [];
      for (let i = 0; i < elementHeadings.length; i += 1) {
          let headingID = elementHeadings[i].getAttribute('id');
          if (!headingID) {
              headingID = this.$uuid(i);
          }
        const headingModel = {
          text: elementHeadings[i].innerText,
          id: headingID,
          element: elementHeadings[i],
        };
        targetHeadings.push(headingModel);
        elementHeadings[i].setAttribute('id', headingID);
      }

      this.headings = targetHeadings;
    },
    scrollIntoView(id) {
      const targetElement = this.headings.find((x) => x.id === id);
      if (targetElement !== null) {
        targetElement.element.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
  mounted() {
    this.findAllHeadings();
  },
};
</script>
