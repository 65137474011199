<template>
  <div class="container mb-8">
    <div class="block lg:flex">
      <aside class="w-full pr-0 mb-4 lg:w-1/4 lg:pl-4">
      </aside>
      <div class="w-full pr-0 lg:w-2/4 lg:pr-4">
        <div v-if="inEditMode">
          <EpiProperty propertyName="DefinitionReference" buttonText="Hvilken skjemamal skal brukes?"/>
          <EpiProperty propertyName="SchemasDestination" buttonText="Hvor skal skjema opprettes?"/>
        </div>
        <div class="my-2" v-if="model.heading || inEditMode" v-epi-edit="'Heading'">
          <h1 class="font-serif font-semibold text-hmd">{{ model.heading }}</h1>
        </div>
        <div class="my-2" v-if="model.subHeading || inEditMode" v-epi-edit="'SubHeading'">
          <p class="text-lg">{{ model.subHeading }}</p>
        </div>
        <div class="my-8 rounded bg-bluegray-300 article-small" v-if="model.mainBody || inEditMode" v-epi-edit="'MainBody'">
          <div v-if="model.mainBody" v-html="model.mainBody" class="p-4"></div>
        </div>
        <div class="my-2" v-if="model.formHeading || inEditMode || !isSubmitted" v-epi-edit="'FormHeading'">
          <p class="text-lg">{{ model.formHeading }}</p>
        </div>
        <form @submit.prevent="create">
          <div class="mt-2 mb-2">
            <label for="title" class="text-sm">{{ $t('schema.introPage.methodEvaluationTitle.label') }}:</label>
            <input v-if="!isSubmitted"
                   id="title"
                   type="text"
                   v-model="title"
                   :placeholder="assessmentType === '1' ? $t('schema.introPage.methodEvaluationTitle.placeholder') : ''"
                   required/>
            <input v-else
                   type="text"
                   v-model="title"
                   disabled/>
          </div>

          <!-------- Spesialisthelsetjenesten -------->
          <div class="mt-2 mb-2" v-if="assessmentType === '1'">
            <label for="organization" class="text-sm">{{ $t('schema.introPage.organization.label') }}:</label>
            <select v-if="!isSubmitted"
                    class="p-4 bg-transparent border-2"
                    v-model="organization">
              <option value="" disabled>{{ $t('schema.introPage.organization.optionDescription') }}</option>
              <option v-for="(option, index) in organizations" :key="$uuid(index)" :value="option.value">{{ option.text }}</option>
            </select>
            <select v-else
                    class="p-4 bg-transparent border-2"
                    v-model="organization"
                    disabled>
              <option v-for="(option, index) in organizations" :key="$uuid(index)" :value="option.value">{{ option.text }}</option>
            </select>
          </div>
          <!-------- End Spesialisthelsetjenesten -------->

          <!---------- Kommunehelsetjenesten ------------>
          <div class="mt-2 mb-2" v-if="assessmentType === '2'">
            <label class="text-sm">{{$t('schema.introPage.county_municipality')}}:</label>
            <div>
              <span v-if="countyError && !isSubmitted" style="color: red;">{{ countyError }}</span>
            </div>
            <select v-model="selectedCounty">
              <option v-for="(county, oindex) in organizations"
                      :key="$uuid(oindex)" :label="county.text"
                      :value="county.value">{{county.text}}</option>
            </select>
            <div v-if="selectedCounty !== null">
              <label class="text-sm">{{$t('schema.introPage.municipality.municipality')}}:</label>
              <select v-model="organization">
                <option v-for="(municipality, oindex) in getMunicipalities(selectedCounty)"
                        :key="$uuid(oindex)"
                        :label="municipality.text"
                        :value="municipality.value">{{municipality.text}}</option>
              </select>
            </div>
          </div>
          <!---------- End Kommunehelsetjenesten ------------>

          <!---------- Samhandling ------------>
          <div class="mt-2 mb-2" v-if="assessmentType === '3'">
            <!--Valg for helseforetak eller fylke/kommune-->
            <div class="flex flex-row gap-4 mb-4">
              <div>
                <label class="text-sm mr-2" for="organization">{{ $t('schema.introPage.organization.label') }}:</label>
                <input type="radio" value="organization" id="organization" name="actor_type" v-model="selectedActor" @click="resetActor">
              </div>
              <div>
                <label class="text-sm mr-2" for="county">{{ $t('schema.introPage.county_municipality') }}:</label>
                <input type="radio" value="county" id="county" name="actor_type" v-model="selectedActor" @click="resetActor">
              </div>
              <span v-if="actorError && !isSubmitted" style="color: red;">{{ actorError }}</span>
            </div>

            <!--Fylke/kommune-->
            <div v-if="selectedActor == 'county'">
              <div>
                <span v-if="countyError && !isSubmitted" style="color: red;">{{ countyError }}</span>
              </div>
              <label class="text-sm mt-4">{{ $t('schema.introPage.county_municipality') }}:</label>
              <select v-model="selectedCounty">
                <option v-for="(county, oindex) in organizations"
                        :key="$uuid(oindex)" :label="county.text"
                        :value="county.value">
                        {{ county.text }}
                </option>
              </select>
              <!--Municipality-->
              <div v-if="selectedCounty !== null">
                <label class="text-sm">{{ $t('schema.introPage.municipality.municipality') }}:</label>
                <select v-model="organization">
                  <option v-for="(municipality, oindex) in getMunicipalities(selectedCounty)"
                          :key="$uuid(oindex)"
                          :label="municipality.text"
                          :value="municipality.value">
                          {{ municipality.text }}
                  </option>
                </select>
              </div>
            </div>

            <!--Helseforetak-->
            <div v-if="selectedActor == 'organization'">
              <label class="text-sm mt-4">{{ $t('schema.introPage.organization.label') }}:</label>
              <select class="p-4 bg-transparent border-2"
                      v-model="organization">
                <option v-for="(option, index) in organizations2" :key="$uuid(index)" :value="option.value">{{ option.text }}</option>
              </select>
              <span v-if="organizationError && !isSubmitted" style="color: red;">{{ organizationError }}</span>
            </div>
          </div>
          <!---------- End Samhandling ------------>

          <div class="mt-2 mb-2">
            <label for="name" class="text-sm">{{$t('schema.introPage.name.label')}}:</label>
            <input v-if="!isSubmitted"
                   id="name"
                   type="text"
                   v-model="name"
                   :placeholder="$t('schema.introPage.name.placeholder')"
                   required/>
            <input v-else type="text"
                   v-model="name"
                   disabled/>
          </div>
          <div class="mt-2 mb-2">
            <label for="email" class="text-sm">{{$t('schema.introPage.email.label')}}:</label>
            <input v-if="!isSubmitted"
                   id="email"
                   type="email"
                   v-model="email"
                   :placeholder="$t('schema.introPage.email.placeholder')"
                   required/>
            <input v-else type="text"
                   v-model="email"
                   disabled/>
            <span v-if="emailError && !isSubmitted" style="color: red;">{{ emailError }}</span>
          </div>
          <div class="my-4 article-small"
               v-show="!isSubmitted"
               v-html="model.secondaryBody"
               v-if="model.secondaryBody || inEditMode"
               v-epi-edit="'SecondaryBody'"/>
          <div class="bg-green-100 rounded py-4 px-4 opacity-80 article-small"
               v-show="isSubmitted || inEditMode"
               v-html="model.sentInMessage"
               v-if="model.sentInMessage || inEditMode"
               v-epi-edit="'SentInMessage'"/>

          <!--Submit button-->
          <div v-show="!isSubmitted" class="flex justify-end">
            <button type="submit" class="btn btn--large btn--charcoalgray">
              <font-awesome-icon icon="arrow-right"/>
              {{ $t('schema.introPage.continue.text') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import EpiProperty from '@/components/EpiProperty.vue';

export default {
  props: ['model'],
  components: {
    EpiProperty,
  },
  computed: mapState({
    inEditMode: (state) => state.epiContext.inEditMode,
  }),
  data() {
    return {
      none: '',
      title: '',
      email: '',
      valid: false,
      actorError: '',
      countyError: '',
      emailError: null,
      organizationError: null,
      name: '',
      organization: this.$t('schema.introPage.organization.optionDescription'),
      organizations: this.model.organizations,
      organizations2: this.model.organizations2,
      assessmentType: this.model.assessmentType,
      id: this.model.contentLink.id,
      isSubmitted: false,
      selectedCounty: null,
      selectedActor: '',
    };
  },
  mounted() {
    this.setMetaTags();
  },
  methods: {
    resetActor() {
      this.selectedCounty = null;
      this.organization = '';
    },
    validate() {
      this.validateActor();
      this.validateEmail();
      this.validateCounty();
      this.validateOrganization();

      // Spesialist
      if (this.assessmentType === '1') {
        if (!this.emailError && !this.organizationError) {
          this.valid = true;
        }
      }
      // Kommune
      if (this.assessmentType === '2') {
        if (!this.emailError && !this.countyError) {
          this.valid = true;
        }
      }
      // Samhandling
      if (this.assessmentType === '3') {
        if (this.selectedActor === 'county' && this.selectedActor && !this.emailError && !this.countyError) {
          this.valid = true;
        } else if (this.selectedActor === 'organization' && !this.emailError && !this.organizationError) {
          this.valid = true;
        }
      }
    },
    validateOrganization() {
      if (!this.organization || this.organization === this.$t('schema.introPage.organization.optionDescription')) {
        this.organizationError = 'Du må velge et helseforetak';
      } else {
        this.organizationError = '';
      }
    },
    validateActor() {
      if (!this.selectedActor) {
        this.actorError = 'Du må velge en aktør';
      } else {
        this.actorError = '';
      }
    },
    validateCounty() {
      if (this.selectedCounty && this.organization && this.organization !== this.$t('schema.introPage.organization.optionDescription')) {
        this.countyError = '';
      } else {
        this.countyError = 'Du må velge fylke og kommune';
      }
    },
    validateEmail() {
      // Validate email
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (this.email === '') {
        this.emailError = 'E-postaddresse er påkrevd';
      } else if (!emailPattern.test(this.email)) {
        this.emailError = 'Du må skrive inn en gyldig e-postadresse';
      } else {
        this.emailError = null;
      }
    },
    setMetaTags() {
      document.title = this.model.name;
      document.querySelector('meta[property="og:title"]').setAttribute('content', this.model.name);
    },
    getMunicipalities(county) {
      const result = this.organizations.filter((item) => item.value === county);
      if (result !== null) {
        return result[0].subItems;
      }
      return [];
    },
    create() {
      this.validate();
      if (this.valid) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.id,
            name: this.name,
            title: this.title,
            email: this.email,
            organizationId: this.organization,
            assessmentType: this.assessmentType,
            actor: this.selectedActor,
          }),
        };
        this.isSubmitted = true;
        fetch(`${window.location.origin}/api/schema/CreateMethodAssessment`, requestOptions)
          .then((response) => response.json())
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.isSubmitted = false;
      }
    },
  },
};
</script>
