<template>
  <div v-if="result.length === 0" class="bg-graylight-200 p-16">
    <div v-if="searchSettings.noHitsHeader" class="text-xl text-center font-semibold mb-6">
      {{searchSettings.noHitsHeader}}
    </div>
    <div v-else class="text-xl text-center font-semibold mb-6">
      {{$t("search.results.noHits")}}
    </div>
    <div v-if="searchSettings.noHitsMessage !== null" v-html="searchSettings.noHitsMessage" class="text-center xhtml-string" />
    <div v-else class="text-center">
      {{$t("search.results.noHitsMessage")}}
    </div>
  </div>
  <ul class="mb-6">
    <li
      v-for="(item, index) in result"
      :key="$uuid(index)"
    >
      <SearchResultItem :type="item.source ? item.source : type"
                        :item="item"
                        :key="$uuid(index)"
                        :isEditor="isEditor" />
    </li>
  </ul>
</template>

<script>
  import SearchResultItem from '@/views/components/search/SearchResultItem.vue';

  export default {
    name: 'Search Result - List',
    components: {
      SearchResultItem,
    },
    data() {
      return {
        result: this.results,
      };
    },
    props: {
      results: {
        type: Object,
      },
      type: {
        type: String,
      },
      target: {
        type: String,
      },
      searchSettings: {
        type: Object,
      },
      isEditor: {
        type: Boolean,
        required: true,
      },
    },
  };
</script>
