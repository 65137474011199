<template>
  <div>
    <div class="flex items-start">
      <p class="mb-1 text-sm text-blue">{{getDateTime}} &ndash; {{model.username}}</p>
      <button
        @click.prevent="deleteComment()"
        class="ml-2 text-xs text-bluegray-400 hover:text-red-300"
        :title="$t('schema.common.action.deleteComment')"
      >
        <span class="sr-only"> {{$t('schema.common.action.deleteComment')}} </span>
        <font-awesome-icon icon="trash" />
      </button>
      <button
        @click.prevent="editComment()"
        class="ml-2 text-xs text-bluegray-400 hover:text-red-300"
        :title="$t('schema.common.action.editComment')"
      >
        <span class="sr-only"> {{$t('schema.common.action.editComment')}} </span>
        <font-awesome-icon icon="edit" />
      </button>
    </div>
    <p v-if="!isEditing" class="text-base text-text">
      {{ model.comment }}
    </p>
    <div v-else>
      <div class="mb-7" :style="`height: ${textAreaHeight}px`">
        <textarea
        v-model="commentText"
        class="h-full overflow-hidden resize-none nomargin"
        ref="commentTextArea"
        @input="setTextAreaHeightOnInput"/>
      </div>
          <SchemaError
            v-if="savingError.error"
            :message="savingError.message"
            :error="`${savingError.status ? savingError.status : ''}  ${savingError.text ? savingError.text : ''}`"
          />
      <div class="flex justify-end">
            <button
              @click.prevent="cancelEdit"
              class="btn btn--link cursor-pointer"
              v-if="!savingChanges"
            >
              <span>{{$t('schema.common.action.cancel')}}</span>
            </button>
            <button
              @click.prevent="saveChanges"
              :class="commentChanged ? 'btn--bluedark cursor-pointer' : 'bg-gray-100 cursor-default'"
              :disabled="!commentChanged"
              class="btn"
            >
              <font-awesome-icon
                class="text-sm text-white animate-spin mr-2"
                icon="spinner"
                v-if="savingChanges"
              />
              <span v-if="!savingChanges">{{$t('schema.common.action.saveChanges')}}</span>
              <span v-else> {{$t('schema.common.action.savingChanges')}}</span>
            </button>
          </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Schema Comment',
  emits: ['saveChanges', 'editComment', 'deleteComment'],
  props: {
    model: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      commentText: '',
      savingChanges: false,
      savingError: {
        text: null,
        status: null,
      },
      textAreaHeight: 50,
    };
  },
  computed: {
    getDateTime() {
      const date = new Date(this.model.date);
      return date?.toLocaleString('no-NO');
    },
    commentChanged() {
      return this.commentText !== this.model.comment && this.commentText !== '';
    },
  },
  methods: {
    deleteComment() {
      // eslint-disable-next-line
      const confirmDelete = window.confirm(this.$t('schema.common.action.comment.confirmDelete'));
      if (confirmDelete) {
        this.$emit('deleteComment', { commentId: this.model.commentId });
      }
    },
    editComment() {
      if (this.isEditing) {
        this.cancelEdit();
        return;
      }
      this.commentText = this.model.comment;
      this.$emit('editComment', { commentId: this.model.commentId, isEditing: true });
      this.$nextTick(() => { this.setTextAreaHeight(this.$refs.commentTextArea); });
    },
    saveChanges() {
      if (this.savingChanges) {
        return;
      }
      this.savingChanges = true;
      this.$emit('saveChanges', { commentId: this.model.commentId, comment: this.commentText, callback: this.saveResponse });
    },
    saveResponse(savingError) {
      this.savingError = savingError;
      this.savingChanges = false;
    },
    cancelEdit() {
      if (this.savingChanges) {
        return;
      }

      let confirmCancel = false;
      if (this.commentChanged) {
        // eslint-disable-next-line
        confirmCancel = window.confirm(this.$t('schema.common.action.comment.userHasChanges'));
        if (confirmCancel) {
          this.$emit('editComment', { commentId: this.model.commentId, isEditing: false });
        }
      } else {
          this.$emit('editComment', { commentId: this.model.commentId, isEditing: false });
      }
    },
    setTextAreaHeightOnInput(event) {
      this.setTextAreaHeight(event.target);
    },
    setTextAreaHeight(target) {
      if (target.scrollHeight > this.textAreaHeight) {
        this.textAreaHeight = target.scrollHeight;
      }
    },
  },
};
</script>
