<template>
  <button v-if="url" class="flex items-center text-sm cursor-pointer text-lightblue-300 group">
    <a :href="url" target="_blank">
      <font-awesome-icon class="mr-2" icon="eye" />
      <span class="text-left underline group-hover:no-underline">
        {{$t('schema.schemaPage.preview')}}
      </span>
    </a>
  </button>
</template>

<script>

  export default {
    props: {
      url: {
        type: String,
        required: true,
      },
    },
  };

</script>
