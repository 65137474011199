<template>
<!--Read only mode-->
  <div v-if="readOnly">

    <!--Top section, menu item in left menu-->
    <div v-if="model.showInLeftMenu" class="flex items-center pb-3 mb-6 border-b border-gray-100" :class="preview ? 'w-full' : 'w-3/4'">
      <h2 class="font-serif font-semibold text-hmd">{{model.title}}
        <Help v-if="model.helpText" v-show="model.showHelpText" :model="model.helpText" />
      </h2>
    </div>

    <div v-else class="flex flex-row mb-6" :class="preview ? 'w-full' : 'w-3/4'">
      <!--Section within section-->
      <h4 v-if="model.isSecondLevelSection" class="text-lg">{{model.title}}
        <Help v-if="model.helpText" v-show="model.showHelpText" :model="model.helpText" />
      </h4>

      <!--Top section, no menu item in left menu-->
      <h3 v-else class="text-xl font-medium">{{model.title}}
        <Help v-if="model.helpText" v-show="model.showHelpText" :model="model.helpText" />
      </h3>
    </div>
  </div>

<!--Edit mode-->
  <div v-else>

    <!--Top section, menu item in left menu-->
    <div v-if="model.showInLeftMenu" class="flex items-center w-full pb-3 mb-6 border-b border-gray-100 xl:w-3/4">
      <h2 class="font-serif font-semibold text-hmd">{{model.title}}
        <Help v-if="model.helpText" v-show="model.showHelpText" :model="model.helpText" />
      </h2>
    </div>
    <div v-else class="flex flex-row w-full mb-6 xl:w-3/4">
      <!--Section within section-->
      <h4 v-if="model.isSecondLevelSection" class="text-lg">{{model.title}}
        <Help v-if="model.helpText" v-show="model.showHelpText" :model="model.helpText" />
      </h4>

      <!--Top section, no menu item in left menu-->
      <h3 v-else class="text-xl font-medium">{{model.title}}
        <Help v-if="model.helpText" v-show="model.showHelpText" :model="model.helpText" />
      </h3>
    </div>
  </div>
</template>

<script>
  import Help from '@/views/components/help/Help.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      Help,
    },
  };
</script>
