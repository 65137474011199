<template>
<FocusTrap
:active="false"
:initial-focus="() => $refs.closePopup"
:escapeDeactivates="true"
@deactivate="closePopup()"
ref="focusTrapPopup">
<div class="fixed w-full h-full top-0 left-0 flex items-center justify-center bg-gray-400 bg-opacity-50">
<div class=" mx-auto z-30">
  <div v-click-outside="closePopup" class=" w-full max-w-3xl border-2 rounded-md my-4 p-9 flex flex-col  bg-white">
    <div class="relative">
        <button class="inline-block absolute top-0 right-0" :title="$t('window.close.description.popup')" ref="closePopup" @click="closePopup"><font-awesome-icon icon="times" /></button>
    </div>
    <h1 class=" font-bold text-lg flex-row w-full pb-9">{{$t('methodNotificationPopup.notifyOnChangesForTopic', { topic: topic })}}</h1>
    <div class="justify-items-start flex-row w-full">
        <h2 class=" w-full pb-4">{{$t('methodNotificationPopup.notificationFrequency.header')}}</h2>
        <div class="flex flex-col justify-items-start">
              <div class="inline-flex items-center pb-5">
                <CustomRadioButton
                  :radioName="'notificationFrequence'"
                  :radioId="'nf1'"
                  :radioLabel="$t('methodNotificationPopup.notificationFrequency.options.rightAway')"
                  :radioValue="'instantly'"
                  v-model="notificationFrequency"
                />
                <!-- <input name="notificationFrequence"
                id="nf1" class="custom--inputbox__radio" type="radio">
                <label for="nf1" class="mb-0">{{$t('methodNotificationPopup.notificationFrequency.options.rightAway')}}</label> -->
              </div>
             <div class="inline-flex items-center pb-5">
                <CustomRadioButton
                  :radioName="'notificationFrequence'"
                  :radioId="'nf2'"
                  :radioLabel="$t('methodNotificationPopup.notificationFrequency.options.everyDay')"
                  :radioValue="'daily'"
                  v-model="notificationFrequency"
                />
                <!-- <input name="notificationFrequence" id="nf2" class="custom--inputbox__radio" type="radio">
                <label for="nf2" class="mb-0">{{$t('methodNotificationPopup.notificationFrequency.options.everyDay')}}</label> -->
             </div>
             <div class="inline-flex items-center pb-5">
               <CustomRadioButton
                  :radioName="'notificationFrequence'"
                  :radioId="'nf3'"
                  :radioLabel="$t('methodNotificationPopup.notificationFrequency.options.everyWeek')"
                  :radioValue="'weekly'"
                  v-model="notificationFrequency"
                />
                <!-- <input name="notificationFrequence" id="nf3" class="custom--inputbox__radio" type="radio">
                <label for="nf3" class="mb-0">{{$t('methodNotificationPopup.notificationFrequency.options.everyWeek')}}</label> -->
             </div>
        </div>
    </div>
    <div class="pb-6">
        <p class="pb-2">{{$t('methodNotificationPopup.email.yourEmail')}}</p>
        <input type="text"/>
    </div>
    <div>
        <p class="pb-2">{{$t('methodNotificationPopup.email.usageDescription')}}</p>
        <a href="" class="underline to-blue pb-2" :title="$t('navigationUtils.navigateToPrivacyDeclaration')">{{$t('methodNotificationPopup.aboutPrivacy')}}</a>
    </div>
    <div class="flex justify-end">
    <button class="btn btn--secondary" @click="closePopup">{{$t('methodNotificationPopup.saveNotification')}}</button>
    </div>
  </div>
</div>
</div>
</FocusTrap>
</template>

<script>
  import vClickOutside from 'click-outside-vue3';
import { FocusTrap } from 'focus-trap-vue';

  export default {
    data() {
      return {
        notificationFrequency: '',
      };
    },
    components: {
      FocusTrap,
    },
    props: {
        topic: {
            type: String,
            required: true,
        },
    },
    directives: {
      clickOutside: vClickOutside.directive,
    },
    methods: {
      closePopup() {
        this.$refs.focusTrapPopup.deactivate();
        this.$emit('close');
      },
    },
    mounted() {
      this.$refs.focusTrapPopup.activate();
    },
  };
</script>
