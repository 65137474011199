<template>
  <div class="w-full py-5">
      <div class="flex items-center justify-between">
        <h3
          @click="open = !open"
          class="text-lg cursor-pointer hover:underline"
        >
          {{ model.question }}
        </h3>
        <button
          @click="open = !open"
          class="cursor-pointer text-hsm"
          title="Trykk for å vise svaret"
          :aria-controls="`faq-${id}`"
          :aria-expanded="open ? true : false"
        >
          <span class="sr-only">Vis svaret</span>
          <font-awesome-icon
            icon="angle-down"
            :class="open ? 'rotate-180' : 'rotate-0'"
            class="transition-transform duration-500 transform "
          />
        </button>
      </div>
      <div
        :class="open ? 'max-h-screen mt-5' : 'max-h-0'"
        class="overflow-hidden duration-500 transition-max-height"
        :aria-hidden="open ? false : true"
        :id="`faq-${id}`"
      >
        <p class="text-base">{{model.answer}}</p>
      </div>
  </div>
</template>

<script>
  export default {
    props: {
      model: {
        type: Object,
      },
    },
    data() {
      return {
        open: false,
      };
    },
    computed: {
      id() {
        return this.$uuid();
      },
    },
  };
</script>
