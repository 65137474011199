<template>
  <button
    :title="menuopen ? $t('header.menu.button.description.closeMenu') : $t('header.menu.button.description.openMenu')"
    class="flex items-center justify-center text-xl group"
    type="button"
    id="hovedmeny"
  >
    <div
      v-text="menuopen ? $t('header.menu.button.text.close') : $t('header.menu.button.text.menu')"
      class="hidden text-xl no-underline md:inline-block md:ml-1 md:w-16 group-hover:underline"
    >
    </div>
    <div class="flex items-center justify-center w-12 h-12 -my-1 text-black bg-red-100 rounded-full shadow-md goup-hover:shadow-sm group-hover:bg-gray-100">
      <font-awesome-icon :icon="menuopen ? 'times' : 'bars'"/>
    </div>
  </button>
</template>

<script>
export default {
  name: 'Header Menu Button',
  props: {
    menuopen: {
      Type: Boolean,
      default: false,
    },
  },
};
</script>
