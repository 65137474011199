<template>
  <div class="container lg:mt-20 flex flex-col lg:flex-row">
    <!-- Mobile filterpane -->
    <transition name="slideFromRight">
      <section v-if="showFilterPane" class="overflow-x-scroll flex flex-col absolute inset-0 p-8 z-50 bg-bluegray-200 lg:hidden">
        <div class="flex justify-end mb-8 relative">
          <button @click="showFilterPane = !showFilterPane"
                  class="flex items-center text-xl group"
                  :title="$t('window.close.description.filter')">
            <span class="group-hover:underline">{{$t("window.close.text")}}</span>
            <span class="flex items-center justify-center w-10 h-10 ml-2 border border-gray-400 rounded-full group-hover:bg-white group-hover:bg-opacity-80">
              <font-awesome-icon class="text-lg align-middle" icon="times" />
            </span>
          </button>
        </div>
        <div>
          <SearchSelectedStateFilter class="order-last sm:flex-row flex flex-col w-full sm:w-auto"
                                     v-model:filterByCompleted="filterByCompleted"
                                     v-model:filterByStarted="filterByStarted"
                                     @filterChanged="updateFilterByState" />
          <SearchFilterGroup v-for="(group, index) in data.searchFilter"
                             :key="`${group.filterKey}_${index}`"
                             :filter="group"
                             :isRadio="group.isRadio"
                             :isMobile="true"/>
          <SearchCollaborationFilter v-if="data.displayFilterByCollaboration" class="order-last sm:flex-row flex flex-col w-full sm:w-auto"
                                     v-model:filterByCollaboration="filterByCollaboration"
                                     @typeFilterChanged="updateFilterByType" />
        </div>
        <div class="mt-auto bg-bluegray-300 w-full">
          <button @click.prevent="showFilterPane = !showFilterPane"
                  class="mt-auto btn btn--bluedark w-full"
                  :title="$t('search.results.showSearchResults.description')">
            {{searchResultText(data.totalMatching) }}
          </button>
        </div>
      </section>
    </transition>

    <transition name="slideFromLeft">
      <section v-if="showSortPane" class="flex flex-col absolute inset-0 p-8 z-50 bg-bluegray-200 lg:hidden">
        <div class="flex justify-end mb-8 relative">
          <button @click="showSortPane = !showSortPane"
                  class="flex items-center text-xl group"
                  :title="$t('window.close.description.sorting')">
            <span class="group-hover:underline">{{$t("window.close.text")}}</span>
            <span class="flex items-center justify-center w-10 h-10 ml-2 border border-gray-400 rounded-full group-hover:bg-white group-hover:bg-opacity-80">
              <font-awesome-icon class="text-lg align-middle" icon="times" />
            </span>
          </button>
        </div>
        <SearchSort :key="$uuid()" />
        <button @click.prevent="showSortPane = !showSortPane"
                class="mt-auto btn btn--bluedark w-full"
                :title="$t('search.results.showSearchResults.description')">
          {{searchResultText(data.totalMatching) }}
        </button>
      </section>
    </transition>

    <div class="lg:pr-12 w-full lg:w-1/4">
      <!-- Desktop filterpane -->
      <section v-if="data" class="hidden lg:block">
        <SearchSelectedStateFilter class="flex flex-col 2xl:block md:order-last order-first  w-full"
                                   v-model:filterByCompleted="filterByCompleted"
                                   v-model:filterByStarted="filterByStarted"
                                   @filterChanged="updateFilterByState" />
        <SearchFilterGroup v-for="(group, index) in data.searchFilter"
                           :key="`${group.filterKey}_${index}`"
                           :filter="group"
                           :isRadio="group.isRadio"
                           :isMobile="false"/>
        <SearchCollaborationFilter v-if="data.displayFilterByCollaboration" class="flex flex-col 2xl:block md:order-last order-first  w-full"
                                   v-model:filterByCollaboration="filterByCollaboration"
                                   @typeFilterChanged="updateFilterByType" />
      </section>
      <div v-if="showPopup">
        <PopupTopic @close="closePopup" />
      </div>
    </div>

    <!--Heading-->
    <div class="w-full lg:w-3/4">
      <div class="flex flex-col lg:flex-row">
        <h1 class="font-serif font-semibold text-hlg mb-7 w-full lg:w-3/4">
          {{ model.heading ?? model.name }}
        </h1>

        <!--Search Help text-->
        <div v-if="this.model.searchInfoText" class="w-full text-left mb-6 lg:mb-0 lg:w-1/4 lg:text-right">
          <button @click="toggleSearchTips(true)">
            <u class="mr-1">{{$t('search.searchtips')}}</u><font-awesome-icon icon="question-circle" />
          </button>
          <HelpPopUp @close="toggleSearchTips(false)" :text="model.searchInfoText" v-if="showTips" />
        </div>
      </div>

      <SearchForm v-if="data" :key="$uuid()" class="md:order-first order-last w-full" :label="$t('search.searchBar.label.assessment')" :placeholder="$t('search.searchBar.placeholder.assessment')" />

      <section class="lg:hidden flex flex-row justify-between mb-5">
        <button @click="showSortPane = !showSortPane"
                class="text-base flex items-center hover:underline"
                type="button"
                :title="$t('search.sort.sorting.description')">
          <font-awesome-icon icon="sort-amount-up" class="mr-2" />
          {{$t("search.sort.sorting.text")}}
        </button>

        <button @click="showFilterPane = !showFilterPane"
                class="text-base flex items-center hover:underline"
                type="button"
                :title="$t('search.filter.showFilters.description')">
          <font-awesome-icon icon="sliders-h" class="mr-2" />
          {{$t('search.filter.showFilters.text') + ' (' + getFilterCount + ')'}}
        </button>
      </section>
      <SearchSelectedFilterButtons v-if="data" :filters="data.searchFilter" />

      <!-- Paging and sort desktop -->
      <SearchPaging :showSort="true" />

      <!-- Results -->
      <Loading v-if="!data" class="mb-10" />
      <SearchResultList v-if="data"
                        :results="data.searchResult"
                        type='methodassessment'
                        :searchSettings="model.searchSettings"
                        :key="$uuid()"
                        :isEditor="model.isEditor" />
      <SearchPaging :showSort="false" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import SearchForm from '@/views/components/search/SearchForm.vue';
  import SearchFilterGroup from '@/views/components/search/SearchFilterGroup.vue';
  import SearchSort from '@/views/components/search/SearchSort.vue';
  import SearchSelectedFilterButtons from '@/views/components/search/SearchSelectedFilterButtons.vue';
  import SearchSelectedStateFilter from '@/views/components/search/SearchSelectedStateFilter.vue';
  import SearchCollaborationFilter from '@/views/components/search/SearchCollaborationFilter.vue';
  import SearchResultList from '@/views/components/search/SearchResultList.vue';
  import PopupTopic from '@/views/components/popup/popupTopic.vue';
  import Loading from '@/views/components/Loading.vue';
  import HelpPopUp from '@/views/components/help/HelpPopUp.vue';
  import SearchPaging from '@/views/components/search/SearchPaging.vue';

  export default {
    name: 'Search',
    props: {
      model: {
        type: Object,
      },
    },
    components: {
      HelpPopUp,
      PopupTopic,
      SearchForm,
      SearchSelectedFilterButtons,
      SearchSelectedStateFilter,
      SearchCollaborationFilter,
      SearchResultList,
      SearchFilterGroup,
      SearchSort,
      Loading,
      SearchPaging,
    },
    data() {
      return {
        showPopup: false,
        showFilterPane: false,
        showSortPane: false,
        filterByCompleted: false,
        filterByStarted: false,
        displayFilterByCollaboration: false,
        filterByCollaboration: false,
        showTips: false,
      };
    },
    computed: {
      ...mapGetters({
        data: 'getData',
        pageNum: 'getPageNum',
        pageSize: 'getPageSize',
        getFilterByCompleted: 'getFilterByCompleted',
        getFilterByStarted: 'getFilterByStarted',
        getCollaborationFilter: 'getFilterByCollaboration',
      }),
      getFilterCount() {
        if (this.data) {
          return this.data.searchFilter.length;
        }
        return 0;
      },
    },
    methods: {
      ...mapActions(['performSearch']),
      ...mapMutations(['setFilterByCompleted', 'setFilterByStarted', 'setDisplayFilterByCollaboration', 'setFilterByCollaboration', 'setDefaultAssessmentType']),

      fetchProducts() {
        this.$store.dispatch('performSearch');
      },
      updateFilterByState() {
        this.setFilterByCompleted(this.filterByCompleted);
        this.setFilterByStarted(this.filterByStarted);
        this.fetchProducts();
      },
      updateFilterByType() {
        this.setFilterByCollaboration(this.filterByCollaboration);
        this.fetchProducts();
      },
      updateDisplayFilter() {
        this.setDisplayFilterByCollaboration = this.displayFilterByCollaboration;
      },
      searchResultText(resultCount) {
        if (resultCount <= 1) {
          return this.$tc('search.results.showSearchResults.text', 0, { count: resultCount });
        }
        return this.$tc('search.results.showSearchResults.text', 1, { count: resultCount });
      },
      toggleSearchTips(value) {
        this.showTips = value;
      },
      setMetaTags() {
        document.title = this.model.name;
        document.querySelector('meta[property="og:title"]').setAttribute('content', this.model.name);
      },
    },
    watch: {
      filterIds(newVal) {
        this.filterIds = newVal;
      },
    },
    mounted() {
      this.filterByStarted = this.getFilterByStarted;
      this.filterByCompleted = this.getFilterByCompleted;
      this.filterByCollaboration = this.getFilterByCollaboration;
      this.displayFilterByCollaboration = this.getDisplayFilterByCollaboration;
      this.fetchProducts();
      this.setMetaTags();
    },
  };
</script>

<style scoped>
  .slideFromRight-enter-active,
  .slideFromRight-leave-active {
    transition: transform 0.5s ease;
    transform: translateX(0);
  }

  .slideFromRight-enter-from,
  .slideFromRight-leave-to {
    transform: translateX(100%);
  }

  .slideFromLeft-enter-active,
  .slideFromLeft-leave-active {
    transition: transform 0.5s ease;
    transform: translateX(0);
  }

  .slideFromLeft-enter-from,
  .slideFromLeft-leave-to {
    transform: translateX(-100%);
  }
</style>
