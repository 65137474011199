<template>
  <Accordion class="bg-bluegray-300" @deleteItem="openPopUp" :readOnly="readOnly" :id="id">
    <template v-slot:title>
      <div class="w-full text-ellipsis overflow-hidden">
        <p class="text-lg font-semibold float-left">{{item.shortId}}</p>
      </div>
    </template>
    <template v-slot:content>
      <form @submit.prevent="updateEvidenceTableItem" class="mt-4">
        <!-- 'table' -->
        <div class="w-full flex flex-col">
          <!-- overskrift -->
          <div class="flex flex-row">
            <div class="w-full p-4 text-center">
              <span v-show="section === 'KnowledgeSummary'" class="text-xl font-medium">
                {{$t('schema.evidenceTable.header.descriptionKnowledgeSummary')}}
              </span>
              <span v-show="section === 'PrimaryStudy'" class="text-xl font-medium">
                {{$t('schema.evidenceTable.header.descriptionPrimaryStudy')}}
              </span>
            </div>
          </div>
          <!-- kort-id -->
          <div class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="shortId">
                {{$t('schema.evidenceTable.label.shortId')}}
              </label>
            </div>
            <div class="w-3/4">
              <input type="text" id="shortId"
                     v-model="updateItem.shortId"
                     :placeholder="$t('schema.evidenceTable.placeholder.shortId')"
                     v-bind:readonly="!isEdit" />
            </div>
          </div>
          <!-- referanse -->
          <div class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="reference">
                {{$t('schema.evidenceTable.label.reference')}}
              </label>
            </div>
            <div class="w-3/4">
              <ResizableTextArea :name="reference"
                                 :placeholder="$t('schema.evidenceTable.placeholder.reference')"
                                 :readOnly="!isEdit"
                                 v-model="updateItem.reference" />
            </div>
          </div>
          <!-- primærstudier -->
          <div v-show="section === 'KnowledgeSummary'" class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="numberOfPrimaryStudies">
                {{$t('schema.evidenceTable.label.numberOfPrimaryStudies')}}
              </label>
            </div>
            <div class="w-3/4">
              <input type="text" id="numberOfPrimaryStudies"
                     v-model="updateItem.numberOfPrimaryStudies"
                     :placeholder="$t('schema.evidenceTable.placeholder.numberOfPrimaryStudies')"
                     v-bind:readonly="!isEdit" />
            </div>
          </div>
          <!-- land -->
          <div v-show="section === 'PrimaryStudy'" class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="country">
                {{$t('schema.evidenceTable.label.country')}}
              </label>
            </div>
            <div class="w-3/4">
              <input type="text"
                     id="country"
                     v-model="updateItem.country"
                     v-bind:readonly="!isEdit" />
            </div>
          </div>
          <!-- studiedesign -->
          <div v-show="section === 'PrimaryStudy'" class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="studyDesign">
                {{$t('schema.evidenceTable.label.studyDesignShort')}}
              </label>
            </div>
            <div class="w-3/4">
              <ResizableTextArea :name="studyDesign"
                                 :readOnly="!isEdit"
                                 v-model="updateItem.studyDesign" />
            </div>
          </div>
          <div v-show="section === 'KnowledgeSummary'" class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="studyDesign">
                {{$t('schema.evidenceTable.label.studyDesign')}}
              </label>
            </div>
            <div class="w-3/4">
              <ResizableTextArea :name="studyDesign"
                                 :readOnly="!isEdit"
                                 :placeholder="$t('schema.evidenceTable.placeholder.studyDesign')"
                                 v-model="updateItem.studyDesign" />
            </div>
          </div>
          <!-- dato -->
          <div class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="date">
                {{$t('schema.evidenceTable.label.date')}}
              </label>
            </div>
            <div class="w-3/4">
              <input type="text"
                     id="date"
                     v-model="updateItem.dateAsString"
                     :placeholder="$t('schema.evidenceTable.placeholder.date')"
                     v-bind:readonly="!isEdit" />
            </div>
          </div>
          <!-- divider line -->
          <hr class="my-2" />
          <!-- populasjon -->
          <div class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm font-medium" for="population">
                {{$t('schema.evidenceTable.label.population')}}
              </label>
            </div>
            <div class="w-3/4">
              <ResizableTextArea :name="population"
                                 :readOnly="!isEdit"
                                 :placeholder="$t('schema.evidenceTable.placeholder.population')"
                                 v-model="updateItem.population" />
            </div>
          </div>
          <div v-show="section === 'KnowledgeSummary'" class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="populationNumberOfPatiens">
                {{$t('schema.evidenceTable.label.numberOfPatientsPopulation')}}
              </label>
            </div>
            <div class="w-3/4">
              <input type="text"
                     id="populationNumberOfPatiens"
                     v-model="updateItem.populationNumberOfPatiens"
                     :placeholder="$t('schema.evidenceTable.placeholder.populationNumberOfPatiens')"
                     v-bind:readonly="!isEdit" />
            </div>
          </div>
          <!-- divider line -->
          <hr class="my-2" />
          <!-- intervensjonsgruppe -->
          <div class="flex flex-row">
            <p class="font-semibold">{{$t('schema.evidenceTable.header.interventionGroup')}}</p>
          </div>
          <div class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="interventionGroup">
                {{$t('schema.evidenceTable.label.methodsInterventionGroup')}}
              </label>
            </div>
            <div class="w-3/4">
              <ResizableTextArea :name="interventionGroup"
                                 :readOnly="!isEdit"
                                 :placeholder="$t('schema.evidenceTable.placeholder.methodsInterventionGroup')"
                                 v-model="updateItem.interventionGroup" />
            </div>
          </div>
          <div class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="interventionGroupNumberOfPatiens">
                {{$t('schema.evidenceTable.label.numberOfPatientsInterventionGroup')}}
              </label>
            </div>
            <div class="w-3/4">
              <input type="text"
                     id="interventionGroupNumberOfPatiens"
                     v-model="updateItem.interventionGroupNumberOfPatiens"
                     :placeholder="$t('schema.evidenceTable.placeholder.numberOfPatientsInterventionGroup')"
                     v-bind:readonly="!isEdit" />
            </div>
          </div>
          <!-- kontrollgruppe-->
          <div class="flex flex-row">
            <p class="font-semibold">{{$t('schema.evidenceTable.header.controlGroup')}}</p>
          </div>
          <div class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="controlGroup">
                {{$t('schema.evidenceTable.label.methodsControlGroup')}}
              </label>
            </div>
            <div class="w-3/4">
              <ResizableTextArea :name="controlGroup"
                                 :readOnly="!isEdit"
                                 :placeholder="$t('schema.evidenceTable.placeholder.methodsControlGroup')"
                                 v-model="updateItem.controlGroup" />
            </div>
          </div>
          <div class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="controlGroupNumberOfPatiens">
                {{$t('schema.evidenceTable.label.numberOfPatientsControllCroup')}}
              </label>
            </div>
            <div class="w-3/4">
              <input type="text"
                     id="controlGroupNumberOfPatiens"
                     v-model="updateItem.controlGroupNumberOfPatiens"
                     :placeholder="$t('schema.evidenceTable.placeholder.numberOfPatientsControllCroup')"
                     v-bind:readonly="!isEdit" />
            </div>
          </div>
          <!-- divider line -->
          <hr class="my-2" />
          <!-- utfallsmål -->
          <div class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm font-semibold" for="outcomeGoals">
                {{$t('schema.evidenceTable.header.outcomeGoals')}}
                <Help v-if="properties" :model="properties.outcomeHelp" />
              </label>
            </div>
            <div class="w-3/4">
              <ResizableTextArea v-if="section === 'PrimaryStudy'"
                                 :name="outcomeGoals"
                                 :readOnly="!isEdit"
                                 :placeholder="$t('schema.evidenceTable.placeholder.outcomeGoalsPrimary')"
                                 v-model="updateItem.outcomeGoals" />

              <ResizableTextArea v-if="section === 'KnowledgeSummary'"
                                 :name="outcomeGoals"
                                 :readOnly="!isEdit"
                                 :placeholder="$t('schema.evidenceTable.placeholder.outcomeGoalsKnowledge')"
                                 v-model="updateItem.outcomeGoals" />
            </div>
          </div>
          <!-- divider line -->
          <hr class="my-2" />
          <!-- effekt og sikkerhet-->
          <div class="flex flex-row">
            <div class="w-full p-4 text-center">
              <span class="text-xl font-medium">{{$t('schema.evidenceTable.header.results')}}</span>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="w-full mb-2">
              <label class="text-sm font-semibold" for="resultEffect">
                {{$t('schema.evidenceTable.label.effect')}}
                <Help v-if="properties" :model="properties.effectHelp" />
              </label>
            </div>
            <div class="w-full">
              <div v-if="isEdit">
                <Editor v-model='updateItem.resultEffect'
                        :api-key='getTinyKey'
                        :init='getTinyConfig' />
              </div>
              <div v-else v-html="updateItem.resultEffect" class="bg-white border rounded py-2 px-2 min-h-2 xhtml-string overflow-x-auto" />
            </div>
          </div>
          <!-- divider line -->
          <hr class="my-2" />
          <!-- annet-->
          <div class="flex flex-row">
            <div class="w-full p-4 text-center">
              <span class="text-xl font-medium"> {{$t('schema.evidenceTable.header.other')}}</span>
            </div>
          </div>
          <div v-if="section === 'PrimaryStudy'" class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="finance">
                {{$t('schema.evidenceTable.label.finance')}}
              </label>
            </div>
            <div class="w-3/4">
              <ResizableTextArea :name="finance"
                                 :readOnly="!isEdit"
                                 :placeholder="$t('schema.evidenceTable.placeholder.finance')"
                                 v-model="updateItem.finance" />
            </div>
          </div>
          <div v-if="section === 'PrimaryStudy'" class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="risk">
                {{$t('schema.evidenceTable.label.risk')}}
                <Help v-if="properties" :model="properties.riskHelp" />
              </label>
            </div>
            <div class="w-3/4">
              <div v-if="isEdit">
                <select v-model="updateItem.risk">
                  <option value="Lav risiko">Lav risiko</option>
                  <option value="Uklar risiko">Uklar risiko</option>
                  <option value="Høy risiko">Høy risiko</option>
                  <option value="Ikke vurdert">Ikke vurdert</option>
                </select>
              </div>
              <div v-else>
                <input type="text"
                       v-model="updateItem.risk"
                       readonly />
              </div>
            </div>
          </div>
          <!---->
          <div v-show="section === 'KnowledgeSummary'" class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm font-semibold" for="methodQuality">
                {{$t('schema.evidenceTable.label.methodQuality')}}
                <Help v-if="properties" :model="properties.methodQualityHelp" />
              </label>
            </div>
            <div class="w-3/4">
              <div v-if="isEdit">
                <select v-model="updateItem.methodQuality">
                  <option value="Høy">Høy</option>
                  <option value="Middels">Middels</option>
                  <option value="Lav">Lav</option>
                </select>
              </div>
              <div v-else>
                <input type="text"
                       v-model="updateItem.methodQuality"
                       readonly />
              </div>
            </div>
          </div>
          <div class="flex flex-row">
            <div class="w-1/4">
              <label class="text-sm" for="methodQualityComments">
                {{$t('schema.common.comments.anyComments')}}
              </label>
            </div>
            <div class="w-3/4">
              <ResizableTextArea :name="methodQualityComments"
                                 :readOnly="!isEdit"
                                 v-model="updateItem.methodQualityComments" />
            </div>
          </div>

        </div>

        <div class="flex">
          <div v-if="!readOnly">
            <button v-show="!isEdit" @click.prevent="setEditable" class="mr-4 btn btn--small btn--charcoalgray">
              <font-awesome-icon icon="pen" class="mr-2" />
              {{$t('schema.common.action.edit')}}
            </button>
            <button v-show="isEdit"
                    type="submit"
                    class="mr-4 btn btn--small btn--charcoalgray"
                    :class="isSaving ? 'btn--charcoalgray__outline' : ''"
                    :disabled="isSaving">
              <font-awesome-icon v-show="!isSaving" icon="save" class="mr-2" />
              <font-awesome-icon v-show="isSaving" icon="spinner" class="animate-spin mr-2" />
              {{$t('schema.common.action.update')}}
            </button>
            <button v-show="isEdit" type="reset" @click.prevent="cancel" class="btn btn--small btn--charcoalgray">
              <font-awesome-icon icon="ban" class="mr-2" />
              {{$t('schema.common.action.cancel')}}
            </button>
          </div>
        </div>
</form>
    </template>
  </Accordion>
  <div v-if="showPopUp">
    <ConfirmPopUp @close="closePopUp" @confirm="confirm" :text="'Ønsker du å slette evidenstabellen?'" />
  </div>
</template>

<script>
  import Editor from '@tinymce/tinymce-vue';
  import TinyConfig from '@/assets/scripts/tinyConfig';
  import Accordion from '@/views/components/accordion/Accordion.vue';
  import ConfirmPopUp from '@/views/components/confirm/ConfirmPopUp.vue';
  import ResizableTextArea from '@/views/components/textarea/ResizableTextArea.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      section: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      properties: {
        type: Object,
        required: false,
      },
    },
    components: {
      Accordion,
      ConfirmPopUp,
      ResizableTextArea,
      Editor,
    },
    computed: {
      getTinyConfig() {
        return TinyConfig.getConfig(this.type);
      },
      getTinyKey() {
        return TinyConfig.getAPIKey();
      },
    },
    data() {
      return {
        isSaving: false,
        id: this.$uuid(),
        item: this.model,
        isEdit: false,
        showPopUp: false,
        updateItem: {
          id: this.model.id,
          section: this.section,
          type: 'custom',
          customType: 'evidenceTable',
          reference: this.model.reference,
          shortId: this.model.shortId,
          dateAsString: this.model.dateAsString,
          numberOfPrimaryStudies: this.model.numberOfPrimaryStudies,
          studyDesign: this.model.studyDesign,
          population: this.model.population,
          populationNumberOfPatiens: this.model.populationNumberOfPatiens,
          interventionGroup: this.model.interventionGroup,
          interventionGroupNumberOfPatiens: this.model.interventionGroupNumberOfPatiens,
          controlGroup: this.model.controlGroup,
          controlGroupNumberOfPatiens: this.model.controlGroupNumberOfPatiens,
          outcomeGoals: this.model.outcomeGoals,
          methodQuality: this.model.methodQuality,
          methodQualityComments: this.model.methodQualityComments,
          resultEffect: this.model.resultEffect,
          finance: this.model.finance,
          risk: this.model.risk,
          country: this.model.country,
        },
      };
    },
    methods: {
      goto() {
        const el = document.getElementById(this.id);
        el.scrollIntoView({ behavior: 'smooth' });
      },
      setResultEffect(input) {
        this.updateItem.resultEffect = input;
      },
      getSchema() {
        this.$emit('getSchema');
      },
      setEditable() {
        this.isEdit = true;
        this.goto();
      },
      cancel() {
        this.isEdit = false;
        this.goto();
      },
      openPopUp() {
        this.showPopUp = true;
      },
      closePopUp() {
        this.showPopUp = false;
      },
      updateEvidenceTableItem() {
        this.isSaving = true;
        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(this.updateItem),
        };
        fetch(`${window.location.origin}/api/schema/UpdateEvidenceTableItem`, requestOptions)
          .then(() => {
            setTimeout(() => {
              this.isSaving = false;
              this.goto();
              this.isEdit = false;
              this.$emit('getSchema');
              this.$toast.open({
                message: 'Evidenstabell oppdatert',
                type: 'success',
                dismissible: true,
              });
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
            this.$emit('getSchema');
          });
      },
      confirm() {
        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.model.id,
            ownerId: this.model.ownerId,
            section: this.model.section,
          }),
        };
        fetch(`${window.location.origin}/api/schema/DeleteEvidenceTableItem`, requestOptions)
          .then(() => {
            // wait a second
            setTimeout(() => {
              this.$emit('getSchema');
              this.$toast.open({
                message: 'Evidenstabell slettet',
                type: 'success',
                dismissible: true,
              });
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
            this.$emit('getSchema');
          });
      },
      emits: ['getSchema'],
    },
  };
</script>
