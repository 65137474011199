<template>
  <div
    v-if="sections && sections.length > 1"
    :class="index > 0 ? 'justify-between' : 'justify-end'"
    class="flex w-full py-8 border-t border-gray-100 xl:w-3/4"
    >
    <button
      v-if="index > 0"
      @click="goPrev"
    >
      <font-awesome-icon icon="long-arrow-alt-left" class="mr-3 text-base text-red-200" />
      <span class="text-base">{{prevPage.title}}</span>
    </button>
    <button
      v-if="index < numElements"
      @click="goNext"
    >
      <span class="text-base">{{nextPage.title}}</span>
      <font-awesome-icon v-show="nextPage.title" icon="long-arrow-alt-right" class="ml-3 text-base text-red-200" />
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      sections: {
        type: Object,
        required: true,
      },
      openSection: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        index: 0,
        numElements: 0,
        prevPage: {
          index: 0,
          title: '',
        },
        nextPage: {
          index: 0,
          title: '',
        },
      };
    },
    watch: {
      openSection: 'getSection',
    },
    methods: {
      goNext() {
        if (this.index < this.sections.length - 1) {
          this.index += 1;
          this.setSection(this.sections[this.index].contentGuid);
        }
      },
      goPrev() {
        if (this.index > 0) {
          this.index -= 1;
          this.setSection(this.sections[this.index].contentGuid);
        }
      },
      setSection(id) {
        this.$emit('toggleSections', id);
      },
      getSection() {
        const currentPos = this.sections.findIndex((x) => x.contentGuid === this.openSection);
        this.index = currentPos;
        this.numElements = this.sections ? this.sections.length : 0;

        // Prev
        if (currentPos > 0) {
          this.prevPage = {
            index: this.index - 1,
            title: this.sections[this.index - 1]?.title,
          };
        }

        // Next
        if (this.sections && this.sections.length > 1) {
          this.nextPage = {
            index: this.index + 1,
            title: this.sections[this.index + 1]?.title,
          };
        }
      },
    },
  };
</script>
