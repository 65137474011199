<!--
    Rendered when no view was found by BlockComponentSelector.vue for the loaded block model.
-->

<template>
    <div>
        <p>{{$t('blocks.generic.unableToLoad', { name: model.Name })}}</p>
    </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
    },
  },
};
</script>
