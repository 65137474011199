<template>
  <div class="mb-2">
    <label v-if="model.title" :for="id"
           class="text-lg">
      {{model.title}}
      <span v-show="model.mandatory" class="ml-1 text-lg text-red-300">*</span>
      <Help v-if="model.showHelpText" :model="model.helpText" />
    </label>
  </div>
</template>

<script>
  import Help from '@/views/components/help/Help.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
      },
    },
    components: {
      Help,
    },
  };
</script>
