<template>
    <div class="flex flex-nowrap">
        <input
              :id="cbxId"
              :name="cbxName"
              :checked="isChecked"
              :value="cbxValue"
              v-model="value"
              type="checkbox"
              :class="`${cbxClass} flex-grow-0 flex-shrink-0 mt-0.5 mb-0.5`"
              :disabled="cbxDisabled"
            />
            <label class="flex justify-between text-sm text-left mb-0 self-center " :for="cbxId">
              <div class="flex-grow">{{ cbxLabel }}</div>
            </label>
    </div>
</template>

<script>
export default {
    props: {
        cbxId: {},
        cbxName: {
            type: String,
            default: 'unnamed-checkbox',
        },
        cbxClass: {
            type: String,
            default: 'custom--inputbox__checkbox',
        },
        cbxLabel: {
            type: String,
        },
        cbxValue: {},
        cbxDisabled: {
            type: Boolean,
            default: false,
        },
        modelValue: {},
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
        isChecked() {
            if (Array.isArray(this.modelValue)) {
                return this.modelValue.includes(this.cbxValue);
            }

            return this.modelValue;
        },
    },
};
</script>
