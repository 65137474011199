<template>
    <div class="flex flex-nowrap">
        <input
              :id="radioId"
              :name="radioName"
              :checked="isChecked"
              :value="radioValue"
              v-model="value"
              type="radio"
              :class="`${radioClass} flex-grow-0 flex-shrink-0 mt-0.5 mb-0.5`"
              :disabled="radioDisabled"
            />
            <label class="flex justify-between text-sm text-left mb-0 self-center " :for="radioId">
              <div class="flex-grow">{{ radioLabel }}</div>
            </label>
    </div>
</template>

<script>
export default {
    props: {
        radioId: {
            type: String,
        },
        radioName: {
            type: String,
            default: 'unnamed-radio-button',
            required: true,
        },
        radioClass: {
            type: String,
            default: 'custom--inputbox__radio',
        },
        radioLabel: {
            type: String,
        },
        radioValue: {
            // type: Object,
        },
        radioDisabled: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            // type: Object,
        },
        isMobile: {
          type: Boolean,
          default() {
            return false;
          },
        },
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
        get() {
            return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
      },
      isChecked() {
        return this.modelValue;
      },
    },
};
</script>
