<template>
  <div class="w-full mb-10">
    <!--Draft mode-->
    <div v-if="!readOnly">

      <!--File upload-->
      <div v-if="((!firstUploadFinished || !firstUploadResult) && !firstUploadedFileName) || removeFirstFileResult " class="mb-4">
        <p class="text-lg">Last opp utfylt regneark for kommune her:</p>
        <button class="btn btn--charcoalgray" @click="onFirstFileUploadClick">
          <font-awesome-icon icon="file" class="mr-2" />
          <span>{{$t('schema.common.action.selectFile')}}</span>
        </button>
        <input type="file"
               style="display: none"
               ref="firstFileInput"
               accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
               @change="firstFileUploadChange" />
      </div>

      <div v-if="!this.isMunicipality && (((!secondUploadFinished || !secondUploadResult) && !secondUploadedFileName) || removeSecondFileResult )" class="mb-4">
        <p class="text-lg">Last opp utfylt regneark for sykehus/helseforetak her:</p>
        <button class="btn btn--charcoalgray" @click="onSecondFileUploadClick">
          <font-awesome-icon icon="file" class="mr-2" />
          <span>{{$t('schema.common.action.selectFile')}}</span>
        </button>
        <input type="file"
               style="display: none"
               ref="secondFileInput"
               accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
               @change="secondFileUploadChange" />
      </div>

      <!--Uploaded files-->
      <p>Filer som er lastet opp:</p>

      <!--Uploaded file-->
      <div class="mb-12 w-full">
        <div v-if="firstUploadedFileName && !removeFirstFileResult">
          <font-awesome-icon icon="file" class="mr-2" />
          <span class="mr-4">Økonomiske-aspekter-kommune.xlsx</span>
          <button class="mr-2" @click="removeFirstFile">
            <span class="btn--link">{{$t('schema.common.action.remove')}}</span>
          </button>
        </div>
        <div v-else-if="firstUploadResult & !removeFirstFileResult">
          <font-awesome-icon icon="file" class="mr-2" />
          <span class="mr-4">økonomiske-aspekter-kommmune.xlsx</span>
          <button class="mr-2" @click="removeFirstFile">
            <span class="btn--link">{{$t('schema.common.action.remove')}}</span>
          </button>
        </div>

        <div v-if="secondUploadedFileName && !removeSecondFileResult">
          <font-awesome-icon icon="file" class="mr-2" />
          <span class="mr-4">Økonomiske-aspekter-sykehus.xlsx</span>
          <button class="mr-2" @click="removeSecondFile">
            <span class="btn--link">{{$t('schema.common.action.remove')}}</span>
          </button>
        </div>
        <div v-else-if="secondUploadResult & !removeSecondFileResult">
          <font-awesome-icon icon="file" class="mr-2" />
          <span class="mr-4">økonomiske-aspekter-sykehus.xlsx</span>
          <button class="mr-2" @click="removeSecondFile">
            <span class="btn--link">{{$t('schema.common.action.remove')}}</span>
          </button>
        </div>
      </div>

    </div>

    <!--Public mode-->
    <div v-else>
      <!--Info text-->
      <div v-if="this.firstFileUrl && model.properties.infoTextForReader" class="mb-6 mt-6" v-html="model.properties.infoTextForReader">
      </div>
      <!--Uploaded files for download-->
      <ul>
        <li>
          <a v-if="this.firstFileUrl" :href="this.firstFileUrl" class="py-3 underline text-lightblue-300" title="$t('schema.common.action.download.description')">
            <font-awesome-icon icon="file" class="mr-2" />
            <span>{{$t('schema.common.action.download.text.municipalityFoundationFile')}}</span>
          </a>
        </li>
        <li>
          <a v-if="this.secondFileUrl" :href="this.secondFileUrl" class="py-3 underline text-lightblue-300" title="$t('schema.common.action.download.description')">
            <font-awesome-icon icon="file" class="mr-2" />
            <span>{{$t('schema.common.action.download.text.hospitalFoundationFile')}}</span>
          </a>
        </li>
      </ul>
    </div>

    <!--Tables-->
    <div v-if="model.properties.tableSection" class="economytable mt-6">
      <Section :model="model.properties.tableSection"
               :pageId="pageId"
               :preview="preview"
               :readOnly="readOnly" />
    </div>

  </div>
</template>
<script>
  import axios from 'axios';
  import Section from '@/views/components/schema/Section.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      readOnly: {
        type: Boolean,
        default: false,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
      pageId: {
        type: String,
        required: false,
      },
    },
    components: {
      Section,
    },
    data() {
      return {
        isMunicipality: false,
        firstFile: null,
        firstUploadFinished: false,
        firstUploadResult: false,
        removeFirstFileResult: false,
        secondFile: null,
        secondUploadFinished: false,
        secondUploadResult: false,
        removeSecondFileResult: false,
        tableSection: this.model.properties.tableSection,
      };
    },
    mounted() {
      this.isMunicipality = this.model.properties.isMunicipality;
    },
    computed: {
      firstUploadedFileName() {
        if (this.model.properties && this.model.properties.uploadedSpreadsheetFiles && this.model.properties.uploadedSpreadsheetFiles[0]) {
          return this.model.properties.uploadedSpreadsheetFiles[0];
        }
        if (this.firstFile) {
          return this.firstFile.name;
        }
        return null;
      },
      secondUploadedFileName() {
        if (this.model.properties && this.model.properties.uploadedSpreadsheetFiles && this.model.properties.uploadedSpreadsheetFiles[1]) {
          return this.model.properties.uploadedSpreadsheetFiles[1];
        }
        if (this.secondFile) {
          return this.secondFile.name;
        }
        return null;
      },
      firstFileUrl() {
        if (this.model.properties && this.model.properties.foundationFileUrl) {
          return this.model.properties.foundationFileUrl;
        }
        return null;
      },
      secondFileUrl() {
        if (this.model.properties && this.model.properties.secondFoundationFileUrl) {
          return this.model.properties.secondFoundationFileUrl;
        }
        return null;
      },
    },
    methods: {
      onFirstFileUploadClick() {
        this.$refs.firstFileInput.click();
      },
      onSecondFileUploadClick() {
        this.$refs.secondFileInput.click();
      },
      firstFileUploadChange(e) {
        const firstFileUploaded = e.target.files || e.dataTransfer.files;
        const [firstFile] = firstFileUploaded;
        this.firstFile = firstFile;
        this.submitFirstFile();
      },
      secondFileUploadChange(e) {
        const secondFileUploaded = e.target.files || e.dataTransfer.files;
        const [secondFile] = secondFileUploaded;
        this.secondFile = secondFile;
        this.submitSecondFile();
      },
      submitFirstFile() {
        if (this.firstFile) {
          const formData = new FormData();
          formData.append('file', this.firstFile);

          axios.post(`${window.location.origin}/api/schema/UploadMunicipalityEconomyFile/${this.model.contentId}/økonomiske-aspekter-kommune`, formData)
            .then((response) => {
              this.firstUploadFinished = true;
              this.firstUploadResult = response;
              this.removeFirstFileResult = false;
            }, (error) => {
              this.uploadFirstFinished = true;
              this.uploadFirstResult = false;
              this.statusMessage = error;
            });
        }
      },
      submitSecondFile() {
        if (this.secondFile) {
          const formData = new FormData();
          formData.append('file', this.secondFile);

          axios.post(`${window.location.origin}/api/schema/UploadMunicipalityEconomyFile/${this.model.contentId}/økonomiske-aspekter-sykehus`, formData)
            .then((response) => {
              this.secondUploadFinished = true;
              this.secondUploadResult = response;
              this.removeFileResult = false;
            }, (error) => {
              this.uploadFinished = true;
              this.uploadResult = false;
              this.statusMessage = error;
            });
        }
      },
      removeFirstFile() {
        axios.post(`${window.location.origin}/api/schema/RemoveEconomyEvaluation/${this.model.contentId}`, null)
          .then((response) => {
            this.firstFile = null;
            this.removeFirstFileResult = response.data;
          }, (error) => {
            this.statusMessage = error;
          });
      },
      removeSecondFile() {
        axios.post(`${window.location.origin}/api/schema/RemoveEconomyEvaluation/${this.model.contentId}`, null)
          .then((response) => {
            this.secondFile = null;
            this.removeSecondFileResult = response.data;
          }, (error) => {
            this.statusMessage = error;
          });
      },
    },
    emits: ['onSave'],
  };
</script>
