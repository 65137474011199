<template>
     <div class="hidden md:block pb-20">
        <h1 class=" text-xl pb-3"> Hold deg oppdatert </h1>
        <p class=" text-sm pb-6">Få e-post når metodevarsler blir publisert eller oppdatert</p>
        <button class ="btn btn--secondary__outline" @click="openPopup"><font-awesome-icon icon="bell"/> Skru på eller endre varsler </button>
      </div>
</template>

<script>

export default ({
    props: {

    },
    methods: {
        openPopup() {
            this.$emit('openPopup');
        },
    },
});
</script>
