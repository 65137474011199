<template>
  <div v-if="readOnly" :class="preview ? 'w-full' : 'w-3/4'">
    <Figure :figureData="figureData" />
  </div>
  <div v-else class="w-3/4">
    <Figure :figureData="figureData" />
  </div>
</template>

<script>
  import Figure from './Figure.vue';

  export default {
    props: {
      figureData: {
        type: Object,
        required: true,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      Figure,
    },
  };
</script>
