<template>
  <div class="flex flex-col mb-2.5">
    <ul
      v-show="toggleFilter"
    >
      <li v-for="(item, index) in sortFilterNames(filter.filterItems)" :key="index" class="mb-1.5">
        <SearchFilterItem :item="item" :subItems="filter.subItems" :checkboxGroup="filter.filterKey" :showSubItems="showSubItems(item)" :preChecked="preChecked(item)" />
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SearchFilterItem from './SearchFilterItem.vue';

export default {
  name: 'Search Filter Group',
  props: {
    filter: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      toggleFilter: this.visible,
    };
  },
  components: {
    SearchFilterItem,
  },
  methods: {
    ...mapGetters(['getFilter']),
    preChecked(item) {
      const filterKey = `filter${item.filterKey}`;
      const filterObj = this.getFilter();

      // Check if key exsist in object
      if (filterKey in filterObj) {
        // If key exsist, check if ID exsist in array
        if (filterObj[filterKey].includes(item.filterId)) {
          this.toggleFilter = true;
          return true;
        }
      }
      return false;
    },
    showSubItems(item) {
      const filterKey = 'filterSubFilter';
      const filterObj = this.getFilter();
      let showSubItems = false;
      if (item.subItems && item.subItems.length > 0) {
        item.subItems.forEach((i) => {
          if (filterObj && filterObj[filterKey] && filterObj[filterKey].includes(i.filterId)) {
              this.toggleFilter = true;
              showSubItems = true;
            }
          });
        }
        return showSubItems;
    },
       sortFilterNames(filter) {
      const filtersSorted = filter.sort((a, b) => {
        if (a.filterName < b.filterName) {
          return -1;
        }
        if (a.filterName > b.filterName) {
          return 1;
        }
        return 0;
        });
      return filtersSorted;
    },
  },
};
</script>
