<template>
  <div>
    <FieldTitle :model="model" />
    <div v-if="readOnly">
      <div class="pr-0" :class="preview ? 'w-full' : 'w-3/4'">
        <TableView :table="table" :readOnly="readOnly" :pageId="pageId" />
      </div>
    </div>
    <div v-else class="flex">
      <div class="w-full pr-0 xl:w-3/4">
        <TableView :table="table" :readOnly="readOnly" :pageId="pageId" />
      </div>
      <div class="hidden w-1/4 pr-0 mb-4 xl:block lg:pl-4">
        <CommentPane
            v-if="!model.deactivateComments"
            :model="model.comments"
            :contentGuid="model.contentGuid"
            :pageId="pageId"
            :fieldTitle="model.title" />
      </div>
    </div>
  </div>
</template>

<script>
  import CommentPane from '@/views/components/schema/CommentPane.vue';
  import FieldTitle from '@/views/components/schema/common/FieldTitle.vue';
  import TableView from '@/views/components/schema/table/TableView.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    data() {
      return {
        table: this.model.properties.table == null ? [] : this.model.properties.table,
      };
    },
    components: {
      CommentPane,
      FieldTitle,
      TableView,
    },
  };
</script>
