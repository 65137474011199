<template>
    <div class="hidden xl:block">
      <button
        @click.prevent="toggleCommentPane"
        :title="$t('schema.common.action.showComments')"
        class="text-base group"
        :class="guid === contentGuid ? 'text-text underline' : 'text-gray-300'"
      >
        <font-awesome-icon
          icon="comment"
          :class="guid === contentGuid ? 'text-text' : 'text-gray-200'"
        />
        <span class="ml-1 group-hover:underline">{{$t('schema.common.comments.comments', { count: comments.length})}}</span>
      </button>
      <transition name="slide">
        <section
          v-if="guid === contentGuid"
          class="fixed top-0 right-0 w-full h-full max-w-md p-12 overflow-y-auto bg-bluegray-100  z-10"
          v-click-outside="onClickOutside"
        >
          <FocusTrap
            :initial-focus="() => $refs.closeBtn"
            :escapeDeactivates="true"
            @deactivate="onDeactivateFocusTrap()"
            >
            <div>
              <header class="flex items-start justify-between mb-9">
                <h2 class="pr-4 text-xl font-normal">
                  {{ header }}
                </h2>
                <button @click.prevent="toggleCommentPane"
                        :title="$t('schema.common.action.close')"
                        ref="closeBtn"
                        class="text-hmd text-text hover:text-red-200">
                  <font-awesome-icon icon="times" />
                </button>
              </header>

              <p class="mb-4">
                {{$t('schema.common.action.comment.commentDescription')}}
              </p>

              <ul class="mb-6 border-b border-bluegray-400">
                <li class="mb-6" v-for="comment in comments" :key="comment.commentId">
                  <SchemaComment :model="comment"
                                 :isEditing="commentsBeeingEdited.includes(comment.commentId)"
                                 @editComment="editComment"
                                 @saveChanges="saveChanges"
                                 @deleteComment="deleteComment" />
                </li>
              </ul>
              <div>
                <label for="" class="mb-2 text-sm text-text">
                  {{$t('schema.common.action.newComment')}}
                </label>
                <ResizableTextArea :name="guid"
                              class="h-full overflow-hidden nomargin"
                              :placeholder="placeholder"
                              :readOnly="false"
                              v-model="commentText" />
                <!-- <textarea v-model="commentText"></textarea> -->
                <SchemaError v-if="savingError.error"
                             :message="savingError.message"
                             :error="`${savingError.status ? savingError.status : ''}  ${savingError.text ? savingError.text : ''}`" />
                <div class="flex justify-end">
                  <button @click.prevent="saveComment"
                          :disabled="commentText.length === 0"
                          :class="commentText.length > 0 ? 'btn--bluedark cursor-pointer' : 'bg-gray-100 cursor-default'"
                          class="btn">
                    <font-awesome-icon class="text-sm text-white animate-spin mr-2"
                                       icon="spinner"
                                       v-if="savingComment" />
                    <span v-if="!savingComment">
                      {{$t('schema.common.action.save')}}
                    </span>
                    <span v-else>
                      {{ $t('schema.common.action.saving')}}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </FocusTrap>
        </section>
      </transition>
    </div>
</template>
<script>
  import { mapMutations, mapGetters } from 'vuex';
  import vClickOutside from 'click-outside-vue3';
  import { FocusTrap } from 'focus-trap-vue';
  import SchemaComment from '@/views/components/schema/SchemaComment.vue';

  export default {
    name: 'Commentpane',
    props: {
      model: {
        type: Object,
        required: false,
      },
      contentGuid: {
        type: String,
        required: true,
      },
      pageId: {
        type: String,
        required: true,
      },
      fieldTitle: {
        type: String,
        required: false,
      },
    },
    components: {
      SchemaComment,
      FocusTrap,
    },
    directives: {
      clickOutside: vClickOutside.directive,
    },
    data() {
      return {
        placeholder: this.model && this.model.properties ? this.model.properties.placeholder : '',
        comments: {},
        commentText: '',
        savingComment: false,
        savingError: {
          message: '',
          text: null,
          status: null,
        },
        commentsBeeingEdited: [],
        header: this.fieldTitle !== null ? this.fieldTitle : 'Legg til kommentar',
      };
    },
    computed: {
      ...mapGetters(['getSchemaCommentGuid']),

      guid() {
        return this.getSchemaCommentGuid;
      },
    },
    methods: {
      ...mapMutations(['setCommentGuid']),
      onClickOutside() {
        if (this.commentsBeeingEdited.length > 0) {
        // eslint-disable-next-line
          const confirmClose = window.confirm(this.$t('schema.common.action.comment.confirmPanelClose'));
          if (confirmClose) {
            this.toggleCommentPane();
          }
        } else {
          this.toggleCommentPane();
        }
      },
      onDeactivateFocusTrap() {
        // If another commentpane was opened, don't trigger toggleCommentPane()
        // again for this instance when focus trap is deactivated
        if (this.guid === this.contentGuid) {
          this.toggleCommentPane();
        }
      },
      toggleCommentPane() {
        if (this.getSchemaCommentGuid !== this.contentGuid) {
          this.commentsBeeingEdited = [];
          this.setCommentGuid(this.contentGuid);
        } else {
          this.setCommentGuid(null);
        }
      },

      // Get comments
      getComments() {
        const path = `${window.location.origin}/api/schema/GetComments/${this.contentGuid}`;
        // const path = `https://fhi.8086/api/schema/getcomments/${this.contentGuid}`;
        fetch(path)
          .then((response) => response.json())
          .then((data) => {
            this.comments = data;
          });
      },

      // Delete comment
      deleteComment(payload) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              id: this.contentGuid,
              type: 'comment',
              pageId: this.pageId,
              }),
          };
          const path = `${window.location.origin}/api/schema/DeleteComment/${payload.commentId}`;
          // const path = 'https://fhi:8086/api/schema/SaveSchemaItem';
          fetch(path, requestOptions)
            .then((response) => {
              this.savingError.error = false;

              if (response.status !== 200) {
                this.savingComment = false;
                this.savingError = {
                  error: true,
                  message: this.$t('schema.common.action.comment.errorDeleting'),
                  text: response.statusText,
                  status: response.status,
                };
              } else {
                this.$toast.open({
                  message: 'Kommentar slettet',
                  type: 'success',
                  dismissible: true,
                });
              }
              this.getComments();
            });
      },

      // Edit comment
      editComment(payload) {
        if (payload.isEditing) {
          this.commentsBeeingEdited.push(payload.commentId);
        } else {
          this.commentsBeeingEdited = this.commentsBeeingEdited.filter((x) => x !== payload.commentId);
        }
      },
      saveChanges(payload) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              id: this.contentGuid,
              value: payload.comment,
              type: 'comment',
              pageId: this.pageId,
              }),
          };
          const path = `${window.location.origin}/api/schema/EditComment/${payload.commentId}`;
          // const path = 'https://fhi:8086/api/schema/SaveSchemaItem';
          fetch(path, requestOptions)
            .then((response) => {
              const saveChangesError = {
                error: false,
                message: this.$t('schema.common.action.comment.errorUpdating'),
                text: response.statusText,
                status: response.status,
              };
              this.savingComment = false;

              if (response.status === 200) {
                this.$toast.open({
                  message: 'Kommentar endret',
                  type: 'success',
                  dismissible: true,
                });
                this.commentsBeeingEdited = this.commentsBeeingEdited.filter((x) => x !== payload.commentId);
              } else {
                this.savingComment = false;
                saveChangesError.error = true;
              }
              payload.callback(saveChangesError);
              this.getComments();
            });
      },

      // Save comment
      saveComment() {
        if (this.commentText.length > 0) {
          this.savingComment = true;

          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              id: this.contentGuid,
              value: this.commentText,
              type: 'comment',
              pageId: this.pageId,
              }),
          };
          const path = `${window.location.origin}/api/schema/SaveSchemaItem`;
          // const path = 'https://fhi:8086/api/schema/SaveSchemaItem';
          fetch(path, requestOptions)
            .then((response) => {
              this.savingComment = false;
              this.savingError.error = false;

              if (response.status === 200) {
                this.$toast.open({
                  message: 'Kommentar lagret',
                  type: 'success',
                  dismissible: true,
                });
                this.commentText = '';
              } else {
                this.savingComment = false;
                this.savingError = {
                  error: true,
                  message: this.$t('schema.common.action.comment.errorSaving'),
                  text: response.statusText,
                  status: response.status,
                };
              }
              this.getComments();
            });
        }
      },
    },
    mounted() {
      this.getComments();
    },
  };
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
  transform: translateX(0);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
