<template>
    <button
        @click.prevent="this.$emit('click')"
        class="text-xs hover:text-red-300"
        :class="iconColor"
        :title="title"
      >
      <font-awesome-icon :icon="icon" :class="iconSize"/>
      </button>
</template>

<script>
export default {
    emits: ['click'],
    props: {
        title: {
            type: String,
        },
        icon: {
            type: String,
            required: true,
        },
        size: {
            // Valid sizes | xs | sm | base | lg | xl |
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: 'bluegray-400',
        },
        hoverColor: {
            type: String,
            default: 'red-300',
        },
    },
    computed: {
        iconSize() {
            return `text-${this.size}`;
        },
        iconColor() {
            return `text-${this.color}`;
        },
        iconHoverColor() {
            return `hover:text-${this.hoverColor}`;
        },
    },
};
</script>
