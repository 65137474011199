/**
 * Translates the different display options defined on the server to CSS
 * classes to use on the client.
 */

export const DISPLAY_OPTIONS = {
  full: 'u-md-sizeFull',
  wide: 'u-md-size2of3',
  half: 'u-md-size1of2',
  narrow: 'u-md-size1of3',
};

export default {
  DISPLAY_OPTIONS,
};
