<template>
  <div>
    <div>{{model.question}}</div>
    <div>{{model.answer}}</div>
  </div>
</template>

<script>
  export default {
    props: {
      model: {
        type: Object,
      },
    },
  };
</script>
