<template>
  <div class="mb-0 relative">
    <button class="absolute -left-6 text-lg top-1"
            @click="toggleShowSubItems"
            v-if="item.subItems && item.subItems.length> 0">
      <font-awesome-icon :icon="subItemsExpanded ? 'minus-circle': 'plus-circle'" />
      <span v-if="!subItemsExpanded" class="sr-only">{{$t('filter.expand')}}</span>
      <span v-if="subItemsExpanded" class="sr-only">{{$t('filter.collapse')}}</span>
    </button>
    <div v-if="this.isRadio">
      <CustomRadioButton :radioId="filterIdToString(item.filterId)"
                         :radioName="this.groupName"
                         :radioValue="item"
                         :radioDisabled="false"
                         :radioLabel="label"
                         :isMobile="this.isMobile"
                         v-model="checkedFilter"
                         @change="toggleFilter"/>
    </div>
    <div v-else>
      <CustomCheckbox :cbxId="item.filterId"
                      :cbxValue="item"
                      :cbxName="this.checkboxGroup"
                      :cbxClass="'custom--inputbox__checkbox'"
                      :cbxLabel="label"
                      v-model="checkedFilter"
                      @change="toggleFilter" />
      <div v-if="subItemsExpanded">
        <div v-for="(filterItem,index) in item.subItems" :key="`subitem_${index}`">
          <SearchFilterItem :isRadio="false" class=" pl-8" :item="filterItem" :subItems="filterItem.subItems" isSubItem :checkboxGroup="checkboxGroup" :preChecked="subItemPreChecked(filterItem)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapMutations, mapActions, mapGetters } from 'vuex';

  export default {
    name: 'Search Filter - Item',
    components: {
    },
    props: {
      isRadio: {
        type: Boolean,
        required: true,
      },
      isMobile: {
        type: Boolean,
        default() {
          return false;
        },
      },
      subItems: {
        type: Array,
        required: false,
      },
      item: {
        type: Object,
        required: true,
      },
      checkboxGroup: {
        type: String,
        required: true,
      },
      preChecked: {
        type: Boolean,
        default() {
          return false;
        },
      },
      isSubItem: {
        type: Boolean,
        default: false,
      },
      showSubItems: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        checkedFilter: this.preChecked, // Whether item should be checked when loading page
        subItemsExpanded: this.showSubItems, // Coming through prop from searchfiltergroup
      };
    },
    computed: {
      label() {
        const totalCount = this.item.count;
        // If item has sub items, count is aggregated
        // if (this.item.subItems) {
        //   this.item.subItems.forEach((subItem) => {
        //       totalCount += subItem.count;
        //   });
        // }
        return `${this.item.filterName} (${totalCount})`;
      },
      groupName() {
        return this.isMobile ? `${this.checkboxGroup}_mobile` : `${this.checkboxGroup}`;
      },
    },
    methods: {
      ...mapMutations(['setFilter', 'unsetFilter', 'setPageNum', 'unsetFilterAll', 'clearAll']),
      ...mapActions(['performSearch']),
      ...mapGetters(['getFilter']),
      toggleFilter() {
        let tempItem = this.item;
        if (this.isSubItem) {
          tempItem = { filterKey: 'SubFilter', filterId: tempItem.filterId, filterGroup: this.checkboxGroup };
        }
        if (tempItem.filterKey === 'Year') {
          tempItem = { filterKey: 'Year', filterId: parseInt(tempItem.filterName, 10) };
        }
        if (tempItem.filterKey === 'Source') {
          tempItem = { filterKey: 'Source', filterId: tempItem.filterId.toString() };
        }
        if (tempItem.filterKey === 'AssessmentType') {
          this.unsetFilterAll(tempItem);
          this.setFilter(tempItem);
        }
        if (this.checkedFilter) {
          this.setFilter(tempItem);
        }
        if (!this.checkedFilter) {
          this.unsetFilter(tempItem);
        }
        // Set page to 1
        this.setPageNum(1);
        // Perform search
        this.$store.dispatch('performSearch');
      },
      toggleShowSubItems() {
        this.subItemsExpanded = !this.subItemsExpanded;
      },
      subItemPreChecked(item) {
        const filterKey = 'filterSubFilter';
        const filterObj = this.getFilter();
        let showSubItems = false;
        if (filterObj && filterObj[filterKey] && filterObj[filterKey].includes(item.filterId)) {
          showSubItems = true;
        }
        return showSubItems;
      },
      filterIdToString(filterId) {
        return filterId.toString();
      },
    },
    watch: {
      preChecked(val) {
        this.checkedFilter = val;
      },
    },
  };
</script>
