<template>
  <div :class="preview ? 'w-full' : 'w-3/4'">
    <FieldTitle :model="model" :id="id"/>
  </div>
</template>

<script>
  import FieldTitle from '@/views/components/schema/common/FieldTitle.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
    },
    components: {
      FieldTitle,
    },
    data() {
      return {
        id: this.$uuid(),
      };
    },
  };
</script>
