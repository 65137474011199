<template>
  <div v-if="!readOnly" class="flex">
    <div class="w-full pr-0 xl:w-3/4">
      <FieldTitle :model="model" />
      <div class="mb-6">
        <p>{{model.properties.infoTextDraft}}</p>
      </div>
      <div v-for="(file, index) in files" :key="$uuid(index)" class="mb-6">
        <a :href="file.url" class="py-3 underline text-lightblue-300" title="$t('schema.common.action.download.description')">
          <font-awesome-icon icon="file" class="mr-2" />
          <span>{{$t('schema.common.action.download.text.excelFile')}}</span>
        </a>
      </div>
    </div>
  </div>
  <div v-else class="mb-6">
    <p>{{model.properties.infoTextReader}}</p>
  </div>
</template>

<script>
  import FieldTitle from '@/views/components/schema/common/FieldTitle.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      readOnly: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    components: {
      FieldTitle,
    },
    data() {
      return {
        files: this.model.properties.files,
      };
    },
  };
</script>
